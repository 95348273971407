import { twMerge } from "tailwind-merge";
import { BlockViewIcon, ListViewIcon } from "../icons";
const ViewButtons = ({
  showListView,
  showBlockView,
  handleListViewClick,
  handleBlockViewClick,
  displayClass,
}) => {
  return (
    <div className={twMerge("flex justify-end mt-8 mb-4", displayClass)}>
      <ListViewIcon
        className={`cursor-pointer h-[30px] w-[30px] p-1 rounded-md ${
          showListView ? `bg-bgSelectedView` : ``
        }`}
        onClick={handleListViewClick}
      />
      <BlockViewIcon
        className={`ml-4 cursor-pointer p-2 h-[30px] rounded-md w-[30px] ${
          showBlockView ? `bg-bgSelectedView` : ``
        }`}
        onClick={handleBlockViewClick}
      />
    </div>
  );
};
export default ViewButtons;
