import React, { lazy, useContext, useState } from "react";
import { SidebarContext } from "../context/SidebarContext";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Avatar from "@mui/material/Avatar";
import { CssNotificationTooltip, CssTextField } from "./StyledComponent";
// import logo from "../assets/img/logo.jpg";
import logo from "../assets/img/Curatal-new-logo.JPG";
import * as Icon from "react-bootstrap-icons";
import WalletIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, NotificationIcon } from "../icons";
import Search from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import { AuthContext } from "../context/AuthContext";
import dayjs from "dayjs";
import { TermsNConditions } from "../common/TermsNConditions";
import {
  templates,
  TermsAndConditionsPostLogin,
  PrivacyPolicyPostLogin,
} from "../common/constants";
import Notification from "./Notification/Notification";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Badge from "@mui/material/Badge";

import Box from "@mui/material/Box";
import { useMutation, useQuery } from "react-query";
import {
  getNotificationList,
  getUnreadNotificationCount,
  updateNotificationReadStatus,
} from "../lib/notification-api";
import {
  getProfileDetails,
  getCandidateMetrics,
  getRecruiterMetrics,
} from "../lib/userprofile-api";
import developmentSkillsData from "../common/final_taxonomy.json";
import { getCandidateInfo } from "../lib/recruiter-profile-apis";
import TermsDialog from "./Dialog/TermsDialogPostLogin";
import { PrivacyPolicy } from "../common/PrivacyPolicy";
import Drawer from "../components/Drawer";
import OutlinedButton from "../components/Button/Button";
import { Formik, Form, Field } from "formik";
import { Checkbox, FormControlLabel, Button, FormGroup } from "@mui/material";
import { getCategoryList, submitTicket } from "../lib/support-apis";
const SuccessDialog = lazy(() => import("./Dialog/SuccessDialog"));
const ErrorDialog = lazy(() => import("./Dialog/ErrorDialog"));

function Header() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchquery = searchParams.get("q");
  const { toggleSidebar } = useContext(SidebarContext);
  const { logout, user, template, role } = useContext(AuthContext);
  const [tootltipOpen, setTootltipOpen] = React.useState(false);
  const [walltettooltipopen, setWalletTooltioOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState(searchquery ? searchquery : "");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSearch, setShowSearchBox] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tandcOpen, setTandCOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [timeUserAccepted, setTimeUserAccepted] = useState("");
  const [userProfileImage, setUserProfileImage] = useState("");
  const [metrics, setMetrics] = useState({});
  const [message, setMessage] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [categoryID, setCategoryID] = useState();

  const [walletTooltipOpen, setWalletTooltipOpen] = useState(false);
  const handleWalletTooltipOpen = () => {
    setWalletTooltipOpen(!walletTooltipOpen);
  };

  const handleWalletTooltipClose = () => {
    setWalletTooltipOpen(false);
  };

  const [openDrawer, closeDrawer] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => {
    setTimeout(() => {
      setFocused(false);
    }, 1000);
  };
  const containerRef = React.useRef(null);
  const tcContent = (
    <p className="text-secondary text-medium">
      {t("_You_have_accepted_the_Terms_&_Conditions_while_creating_your_account.", {
        timeUserAccepted: timeUserAccepted,
      })}
      <br></br>
      {t("_ip_address") + `: ${metrics?.ip_address || ""}`} <br></br>
      {t("_mac_address") + `: ${metrics?.mac_address || ""}`}
      {/* <p className="text-title text-small mt-5">{TermsNConditions}</p> */}
      <TermsNConditions></TermsNConditions>
    </p>
  );
  const ppContent = (
    <p className="text-secondary text-medium">
      {t("_You_have_accepted_the_Terms_&_Conditions_while_creating_your_account.", {
        timeUserAccepted: timeUserAccepted,
      })}
      <br></br>
      {t("_ip_address") + `: ${metrics?.ip_address || ""}`} <br></br>
      {t("_mac_address") + `: ${metrics?.mac_address || ""}`}{" "}
      {/* <p className="text-title text-small mt-5">{PrivacyPolicyPostLogin}</p> */}
      <PrivacyPolicy></PrivacyPolicy>
    </p>
  );
  useQuery(["/profileDetails1", user.sub], getProfileDetails, {
    enabled: template === templates.candidate,
    onSuccess: (data) => {
      let userData = data?.data?.data?.personal_information;
      let userProfileImage =
        data?.data?.data?.profile_information?.profile_pic_file[0]?.pro_pic_file;
      setUserProfileImage(userProfileImage);
      setFirstName(userData?.first_name);
      setLastName(userData?.last_name);
    },
  });

  useQuery(["/Get recruiter data", user.sub], getCandidateInfo, {
    enabled: template === templates.recruiter,
    onSuccess: (resp) => {
      let userData1 = resp?.data?.data[0];
      //console.log(userData1);
      setFirstName(userData1?.first_name);
      setLastName(userData1?.last_name);
    },
  });

  useQuery(["/Get recruiter metrics", user.sub], getRecruiterMetrics, {
    enabled: template === templates.recruiter,
    onSuccess: (resp) => {
      //console.log(resp);
      setTimeUserAccepted(
        dayjs(resp?.data?.data?.createdAt).format("DD MMM YYYY HH:mm:ss")
      );
      setMetrics(resp?.data?.data?.recruiterMetrics?.[0]);
    },
  });
  useQuery(["/Get candidate metrics", user.sub], getCandidateMetrics, {
    enabled: template === templates.candidate,
    onSuccess: (resp) => {
      //console.log(resp);
      setTimeUserAccepted(
        dayjs(resp?.data?.data?.createdAt).format("DD MMM YYYY HH:mm:ss")
      );
      setMetrics(resp?.data?.data?.candidateMetrics?.[0]);
    },
  });

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  // const { data: notificationList, isLoading: isNotificationListLoading } = useQuery(
  //   ["/notification", user.sub, pageSize, pageIndex],
  //   getNotificationList
  // );

  const { data: unreadNotificationCount, isLoading: isUnreadNotificationCountLoading } =
    useQuery(["/unreadNotificationCount", user.sub], getUnreadNotificationCount, {
      enabled: template !== templates.pcaAdmin,
    });
  const { mutate: updateNotificationStatus } = useMutation(updateNotificationReadStatus);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };
  const handleTooltipClose = () => {
    setTootltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTootltipOpen(!tootltipOpen);
    // updateNotificationStatus({})
    if (unreadNotificationCount?.data?.data?.count > 0) {
      let payload = {
        id: unreadNotificationCount?.data?.data?.rows?.map((row) => {
          return row.id;
        }),
        data: {
          read: true,
        },
      };
      updateNotificationStatus({ payload });
    }
  };

  const handleSearch = (e, value) => {
    if (searchValue || value) {
      if (value) {
        setSearchValue(value);
      }

      template === templates.recruiter
        ? navigate(`/app/recruiter/joblist?q=${value ? value : searchValue}`, {
            replace: true,
          })
        : navigate(`/app/jobs/search?q=${value ? value : searchValue}`, {
            replace: true,
          });
    }
  };
  const getRoleName = (role) => {
    if (role === "Platform Admin") {
      return "Admin";
    } else if (role === "master_recruiter") {
      return "Master Recruiter";
    } else if (role === "sub_recruiter") {
      return "Recruiter";
    } else if (role === "pca_admin") {
      return "PCA Admin";
    } else if (role === "pca_agent") {
      return "PCA Agent";
    } else return role;
  };

  // Function to generate an array from "suggest_field"
  function generateSuggestFieldArray(skillCategory) {
    const suggestFieldArray = [];

    if (skillCategory && skillCategory.child) {
      skillCategory.child.forEach((skill) => {
        suggestFieldArray.push(skill.name);
        if (skill.frameworks) {
          skill.frameworks.forEach((framework) => {
            if (framework.name) {
              suggestFieldArray.push(framework.name);
            }
          });
        }
      });
    }

    return suggestFieldArray;
  }

  // // Function to generate an array from "certifications" where not null
  // function generateCertificationsArray(skillCategory) {
  //   const certificationsArray = [];

  //   if (skillCategory && skillCategory.child) {
  //     skillCategory.child.forEach((skill) => {
  //       if (skill.frameworks) {
  //         skill.frameworks.forEach((framework) => {
  //           if (framework.certifications) {
  //             certificationsArray.push(framework.certifications);
  //           }
  //         });
  //       }
  //     });
  //   }

  //   return certificationsArray.filter((certification) => certification !== null);
  // }

  // Example usage for "FRONTEND" category
  const frontendCategory = developmentSkillsData.find(
    (category) =>
      category.masterCategory === "Development Skills" &&
      category.subCategory &&
      category.subCategory.some((sub) => sub.name === "FRONTEND")
  );

  const array1 = generateSuggestFieldArray(
    frontendCategory && frontendCategory.subCategory
      ? frontendCategory.subCategory.find((sub) => sub.name === "FRONTEND")
      : {}
  );
  // const array2 = generateCertificationsArray(
  //   frontendCategory && frontendCategory.subCategory
  //     ? frontendCategory.subCategory.find((sub) => sub.name === "FRONTEND")
  //     : {}
  // );

  // Print the generated arrays
  // console.log("Array 1:", array1);
  // console.log("Array 2:", array2);
  // Given data
  // Given data
  // Arrays to store all names, certifications, and subCategory names
  let allNames = [];
  let allCertifications = [];
  let allSubCategoryNames = [];

  // Function to extract all names, certifications, and subCategory names
  function extractAllNamesAndCertifications(data) {
    data.forEach((category) => {
      category.subCategory.forEach((subCategory) => {
        subCategory.child.forEach((child) => {
          if (
            child.frameworks[0].certifications !== null &&
            child.frameworks[0].certifications !== undefined
          ) {
            if (child.name) {
              allNames.push(child.name);
            }
            if (child.frameworks[0].name) {
              allNames.push(child.frameworks[0].name); // Add framework names
            }
            allCertifications.push(child.frameworks[0].certifications);
          }
        });

        // Add subCategory name to the array only if there's a match in child data
        if (
          subCategory.child.some(
            (child) =>
              allNames.includes(child.name) || allNames.includes(child.frameworks[0].name)
          )
        ) {
          allSubCategoryNames.push(subCategory.name);
        }
      });

      // Check if there's a match in the top-level category (masterCategory)
      if (
        category.subCategory.some((subCategory) =>
          subCategory.child.some(
            (child) =>
              allNames.includes(child.name) || allNames.includes(child.frameworks[0].name)
          )
        )
      ) {
        allSubCategoryNames.push(category.masterCategory);
      }
    });
  }

  // Function to filter names and certifications based on user input
  function filterNamesAndCertifications(userInput) {
    const matchingNames = new Set();
    const matchingCertifications = new Set();

    for (let i = 0; i < allNames.length; i++) {
      const currentName = allNames[i] || ""; // Handle null values
      if (currentName.toLowerCase().includes(userInput.toLowerCase())) {
        matchingNames.add(currentName);
      }
    }

    developmentSkillsData.forEach((category) => {
      category.subCategory.forEach((subCategory) => {
        subCategory.child.forEach((child) => {
          // Check if child name or framework name matches user input and certifications are not null
          if (
            (child.name &&
              child.name.toLowerCase().includes(userInput.toLowerCase()) &&
              child.frameworks[0].certifications !== null) ||
            (child.frameworks[0].name &&
              child.frameworks[0].name.toLowerCase().includes(userInput.toLowerCase()) &&
              child.frameworks[0].certifications !== null)
          ) {
            // Add certifications to matchingCertifications set
            matchingCertifications.add(child.frameworks[0].certifications);
          }
        });
      });
    });

    return {
      matchingNames: Array.from(matchingNames),
      matchingCertifications: Array.from(matchingCertifications),
    };
  }

  // Example: User input
  const userInput = searchValue;

  // Call the function to extract all names, certifications, and subCategory names
  extractAllNamesAndCertifications(developmentSkillsData);

  // Call the function to filter based on user input
  const { matchingNames, matchingCertifications } =
    filterNamesAndCertifications(userInput);

  // Filter subCategory names based on the user input and child data matches
  const matchingSubCategoryNames = Array.from(
    new Set(
      allSubCategoryNames.filter((subCategoryName) =>
        developmentSkillsData.some((category) =>
          category.subCategory.some(
            (subCategory) =>
              subCategory.name.toLowerCase() === subCategoryName.toLowerCase() &&
              subCategory.child.some(
                (child) =>
                  matchingNames.includes(child.name) ||
                  matchingNames.includes(child.frameworks[0].name)
              )
          )
        )
      )
    )
  );

  useQuery("Get Category", getCategoryList, {
    enabled: true,
    onSuccess: (resp) => {
      resp?.data?.category?.send.find((item) => {
        if (item.name === "Reward") setCategoryID(item.id);
      });
    },
  });

  const { mutate: submitTicketAPI } = useMutation(submitTicket);

  const initialValues = {
    options: [],
  };

  const handleSubmit = (values) => {
    setWalletTooltipOpen(false);
    const selectedOptions = values.options.join(", ");
    let reqData = {
      comments: selectedOptions,
      category_id: categoryID,
    };

    submitTicketAPI(
      {
        reqData,
      },
      {
        onSuccess: (resp) => {
          setMessage(t("Thanks for your response Our Team will get back to you soon"));
          setSuccessDialog(true);
        },
        onError: (error) => {
          setMessage(error?.response?.data?.message);
          setErrorDialog(true);
        },
      }
    );
  };

  // // Print the resulting arrays
  // console.log("Matching Names:", matchingNames);
  // console.log("Certifications:", matchingCertifications);
  // console.log("Matching SubCategory Names:", matchingSubCategoryNames);

  return (
    // <ClickAwayListener onClickAway={handleTooltipClose}>
    <header
      className="z-40 w-full py-4 bg-white lg:px-1 sm:h-20 "
      style={{ boxShadow: "0px 3px 6px #00000029" }}
    >
      <div className="w-full flex  sm:gap-5 lg:gap-[132px] items-center justify-between px-1 sm:px-5 h-full ">
        <div className="xl:hidden mx-2 sm:mx-4 flex  ">
          <MenuRoundedIcon fontSize="medium" onClick={toggleSidebar} />
        </div>

        <img
          src={logo}
          alt="horizontal-logo"
          className="w-28 sm:w-[140px] "
          width={"100%"}
          height={"100%"}
        />
        {template === templates.pcaAdmin || template === templates.pcaAgent ? null : (
          <Box sx={{ width: "100%", position: "relative" }}>
            <CssTextField
              InputProps={{ startAdornment: <Search className="mr-3" /> }}
              placeholder={t("_Search_jobs")}
              autoComplete="off"
              className={`max-w-[556px]  w-full hidden sm:flex `}
              onChange={(e) => {
                setSearchValue(e.target.value);
                onFocus();
              }}
              onClick={onFocus}
              value={searchValue}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                  onBlur();
                }
              }}
            />
            {focused && matchingNames.length > 0 ? (
              searchValue !== "" ? (
                <div
                  className={`z-50 hidden sm:block  absolute bg-white p-5 max-w-[556px] w-full border-2 border-solid border-[#70707059] borderRadius-[5px] overflow-scroll max-h-96`}
                >
                  {/* {matchingNames.length === 0 &&
                  matchingSubCategoryNames.length === 0 &&
                  matchingCertifications.length === 0 ? (
                    <p className="text-lg mb-4">
                      {t("_No_Suggestions_found_for")}{" "}
                      <span className="text-secondary">"{searchValue}"</span>
                    </p>
                  ) : ( */}
                  <p className="text-lg mb-4">
                    {t("_Search_Suggestions_for")}{" "}
                    <span className="text-secondary">"{searchValue}"</span>
                  </p>
                  {/* )} */}
                  <div className="grid grid-cols-2 ">
                    <ul>
                      {matchingNames.length > 0 ? (
                        <>
                          <p className="text-secondary text-base">{t("_Skills")}</p>
                          {matchingNames.slice(0, 8).map((arr, index) => (
                            <li
                              key={index}
                              onClick={(e) => handleSearch(e, arr)}
                              className="text-xs py-2 text-bold cursor-pointer"
                            >
                              {arr}
                            </li>
                          ))}
                        </>
                      ) : null}
                    </ul>

                    {/* <ul>
                    {matchingSubCategoryNames.length > 0 ? (
                      <>
                        <p className="text-secondary text-base ">{t("_Roles")}</p>
                        {matchingSubCategoryNames.slice(0, 3).map((arr, index) => (
                          <li
                            key={index}
                            onClick={(e) => handleSearch(e, arr)}
                            className="text-xs py-2 text-bold cursor-pointer"
                          >
                            {arr}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {matchingCertifications.length > 0 ? (
                      <>
                        <p className="text-secondary text-base mt-5">
                          {t("_Certifications")}
                        </p>
                        {matchingCertifications.slice(0, 3).map((arr, index) => (
                          <li
                            key={index}
                            onClick={(e) => handleSearch(e, arr)}
                            className="text-xs py-2 text-bold cursor-pointer"
                          >
                            {arr}
                          </li>
                        ))}
                      </>
                    ) : null}
                  </ul> */}
                  </div>
                </div>
              ) : (
                <div
                  className={`z-50 absolute hidden sm:block max-w-[556px]  bg-white p-5 w-full border-[1px] border-t-0 border-solid border-[#70707059] borderRadius-[5px] rounded-[5px] overflow-scroll max-h-96`}
                >
                  <p className="text-lg mb-4">
                    {t("_Popular_Suggestions_for")}{" "}
                    <span className="text-secondary">"FrontEnd"</span> {t("_Role")}
                  </p>

                  <div className="grid grid-cols-2 ">
                    <ul>
                      <p className="text-secondary text-base">{t("_Skills")}</p>
                      {array1.map((arr, index) => (
                        <li
                          key={index}
                          onClick={(e) => handleSearch(e, arr)}
                          className="text-xs py-2 text-bold cursor-pointer"
                        >
                          {arr}
                        </li>
                      ))}
                    </ul>

                    {/* <ul>
                    <p className="text-secondary text-base">{t("_Certifications")}</p>
                    {array2.map((arr, index) => (
                      <li
                        key={index}
                        onClick={(e) => handleSearch(e, arr)}
                        className="text-xs py-2 text-bold cursor-pointer"
                      >
                        {arr}
                      </li>
                    ))}
                  </ul> */}
                  </div>
                </div>
              )
            ) : null}
          </Box>
        )}
        <div className="flex justify-end flex-grow items-center">
          {template === templates.pcaAdmin || template === templates.pcaAgent ? null : (
            <Search className="flex sm:hidden" onClick={() => setShowSearchBox(true)} />
          )}
          {template === templates.recruiter ||
          template === templates.platformAdmin ||
          template === templates.pcaAdmin ||
          template === templates.pcaAgent ? (
            //Hiding the logged in as in mobile view since the screens slides to the right
            <div className="text-[10px] whitespace-nowrap  hidden md:inline mx-2">
              {t(`_Logged_in_as `)}
              <span className="text-medium text-secondary ">{getRoleName(role)}</span>
            </div>
          ) : (
            <></>
          )}
          {template === templates.pcaAdmin || template === templates.pcaAgent||role==="Platform Admin"||role === "candidate" || template === "Candidate" ? null : (<div className="mx-2 lg:mx-5 cursor-pointer">
                <Drawer openDrawer={openDrawer} closeDrawe={closeDrawer} />
              </div>)
          }
          {
           (role === "candidate" || template === "Candidate") && (<div className="mx-2 lg:mx-5 cursor-pointer ">
              <Drawer openDrawer={openDrawer} closeDrawe={closeDrawer} />
            </div>)
          }
          {(role === "candidate" || template === "Candidate") && (
          <>
              <ClickAwayListener onClickAway={handleWalletTooltipClose}>
                <div className="relative cursor-pointer">
                  <CssNotificationTooltip
                    className="w-[250px] md:w-[420px]"
                    placement="bottom-end"
                    open={walletTooltipOpen}
                    onClose={handleWalletTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      walletTooltipOpen && (
                        <div className="bg-white rounded-md z-50">
                          <h3 className="text-medium py-5 font-semibold w-full px-[25px] text-buttonsecondary">
                            {t("_Welcome_text")}
                          </h3>
                          <div className="text-sm mb-4 w-full px-[15px]">
                            <p>{t("_Avail_discounts")}</p>

                            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                              {({ values, handleChange }) => (
                                <Form>
                                  <FormGroup>
                                    <FormControlLabel
                                      className="text-medium"
                                      control={
                                        <Field
                                          as={Checkbox}
                                          type="checkbox"
                                          name="options"
                                          value="Mock interview"
                                          checked={values.options.includes(
                                            "Mock interview"
                                          )}
                                          onChange={handleChange}
                                        />
                                      }
                                      label={t("_Mock_Interview")}
                                    />
                                    <FormControlLabel
                                      className="text-medium"
                                      control={
                                        <Field
                                          as={Checkbox}
                                          type="checkbox"
                                          name="options"
                                          value="Mentoring services"
                                          checked={values.options.includes(
                                            "Mentoring services"
                                          )}
                                          onChange={handleChange}
                                        />
                                      }
                                      label={t("_Mentoring_Services")}
                                    />
                                    <FormControlLabel
                                      className="text-medium"
                                      control={
                                        <Field
                                          as={Checkbox}
                                          type="checkbox"
                                          name="options"
                                          value="Upgrad upskilling programs"
                                          checked={values.options.includes(
                                            "Upgrad upskilling programs"
                                          )}
                                          onChange={handleChange}
                                        />
                                      }
                                      label={t("_Upgrad_Upskilling_Programs")}
                                    />
                                  </FormGroup>
                                  <OutlinedButton
                                    label={t("_SUBMIT")}
                                    type="submit"
                                    disabled={values.options.length === 0}
                                    buttonClassName="w-full md:w-[145px] h-dropdownHeight text-xs mt-4 "
                                  ></OutlinedButton>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      )
                    }
                  >
                    <div className="relative mx-2 lg:mx-5">
                      <div className="flex" onClick={handleWalletTooltipOpen} style={{color: 'rgb(0 ,113, 182)'}}>
                        <WalletIcon
                          fontSize="small"
                          // style={{ color: rgb(0 113 182); }}
                        />
                        <span>5k</span>
                      </div>
                    </div>
                  </CssNotificationTooltip>
                </div>
              </ClickAwayListener>
          </>
          )}

          {template === templates.pcaAdmin || template === templates.pcaAgent ? null : (
            <>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div className="relative cursor-pointer">
                  <CssNotificationTooltip
                    className="w-[250px] md:w-[420px]"
                    placement="bottom-end"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    open={tootltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    arrow={true}
                    title={
                      <Notification
                        // messages={notificationList}
                        // isLoading={isNotificationListLoading}
                        userId={user.sub}
                      />
                    }
                  >
                    <div className="relative mx-2 lg:mx-5 ">
                      <Badge
                        invisible={
                          unreadNotificationCount?.data?.data?.count > 0 ? false : true
                        }
                        size="small"
                        color="error"
                        badgeContent={unreadNotificationCount?.data?.data?.count}
                        onClick={handleTooltipOpen}
                      >
                        <NotificationIcon className="" onClick={handleTooltipOpen} />
                      </Badge>
                    </div>
                  </CssNotificationTooltip>
                </div>
              </ClickAwayListener>
            </>
          )}
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#0071B6",
                fontSize: "16px",
              }}
            >
              {userProfileImage && templates?.candidate ? (
                <img src={userProfileImage} alt="User Profile" />
              ) : template === templates.platformAdmin ||
                template === templates.pcaAdmin ||
                template === templates.pcaAgent ? (
                user?.name
                  ?.split(" ")
                  .map((n) => n[0])
                  .join("")
                  .slice(0, 2)
              ) : (
                firstName?.charAt(0) + lastName?.charAt(0)
              )}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                display: "flex",
                justifyContent: "end",

                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {template !== templates.platformAdmin &&
            template !== templates.pcaAdmin &&
            template !== templates.pcaAgent ? (
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (template === templates.recruiter) {
                    navigate("/app/recruiter/profile");
                  } else {
                    navigate("/app/profile/personal-information");
                  }
                }}
              >
                {t("_My_Profile")}
              </MenuItem>
            ) : (
              <div></div>
            )}
            {/* <MenuItem
              onClick={() => {
                navigate("/app/recruiter/billing");
                handleClose();
              }}
            >
              {t("_Billing")}
            </MenuItem> */}
            {template !== templates.platformAdmin &&
            template !== templates.pcaAdmin &&
            template !== templates.pcaAgent ? (
              <div>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    // template === templates.platformAdmin
                    //   ? navigate("/app/admin/support")
                    //   :
                    template === templates.recruiter
                      ? navigate("/app/recruiter/support")
                      : navigate("/app/support");
                  }}
                >
                  {t("_Support")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setTandCOpen(true);
                    setDialogTitle(t("_Terms_&_Conditions"));
                    setDialogContent(tcContent);
                    handleClose();
                  }}
                >
                  {t("_Terms_&_Conditions")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDialogTitle(t("Privacy_Policy"));
                    setTandCOpen(true);
                    setDialogContent(ppContent);
                    handleClose();
                  }}
                >
                  {t("Privacy_Policy")}
                </MenuItem>
              </div>
            ) : null}
            <MenuItem onClick={handleLogout}>{t("_Logout")}</MenuItem>
          </Menu>
        </div>
      </div>
      {template === templates.pcaAdmin ? null : (
        <div ref={containerRef}>
          <Collapse in={showSearch}>
            <Box sx={{ width: "100%", position: "relative" }}>
              <CssTextField
                InputProps={{
                  startAdornment: <Search />,
                  endAdornment: <CloseIcon onClick={() => setShowSearchBox(false)} />,
                }}
                placeholder={t("_Search_jobs")}
                fullWidth
                onChange={(e) => setSearchValue(e.target.value)}
                className="w-full px-5 mt-0.5 sm:hidden  "
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                    setFocused(false);
                  }
                  setFocused(true);
                }}
              />
              {focused ? (
                searchValue !== "" ? (
                  <div
                    className={`z-50 sm:hidden  absolute bg-white p-5 max-w-[556px] w-full border-2 border-solid border-[#70707059] borderRadius-[5px] overflow-scroll max-h-96`}
                  >
                    {matchingNames.length === 0 &&
                    matchingSubCategoryNames.length === 0 &&
                    matchingCertifications.length === 0 ? (
                      <p className="text-lg mb-4">
                        {t("_No_Suggestions_found_for")}{" "}
                        <span className="text-secondary">"{searchValue}"</span>
                      </p>
                    ) : (
                      <p className="text-lg mb-4">
                        {t("_Search_Suggestions_for")}{" "}
                        <span className="text-secondary">"{searchValue}"</span>
                      </p>
                    )}
                    <div className="grid grid-cols-2 ">
                      <ul>
                        {matchingNames.length > 0 ? (
                          <>
                            <p className="text-secondary text-base">{t("_Skills")}</p>
                            {matchingNames.slice(0, 8).map((arr, index) => (
                              <li
                                key={index}
                                onClick={(e) => handleSearch(e, arr)}
                                className="text-xs py-2 text-bold cursor-pointer"
                              >
                                {arr}
                              </li>
                            ))}
                          </>
                        ) : null}
                      </ul>

                      {/* <ul>
                    {matchingSubCategoryNames.length > 0 ? (
                      <>
                        <p className="text-secondary text-base ">{t("_Roles")}</p>
                        {matchingSubCategoryNames.slice(0, 3).map((arr, index) => (
                          <li
                            key={index}
                            onClick={(e) => handleSearch(e, arr)}
                            className="text-xs py-2 text-bold cursor-pointer"
                          >
                            {arr}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {matchingCertifications.length > 0 ? (
                      <>
                        <p className="text-secondary text-base mt-5">
                          {t("_Certifications")}
                        </p>
                        {matchingCertifications.slice(0, 3).map((arr, index) => (
                          <li
                            key={index}
                            onClick={(e) => handleSearch(e, arr)}
                            className="text-xs py-2 text-bold cursor-pointer"
                          >
                            {arr}
                          </li>
                        ))}
                      </>
                    ) : null}
                  </ul> */}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`z-50 absolute  sm:hidden max-w-[556px]  bg-white p-5 w-full border-[1px] border-t-0 border-solid border-[#70707059] borderRadius-[5px] rounded-[5px] overflow-scroll max-h-96`}
                  >
                    <p className="text-lg mb-4">
                      {t("_Popular_Suggestions_for")}{" "}
                      <span className="text-secondary">"FrontEnd"</span> {t("_Role")}
                    </p>

                    <div className="grid grid-cols-2 ">
                      <ul>
                        <p className="text-secondary text-base">{t("_Skills")}</p>
                        {array1.map((arr, index) => (
                          <li
                            key={index}
                            onClick={(e) => handleSearch(e, arr)}
                            className="text-xs py-2 text-bold cursor-pointer"
                          >
                            {arr}
                          </li>
                        ))}
                      </ul>

                      {/* <ul>
                    <p className="text-secondary text-base">{t("_Certifications")}</p>
                    {array2.map((arr, index) => (
                      <li
                        key={index}
                        onClick={(e) => handleSearch(e, arr)}
                        className="text-xs py-2 text-bold cursor-pointer"
                      >
                        {arr}
                      </li>
                    ))}
                  </ul> */}
                    </div>
                  </div>
                )
              ) : null}
            </Box>
          </Collapse>
        </div>
      )}
      <TermsDialog
        open={tandcOpen}
        dialogTitle={dialogTitle}
        handleClose={() => setTandCOpen(false)}
        content={dialogContent}
      ></TermsDialog>

      <SuccessDialog
        message={message}
        open={successDialog}
        onOkayClick={() => setSuccessDialog(false)}
      />
      <ErrorDialog
        message={message}
        open={errorDialog}
        onClick={() => setErrorDialog(false)}
      ></ErrorDialog>
    </header>
    // </ClickAwayListener>
  );
}

export default Header;
