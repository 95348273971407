import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CssTextField } from "../../StyledComponent";
import { useFormikContext, useField } from "formik";
import { useTranslation } from "react-i18next";
import "./customDatePickerWidth.css";

const MonthPicker = ({ name, label, inputClassName, dateFormat, isEdit, ...rest }) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  let val = field.value;
  const [startDate, setStartDate] = useState(new Date());
  const { setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
  console.log(touched);
  useEffect(() => {
    // setFieldTouched(name, true);
    setStartDate(val);
  }, [val]);

  const handleDateChange = (date) => {
    setStartDate(date);
    //const formattedDate = date ? dayjs(date).format("MM-YYYY") : null;
    setFieldValue(name, date);
    // setFieldTouched(name, true);
    if (rest.onDateChange) rest.onDateChange(date);
    if (rest.onProfileDateChange) rest.onProfileDateChange(date);

  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const isError = meta.error && meta.touched;

  return (
    <div>
      <div className="text-grayTextColor text-xs font-medium mt-1">{t(label)}</div>
      <div className="customDatePickerWidth">
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          minDate={rest.minDate}
          maxDate={rest.maxDate}
          onBlur={handleBlur}
          dateFormat={dateFormat || "MMM-yyyy"}
          showMonthYearPicker
          customInput={
            <CssTextField
              variant="outlined"
              className={`w-full ${inputClassName}`}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
              }}
              style={{ border: "none", borderColor: isError ? "#d32f2f" : "inherit" }}
            />
          }
        />
      </div>

      {meta.error ? (
        <div style={{ color: "#d32f2f", fontSize: "11px" }}>
          {!isEdit //(Add)
            ? (startDate && meta.error) || meta.touched
              ? meta.error
              : null
            : meta.error}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MonthPicker;
