import React from "react";
import { twMerge } from "tailwind-merge";

const AuthTemplate = ({
  IconClassname,
  leftPannelText,
  leftPanelIcon,
  children,
  panelClassName,
  containerClassName,
  LeftComponent,
}) => {
  return (
    <div
      className={twMerge(
        `flex flex-col justify-center min-h-[90vh] p-1 sm:p-5`,
        containerClassName
      )}
    >
      {/* <Link
      to="/"
      className="px-8 pt-8 text-xl font-bold text-gray-800 dark:text-gray-200"
    >
      Curtal
    </Link> */}
      <div className="flex flex-1 items-center">
        <div className=" h-full w-full max-w-[90rem]  mx-auto overflow-hidden bg-white">
          <div className="flex flex-col  justify-center  overflow-y-auto md:flex-row">
            <div
              // className={` flex-col  max-w-2.5xl justify-center overflow-hidden h-screen md:h-auto bg-gradient-to-r from-[#0071B6] to-[#AFE0FF] from-10%  hidden lg:flex rounded-lg ${panelClassName} `}
              className={`flex-col max-w-2.5xl justify-center overflow-hidden hidden border-r-0 border lg:flex rounded-l-lg ${panelClassName} `}
            >
              {LeftComponent ? (
                <LeftComponent />
              ) : (
                <>
                  <img
                    aria-hidden="true"
                    className={`object-fill  ${IconClassname}`}
                    src={leftPanelIcon}
                    alt="Office"
                  />
                  <h1 className="text-2xl text-white ">{leftPannelText}</h1>
                </>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthTemplate;
