import React, { useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import { useQuery, useMutation } from "react-query";
import { pagination } from "../../common/constants";
import { EditIconRole } from "../../icons";
import { FilterIcon } from "../../icons";
import { InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { SearchIcon } from "../../icons";
import { userStatusOptions } from "../../common/constants";
import {
  getAllUsers,
  deleteUserById,
  activateUser,
  deactivateUser,
} from "../../lib/role-management-api";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import PageHeader from "../../components/Title";
import BackButton from "../../components/Button/OutlinedButton";
import { BackArrowIcon } from "../../icons";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { CssTextField, CssTooltip } from "../../components/StyledComponent";
import SuccessDialog from "../../components/Dialog/SuccessDialog";
import ErrorDialog from "../../components/Dialog/ErrorDialog";
import ConfirmDelete from "../../components/Dialog/ConfirmDelete";
import { CssSwitch } from "../../components/StyledComponent";
import ActivateConfirmation from "../../components/Dialog/ActivateUserDialog";
import DeactivateConfirmation from "../../components/Dialog/DeactivateUserDialog";
import { AuthContext } from "../../context/AuthContext";

const ListingUsers = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const [pageIndex, setPageIndex] = useState(1);
  const [pagesize, setPagesize] = useState(pagination.pageSize);
  const [totalItems, setTotalItems] = useState();
  const location = useLocation();
  let state = location.state;
  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false);

  const [accountNameFilter, setAccountNameFilter] = useState();

  const scope = window.localStorage.getItem("scope");

  const editAccess = scope.includes("role_mgmt_e");
  const createAccess = scope.includes("role_mgmt_c");

  const [tableData, setTableData] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { mutate: deleteUser } = useMutation(deleteUserById);
  const { mutate: activate } = useMutation(activateUser);
  const { mutate: deactivate } = useMutation(deactivateUser);
  const [searchText, setSearchText] = useState("");
  const [resetKey, setResetKey] = useState(0);
  const [selectedId, setSelectedId] = useState();

  const { roledata, isLoading, refetch } = useQuery(
    [
      "getAllUsers",
      state.role.roleId,
      pagesize,
      pageIndex,
      searchText,
      accountNameFilter,
    ],
    getAllUsers,
    {
      onSuccess: (res) => {
        setTotalItems(res?.data.totalUsers);
        if (res?.data.data) {
          setTableData(res?.data?.data);
        }
      },
    }
  );

  const paginate = (currentPage) => setPageIndex(currentPage);
  const filters = [
    {
      placeholder: "_User_Status",
      data: userStatusOptions,
      isLoading: false,
      filterType: "account_status",
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "createdDate",
        header: t("_Creation_Date"),
        size: "100",
        // Cell: ({ cell }) => <span>{dayjs(cell.getValue()).format("DD-MM-YYYY")}</span>,
        Cell: ({ cell }) => {
          return <span className="break-all">{cell.row.original.createdDate}</span>;
        },
      },
      {
        accessorKey: "first_name",
        header: t("_First_Name"),
        size: "150",
        Cell: ({ cell }) => (
          <span className="break-all">{cell.row.original.first_name}</span>
        ),
      },
      {
        accessorKey: "last_name",
        header: t("_Last_Name"),
        size: "150",
        Cell: ({ cell }) => (
          <span className="break-all">{cell.row.original.last_name}</span>
        ),
      },
      {
        accessorKey: "email",
        header: t("_Email"),
        size: "200",
        Cell: ({ cell }) => <span className="break-all">{cell.row.original.email}</span>,
      },
      {
        accessorKey: "mobileNumber",
        header: t("_Phone_Number"),
        size: "100",
        Cell: ({ cell }) => (
          <span className="break-all">{cell.row.original.mobileNumber || 0}</span>
        ),
      },
      {
        accessorKey: "lastLogin",
        header: t("_Last_Logged_In"),
        size: "200",
        Cell: ({ cell }) => {
          const lastLogin = cell.row.original.lastLogin;
          const formattedDate = lastLogin
            ? dayjs(lastLogin).format("DD-MM-YYYY HH:mm:ss")
            : null;
          return (
            <span className="break-all">
              {formattedDate ? formattedDate : <span className="flex w-full">-</span>}
            </span>
          );
        },
      },
      {
        header: t("_Action"),
        size: "10",
        Cell: ({ cell }) => (
          <div className="flex">
            {editAccess && (
              <CssTooltip title={t("_Edit_User")} placement="top" arrow>
                <EditIconRole
                  className="w-4 h-4 mr-2.5"
                  onClick={() => {
                    navigate(`/app/rolemanagement/users/edit`, {
                      state: {
                        firstName: cell?.row?.original?.first_name,
                        email: cell?.row?.original?.email,
                        mobileNumber: cell?.row?.original?.mobileNumber,
                        lastName: cell?.row?.original?.last_name,
                        role: state.role,
                        userid: cell?.row?.original?.id,
                      },
                    });
                  }}
                />
              </CssTooltip>
            )}
            {editAccess && cell?.row?.original?.id !== user.sub && (
              <div className="">
                <CssTooltip title={t("_User_Status")} placement="top" arrow>
                  <CssSwitch
                    className={``}
                    disabled={!cell?.row?.original?.isEmailVerified}
                    checked={cell?.row?.original?.status === "Active"}
                    onChange={() => {
                      const status = cell?.row?.original?.status;
                      console.log(status);
                      const id = cell?.row?.original?.id;

                      if (status === "Active") {
                        setSelectedId(id);
                        setMessage("Are you sure you want to deactivate?");
                        setOpenDeactivateDialog(true);
                      } else {
                        setSelectedId(id);
                        setMessage("Are you sure you want to activate?");
                        setOpenActivateDialog(true);
                      }
                      refetch();
                    }}
                    name="activate-toggle"
                  />
                </CssTooltip>
              </div>
            )}
          </div>
        ),
      },
    ],
    [t, navigate]
  );

  // const handleFiltersChange = (filterType, selectedOptions) => {
  //   setFilters({ ...filters, [filterType]: selectedOptions });
  // };

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1);
    setAccountNameFilter([]);
    setPageIndex(1);
  };

  const handleDelete = (id) => {
    setValue(id);
    console.log(value);
    setDeleteDialog(true);
  };

  return (
    <div className="py-10 px-2 md:px-12 w-full">
      <div>
        <div className="flex justify-between">
          <p className="text-2xl text-title font-semibold">{t("_User_List")}</p>
          <BackButton
            buttonClassName={"h-10"}
            startIcon={<BackArrowIcon className="h-3 w-4 " />}
            onClick={() => {
              navigate(`/app/rolemanagement`);
            }}
            label={t("_ROLE_LIST")}
          />
        </div>
        <div className="mt-5">
          <div className="text-medium mr-2 text-title font-semibold">
            {state.role.roleName}
          </div>
        </div>
        <div className="mt-5 mb-10">
          <div className="flex justify-between items-center space-x-4">
            <div className="flex items-center space-x-4">
              <FilterIcon className="w-12 h-12" />
              {filters.map((filter, index) => (
                <div className="mr-2 mt-2 mb-2" key={index}>
                  <CustomDropdown
                    btnName={filter.placeholder}
                    data={filter.data}
                    isLoading={filter.isLoading}
                    filterType={filter.filterType}
                    reset={resetKey}
                    onApply={(selectedOptions) => {
                      setAccountNameFilter(selectedOptions[0]);
                      setPageIndex(1);
                    }}
                    inputType={
                      filter.filterType !== "campany_name" ? "radio" : "checkbox"
                    }
                    isMulti={filter.filterType === "campany_name"}
                    className="text-gray-600 bg-white border border-gray-300 rounded-lg p-2"
                  />
                </div>
              ))}
              <CssTextField
                size="small"
                // Autofocus on textfield
                autoFocus
                className="w-full h-[35px] "
                placeholder="Search"
                fullWidth
                value={searchText}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="w-4 h-4 text-gray-500 mx-2" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
              <div>
                <Button
                  buttonClassName=" w-24 h-[35px] text-xs rounded-[20px] mx-2 "
                  onClick={handleReset}
                  label="_RESET"
                />
              </div>
            </div>
            {createAccess && (
              <Button
                buttonClassName="w-26 h-[35px] text-xs rounded mx-2"
                onClick={() => {
                  navigate(`/app/rolemanagement/users/add`, {
                    state: {
                      role: state.role,
                    },
                  });
                }}
                label="_ADD_USER"
              />
            )}
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={tableData}
        headerPaddingX="18px"
        bodyPaddingX="18px"
        isLoading={isLoading}
        rowCount={totalItems || 0}
        paginate={paginate}
        currentPage={pageIndex}
        pagesize={pagesize}
        setPagesize={setPagesize}
      />
      <ConfirmDelete
        message={t("_Are_you_sure_you_want_to_delete_the_user?")}
        open={deleteDialog}
        onCancelClick={() => setDeleteDialog(false)}
        onDeleteClick={() => {
          deleteUser(
            { value },
            {
              onSuccess: () => {
                setDeleteDialog(false);
                setMessage("_User_deleted_successfully");
                setSuccessDialog(true);
                refetch();
              },
              onError: (error) => {
                setDeleteDialog(false);
                setErrorDialog(true);
                setErrorMessage(error?.response?.data?.message);
              },
            }
          );
        }}
        maxWidth={500}
      />
      <ActivateConfirmation
        message={message}
        open={openActivateDialog}
        handleActivate={() => {
          if (selectedId) {
            activate(selectedId);
            setOpenActivateDialog(false);
            refetch();
          }
        }}
        onClose={() => setOpenActivateDialog(false)}
      />
      <DeactivateConfirmation
        message={message}
        open={openDeactivateDialog}
        handleDeactivate={() => {
          if (selectedId) {
            deactivate(selectedId);
            setOpenDeactivateDialog(false);
            refetch();
          }
        }}
        onClose={() => setOpenDeactivateDialog(false)}
      />
      <SuccessDialog
        message={message}
        open={successDialog}
        onOkayClick={() => {
          setSuccessDialog(false);
        }}
      />
      <ErrorDialog
        message={errorMessage}
        open={errorDialog}
        onClick={() => setErrorDialog(false)}
      />
    </div>
  );
};

export default ListingUsers;
