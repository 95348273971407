import "react-phone-input-2/lib/high-res.css";
import PhoneInput from "react-phone-input-2";
import { useField, useFormikContext, ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
const PhoneNumberInput = ({ displayError = true, ...props }) => {
  const { name, label, ...rest } = props;
  const { t } = useTranslation();
  const [field, meta] = useField(name);
  const { setFieldTouched, setFieldValue, handleBlur, handleChange } = useFormikContext();
  const styles = {
    mobileField: "w-full h-[40px] rounded-xl pl-20 border-0 font-sans text-xs ",
  };

  return (
    <Field name={name} id={name} type="text" onBlur={handleBlur}>
      {({ field: { value } }) => (
        <div>
          {props.label && (
            <div className="text-grayTextColor font-medium text-xs mb-1">
              {t(props.label)}
              {rest.isMandatory ? (
                <span className="text-asteriskColor text-xs"> *</span>
              ) : null}
            </div>
          )}
          <div
            onBlur={(e) => {
              setFieldTouched(name, true);
              if (props.onBlur) props.onBlur(e);
            }}
          >
            <PhoneInput
              country={"in"}
              name={name}
              inputProps={{ name: name }}
              preferredCountries={["in", "us"]}
              //placeholder="Enter mobile number"
              label={label}
              value={value}
              onBlur={handleBlur}
              inputClass={styles.mobileField}
              onChange={async (value, country, e, formattedValue, name) => {
                const { format, dialCode } = country;

                if (props.countryName && props.countryName !== country?.name) {
                  setFieldValue(props.name, dialCode);
                  setFieldValue(props.name + "format", format);
                  setFieldValue(props.name + "dialCode", dialCode);
                  setFieldValue(props.name + "formattedValue", formattedValue);
                  setFieldValue(props.name + "countryName", country.name || "India");
                } else {
                  setFieldValue(props.name, value);
                  setFieldValue(props.name + "format", format);
                  setFieldValue(props.name + "dialCode", dialCode);
                  setFieldValue(props.name + "formattedValue", formattedValue);
                  setFieldValue(props.name + "countryName", country.name || "India");
                }
              }}
              // onChange={props.onChange}

              containerClass={`${
                meta?.error && meta?.touched ? `border-[#d32f2f]` : `border-[#70707059]`
              } border border-solid  hover:border-borderPrimary hover:border-2 hover:-m-[1px] focus:border-borderPrimary rounded-5px`}
              buttonClass="border-0 border-r bg-white border-r-[#70707059] hover:border-red rounded-l-xl px-3 scale-2 font-medium"
              disableSearchIcon={true}
              countryCodeEditable={false}
              autoFormat={true}
              enableSearch={true}
            />
          </div>
          {displayError ? (
            <ErrorMessage
              component="div"
              className="text-[#D32F2F] absolute text-[11px] mt-0.5"
              name={name}
            />
          ) : null}
        </div>
      )}
    </Field>
  );
};

export default PhoneNumberInput;
