import { useState, lazy, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage } from "formik";
import MultipleSelectChip from "../Select/MultiSelect";
import CurrencyCode from "../Select/CurrencyCode";
import PreferredPlaceDropDown from "../Select/PreferredPlaceDropDown";
import {
  noticePeriodMenu,
  skillLevelOptions,
  experienceData,
  workPlaceTypeOptions,
  employmentTypeOptions,
} from "../../common/constants";
import CertificateInfo from "../Resume/CertificateInfo";
import AssessedSkills from "../Resume/AssessedSkills";
import {
  sSkillOptions,
  sSkillLevelOptions,
  getOptionsToRender,
} from "../../common/common";
import AsyncSelectWrapper from "../../components/Select/MultiSelectAsync";
import { useMutation, useQuery } from "react-query";
import { getSkillList } from "../../lib/dropDownList-api";
import {
  ResumePlusIcon,
  AddIcon,
  TickIcon,
  CheckMarkOutlineIcon,
  CheckmarkDoneIcon,
  RedTickCircleIcon,
} from "../../icons";
import { useNavigate } from "react-router";
import { CssTooltip } from "../StyledComponent";
import { getAssessedSkills } from "../../lib/dashboard-api";
import { AuthContext } from "../../context/AuthContext";
import { Rating } from "@mui/material";

const QuickResumeSubContent1 = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  isNextButtonClicked,
}) => {
  const [skillOptions, setSkillOptions] = useState([]);
  const { userId } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const style = { text: "text-resumeTextColor text-xl mb-3" };

  const { isLoading } = useQuery(["get rate skills"], getSkillList, {
    onSuccess: (data) => {
      let skillOptions = data?.data?.data?.map((skill) => {
        return {
          value: skill.skill_name,
          label: skill.skill_name,
        };
      });

      setSkillOptions(skillOptions);
    },
  });

  const { data: assessedSkillsData, isLoading: isassessedSkillsDataLoading } = useQuery(
    ["/assessedSkills", userId],
    getAssessedSkills
  );

  return (
    <div className="w-[450px]">
      <div className="mx-10 my-12 ">
        <div className="flex items-center">
          <div className={style.text}>
            {t("_Enter_5_mandatory_skills")}
            <span className="text-error-icon">*</span>
          </div>
          {/* <div className="ml-auto fourth-step">
            {values?.isSkillVerified ? (
              <CheckmarkDoneIcon className="h-5 w-5" />
            ) : !values?.isSkillVerified && isNextButtonClicked ? (
              <RedTickCircleIcon
                onClick={() => setFieldValue("isSkillVerified", true)}
              ></RedTickCircleIcon>
            ) : (
              <CssTooltip title={t("_Confirm_Skills")} placement="top" arrow>
                <button
                  onClick={() => setFieldValue("isSkillVerified", true)}
                  disabled={values?.skills?.some(
                    (x) => x.skillName === "" || x.skillLevel === ""
                  )}
                >
                  <TickIcon className="h-5 w-5" />
                </button>
              </CssTooltip>
            )}
          </div> */}
        </div>

        {Array(5)
          .fill()
          .map((_, index) => (
            <div key={index} className="flex gap-4 mb-8 ">
              <div className="w-4/5">
                <AsyncSelectWrapper
                  className="w-full h-[40px] text-primary rounded-[5px] placeholder-inputColorSecondary border-solid border-borderSecondary font-semibold "
                  isMulti={false}
                  isLoading={isLoading}
                  optionsData={getOptionsToRender(values.skills, skillOptions, index)}
                  placeholder={t("_Select_skill")}
                  isSearchable={true}
                  name={`skills[${index}].skillName`}
                  isClearable={false}
                  quickResume={true}
                />
              </div>
              <div className="w-3/5">
                {/* <MultipleSelectChip
                  className="w-full h-[40px] text-primary rounded-[5px] placeholder-inputColorSecondary border-solid border-borderSecondary font-semibold "
                  options={skillLevelOptions}
                  isMulti={false}
                  placeholder=""
                  isSearchable={true}
                  name={`skills[${index}].skillLevel`}
                  isClearable={false}
                />
                 */}
                <Rating
                  name={`skills[${index}].skillLevel`}
                  value={values?.skills?.[index]?.skillLevel}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></Rating>
                <ErrorMessage
                  component="div"
                  className={"absolute  text-resumeTextColor text-[11px] "}
                  name={`skills[${index}].skillLevel`}
                />
              </div>
            </div>
          ))}

        {!isassessedSkillsDataLoading && assessedSkillsData?.data?.data?.length > 0 && (
          <div className={`${style.text} mt-14`}>{t("_Interviewed_Skills")}</div>
        )}
        {!isassessedSkillsDataLoading &&
          assessedSkillsData?.data?.data?.length > 0 &&
          assessedSkillsData?.data?.data?.map((assessedSkill, index) => (
            <AssessedSkills
              key={index}
              skillName={assessedSkill.skill_name}
              skillLevel={assessedSkill.assessed_score}
            />
          ))}

        <div className="flex items-center mt-14">
          <div className={`${style.text} `}>{t("_Certificate")}</div>
          <div className="ml-auto flex flex-wrap gap-5">
            <ResumePlusIcon
              onClick={() => {
                var newurl = `?type=${"C"}&op=add`;
                navigate(newurl);
              }}
              className=" h-5 w-5"
            />
            {/* <div className="fifth-step">
              {values.isCertificateVerified ? (
                <CheckmarkDoneIcon className="h-5 w-5" />
              ) : !values?.isCertificateVerified && isNextButtonClicked ? (
                <RedTickCircleIcon
                  onClick={() => setFieldValue("isCertificateVerified", true)}
                ></RedTickCircleIcon>
              ) : (
                <CssTooltip title={t("_Confirm_Certificate")} placement="top" arrow>
                  <TickIcon
                    className="h-5 w-5"
                    onClick={() => setFieldValue("isCertificateVerified", true)}
                  />
                </CssTooltip>
              )}
            </div> */}
          </div>
        </div>

        {values?.certificateData?.map((certificate, index) => (
          <CertificateInfo
            key={index}
            item={certificate}
            lastIndex={values?.certificateData.length - 1 === index}
            name={"certificateData"}
            type={"C"}
            index={index}
            setFieldValue={setFieldValue}
            data={values.certificateData}
          />
        ))}
        <div className="sixth-step">
          <div className={`${style.text} mt-14`}>
            {t("_Notice_Period")}
            <span className="text-error-icon">*</span>
          </div>
          <MultipleSelectChip
            className=" w-full h-[40px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
            options={noticePeriodMenu}
            isMulti={false}
            placeholder={"Select notice period"}
            isSearchable={true}
            name="noticePeriod"
            isClearable={false}
            quickResume={true}
          />
          <div className={`${style.text} mt-14`}>
            {t("_Experience")}
            <span className="text-error-icon">*</span>
          </div>

          <MultipleSelectChip
            className=" w-full h-[40px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
            options={experienceData}
            isMulti={false}
            placeholder={"Select experience"}
            isSearchable={true}
            name="experience"
            isClearable={false}
            quickResume={true}
          />

          <div className={`${style.text} mt-14`}>
            {t("_Employment_type")}
            <span className="text-error-icon">*</span>
          </div>

          <MultipleSelectChip
            className=" w-full h-[40px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
            options={employmentTypeOptions}
            isMulti={false}
            placeholder={"Select employment type"}
            isSearchable={true}
            name="employmentType"
            isClearable={false}
            quickResume={true}
          />

          <div className={`${style.text} mt-14`}>
            {t("_Preferred_work_mode")}
            <span className="text-error-icon">*</span>
          </div>

          <MultipleSelectChip
            className=" w-full h-[40px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
            options={workPlaceTypeOptions}
            isMulti={true}
            placeholder={"Select work mode"}
            isSearchable={true}
            name="workPlace"
            isClearable={false}
            quickResume={true}
          />
          <div className={`${style.text} mt-14 `}>
            {t("_Current_CTC")}
            <span className="text-error-icon">*</span>
          </div>
          <CurrencyCode
            values={values}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            onBlur={handleBlur}
            // options={options}
            fieldName="currentCtc"
            currencyFieldName={"currentCtcCurrency"}
          />
          <ErrorMessage
            component="div"
            className="text-resumeTextColor absolute text-xs"
            name={"currentCtc"}
          />
          <div className={`${style.text} mt-14`}>
            {t("_Expected_CTC")}
            <span className="text-error-icon">*</span>
          </div>
          <CurrencyCode
            values={values}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            onBlur={handleBlur}
            // options={options}
            fieldName="expectedCTC"
            currencyFieldName={"expectedCTCCurrency"}
          />
          <ErrorMessage
            component="div"
            className="text-resumeTextColor absolute text-xs"
            name={"expectedCTC"}
          />
          <div className={`${style.text} mt-14`}>
            {t("_Preferred_Locations")}
            <span className="text-error-icon">*</span>
          </div>
          <PreferredPlaceDropDown
            index={0}
            placeholder={t("_Select_location")}
            value={values.preferredPlaceToRelocate}
            setFieldValue={setFieldValue}
            name="preferredPlaceToRelocate"
            quickResume={true}
            maxSelectedValue={5}
          ></PreferredPlaceDropDown>
        </div>
      </div>
    </div>
  );
};

export default QuickResumeSubContent1;
