import getAPIMap from "../routes/ApiUrls";
import { axiosPublic } from "../common/axiosPublic";
import { axiosPrivate } from "../common/axiosPrivate";
const IP = process.env.REACT_APP_IP;

/**
 * This method is used to get the parsed data from resume
 * @param {*} options
 * @returns
 */
export async function getResumeParserData(options) {
  let reqData = options.data;
  var url = getAPIMap("getResumeParserData");
  const config = {
    headers: {
      //Authorization: `Bearer ${tokenData?.data?.access_token}`,
      "Content-Type": "application/json", // content type
    },
  };
  let response = await axiosPrivate.post(url, reqData, config);
  return response;
  // const jsonResponse = Promise.resolve(sampleResponse2);
  // return jsonResponse;
}

/**
 * This method is used to get the resume parser token
 * @returns
 */
export async function getResumeParseToken() {
  let reqData = {
    username: "ottl",
    password: "()P3n7u6f!2023",
    grant_type: "password",
    client_id: "curatal-client",
    client_secret: "wWaHlWvedxe7DP4mxptm2CXjJQYUvZXx",
  };
  var formBody = [];
  for (var property in reqData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(reqData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  let url = getAPIMap("getResumeParserToken");
  const config = {
    headers: {
      //Authorization: `Bearer ${options.token}`,
      "Content-Type": "application/x-www-form-urlencoded", // content type
    },
  };
  let response = await axiosPublic.post(url, reqData, config);
  //console.log(response);
  return response;
}

/**
 * This method is used for Signup
 * @param {*} options
 * @returns
 */
export async function signUp(options) {
  let url = getAPIMap("signup");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

/**
 * This method is used for login to the appplication
 */
export async function login(payload) {
  let url = getAPIMap("login");
  return axiosPublic.post(url, payload.reqData);
}

/**
 * This method is used for login to the appplication
 */
export async function recruiterLogin(payload) {
  let url = getAPIMap("recruiterLogin");
  return axiosPublic.post(url, payload.reqData);
}
/**
 * This method is used to set password after activation link is sent
 * @param {*} options
 * @returns
 */
export async function setPassword(options) {
  //console.log(options);
  let url = getAPIMap("resetpassword");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}
/**
 * This method is used for forget password
 * @param {*} options
 * @returns
 */
export async function forgotPassword(options) {
  // console.log(options);
  let url = getAPIMap("forgotpassword");

  let response = axiosPublic.post(url, options.reqData);
  return response;
}
/**
 * This method is used for upload resume
 * @param {*} options
 * @returns
 */
export async function uploadResumeFile(options) {
  const id = options.reqData.id;
  let formData = new FormData();
  formData.append("resume", options.reqData.resume);

  let url = getAPIMap("uploadResume").replace("{id}", id);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data", // content type
    },
  };
  let response = axiosPrivate.post(url, formData, config);

  return response;
}

/**
 * This method is used to update the existing resume of user
 * @param {*} options
 * @returns
 */
export async function updateResumeFile(options) {
  const id = options.reqData.id;
  let formData = new FormData();
  formData.append("resume", options.reqData.resume);

  let url = getAPIMap("updateResume").replace("{id}", id);
  url = url.replace("{fileid}", options.reqData.fileId);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  let response = axiosPrivate.put(url, formData, config);
  return response;
}

/**
 * This method is used for delete resume
 * @param {*} options
 * @returns
 */
export async function DeleteResumeFile(options) {
  const id = options.id;
  const fileId = 1;
  //console.log("Id", id);
  let url = `${IP}${getAPIMap("deleteResume")
    .replace("{id}", id)
    .replace("{fileid}", fileId)}`;
  let response = axiosPublic.delete(url);
  //console.log("Response", response);
  return response;
}

/**
 * This method is used for otpverification
 * @param {*} options
 * @returns
 */
export async function OtpVerification(options) {
  let url = getAPIMap("otpVerification");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

/**
 * This method is used for Forgot username
 * @param {} payload
 * @returns
 */
export async function forgotUsername(payload) {
  let url = getAPIMap("forgotUsername");
  return axiosPublic.post(url, payload);
}

/**
 * This method is used to display left menu nav
 */

export async function sidebarMenu() {
  let url = getAPIMap("sidebarMenu");
  return axiosPrivate.get(url);
}

///////////////////////////////////////RECRUITER SPECIFIC APIS/////////////////////////////////

/**
 * This method is used for Signup
 * @param {*} options
 * @returns
 */
export async function recruiterSignUp(options) {
  let url = getAPIMap("recruiterSignup");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}
/**
 * This method is used for Signup
 * @param {*} options
 * @returns
 */
export async function recruiterVerifyLink(options) {
  let url = getAPIMap("recruiterVerifyActivationLink");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

/*This method is used for submit details to contact sales team*/
export async function submitContactSales(options) {
  let url = getAPIMap("salesTicket");
  // let url = "/v1/recruiter/sales/ticket";
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

/**
 * This method is used to validate recruiter email id during signup
 */
export async function validateRecruiterEmailId(options) {
  let url = getAPIMap("validateRecruiterEmail");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

/**
 * This method is used to validate recruiter email id during signup
 */
export async function validateMobileNo(options) {
  let url = getAPIMap("verifyMobileNo");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

/**
 * This method is used to validate candidate email id during signup
 */
export async function validateCandidateEmailId(options) {
  let url = getAPIMap("ValidateEmailId");
  let response = axiosPublic.post(url, options.reqData);
  return response;
}

///////////////////////////////////////PCA SPECIFIC APIS/////////////////////////////////
/**
 * This method is used for login to the appplication
 */
export async function pcaLogin(payload) {
  let url = getAPIMap("pcaLogin");
  return axiosPublic.post(url, payload.reqData);
}
