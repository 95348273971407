import mem from "mem";

import { axiosPublic } from "./axiosPublic";
import getAPIMap from "../routes/ApiUrls";

const RefreshTokenFn = async () => {
  let url = getAPIMap("refreshToken");
  //const { setSessionValue, setUser } = useContext(AuthContext);

  const sessionData = JSON.parse(localStorage.getItem("session"));

  try {
    const response = await axiosPublic.post(url, {
      refresh_token: sessionData?.refreshToken,
    });

    const session = response.data;

    // if (!session?.access_token) {
    //   setSessionValue("");
    //   setUser("");
    // } else {
    //   setSessionValue(JSON.stringify(session));
    // }

    if (!session?.access_token) {
      localStorage.removeItem("session");
      localStorage.removeItem("user");
    }

    let newSession = {
      accessToken: session.access_token,
      refreshToken: session.refresh_token,
    };
    localStorage.setItem("session", JSON.stringify(newSession));
    window.dispatchEvent(new Event("storage"));

    return session;
  } catch (error) {
    localStorage.removeItem("session");
    localStorage.removeItem("user");
    window.dispatchEvent(new Event("storage"));
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(RefreshTokenFn, {
  maxAge,
});
