//import React from "react";

function Main({ children }) {
  return (
    <main className="h-full overflow-y-visible">
      <div className="2xl:container  lg:mx-auto h-full">{children}</div>
      {/* {children} */}
    </main>
  );
}

export default Main;
