import React from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import AsyncSelect from "react-select/async";
import { CheckmarkIcon } from "../../icons";
//import { ColourOption, colourOptions } from "../data";
import ReactSelect, { components, createFilter } from "react-select";
const AsyncSelectDropdown = ({
  optionsData,
  minHeight,
  fontSize,
  quickResume,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const filterColors = (inputValue) => {
    return optionsData?.filter((i) =>
      i?.label?.toLowerCase()?.includes(inputValue.toLowerCase())
    );
  };
  const customSelectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontWeight: "500",
      fontSize: fontSize || "12px",
      minHeight: "40px",
      borderWidth: "1px",
      borderColor: state.isFocused
        ? "#2D2D2D"
        : meta.touched && meta.error
        ? "#d32f2f"
        : "#70707059",
      borderRadius: "0.3rem",
      //borderRadius: template === templates.recruiter ? "0.3rem" : "0.75rem",
      "&:hover": {
        border: "2px solid #2D2D2D",
      },
      //backgroundColor: "#FFFFFF",

      // This line disable the blue border
      boxShadow: "none",
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      ...provided,
      color: "#2D2D2D",
      background: "white",
      ":hover": {
        background: "#7070701C",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: fontSize || "12px",
      fontWeight: "400",
      color: "#7070708C",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: fontSize || "12px",
      position: "absolute",
      //The below width line is commented because it makes entire for scrollable
      //width: "max-content",
      minWidth: "100%",
      // border: "0 !important",
      border: "1px solid #70707059",
      boxShadow: "0 !important",
      "&:hover": {
        border: "1px solid #70707059",
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: "#0071B6",
      color: "white",
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px",
      height: "20px",

      "&:hover": {
        backgroundColor: "#0071B6",
        color: "white",
      },
    }),
    multiValueLabel: (data) => ({
      ...data,
      height: "20px",
      padding: "5px 6px",
      fontSize: "0.8125rem",
      color: "white !important",
    }),
    multiValue: (data) => ({
      ...data,
      height: "20px",
      padding: "0px 6px",
      backgroundColor: "#0071B6",
      borderRadius: "20px",
    }),
    input: (data) => ({
      ...data,

      "input:focus": {
        boxShadow: "none",
      },
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
  };
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const IconOption = (props) => (
    <components.Option {...props} className="flex justify-between">
      {props.data.label}
      {props.isSelected && <CheckmarkIcon className="text-secondary" />}
    </components.Option>
  );
  return (
    <div className="relative">
      <AsyncSelect
        styles={customSelectStyles}
        className={`input ${rest.className} ${meta.touched && meta.error && "is-danger"}`}
        defaultOptions={optionsData.slice(0, 500)}
        cacheOptions
        loadOptions={loadOptions}
        optionsData={optionsData}
        placeholder={rest.placeholder}
        isMulti={rest.isMulti}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        name={field.name}
        value={optionsData.find((option) => option.value === field.value)}
        onChange={(option) => {
          //console.log(option);
          setFieldTouched(rest.name, true);
          if (rest.onChange) rest.onChange(option);
          if (!rest.isMulti) {
            helpers.setValue(option);
            setFieldValue(rest.name, option.value);
          } else {
            let options = option.map((option) => option.value);
            setFieldValue(rest.name, options);
          }
        }}
        isDisabled={rest.isDisabled}
      />
      <ErrorMessage
        component="div"
        className={`absolute  ${
          quickResume ? "text-resumeTextColor" : "text-[#d32f2f]"
        } text-[11px] mt-0.5 }`}
        name={field.name}
      />
    </div>
  );
};

export default AsyncSelectDropdown;
