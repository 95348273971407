import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Announcement, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { whatsNewRecuriterFunc, whatsNewEmojiFeedback } from "../../lib/admin/alet-apis";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import whatsNew from "../../assets/img/whats-new.png";
import SuccessDialog from "../Dialog/SuccessDialog";
import ErrorDialog from "../Dialog/ErrorDialog";

const MAX_WORDS = 20;
const MAX_DAYS = 60;

const truncateContent = (content) => {
  const words = content.split(" ");
  if (words.length > MAX_WORDS) {
    return words.slice(0, MAX_WORDS).join(" ") + "...";
  }
  return content;
};

const isRecent = (date) => {
  const daysDiff = dayjs().diff(dayjs(date), "day");
  return daysDiff <= MAX_DAYS;
};

export default function Index(props) {
  // const { openDrawer, setSearchText } = props;
  const { t } = useTranslation();
  const [state, setState] = useState({
    right: false,
  });
  const [expandedCards, setExpandedCards] = useState({});
  const [whatsnewData, setWhatsNewData] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [message, setMessage] = useState("");

  const { mutate: fetchWhatsNew } = useMutation(whatsNewRecuriterFunc, {
    onSuccess: (response) => {
      console.log("data ky aaya", response?.data?.data);
      const filteredData = response?.data?.data.filter((item) =>
        isRecent(item.updatedAt)
      );
      // console.log("filteredData",filteredData)
      setWhatsNewData(filteredData);
    },
    onError: (response) => {},
  });
  const { mutate: EmojiFeedback, refetch } = useMutation(whatsNewEmojiFeedback);

  useEffect(() => {
    fetchWhatsNew();
  }, []);

  const handleToggle = (id) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const toggleDrawer = (anchor, open) => (event) => {
    // Close on escape key
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift" || event.key === "Escape")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleDrawerClose = () => {
    setState({ ...state, right: false });
  };

  const handleDrawerClick = (event) => {
    event.stopPropagation();
  };

  // const handleLearnMoreClick = (cardId) => (event) => {
  //   event.preventDefault();
  //   setExpandedCards((prevState) => ({
  //     ...prevState,
  //     [cardId]: !prevState[cardId],
  //   }));
  // };

  const handlefeedback = (whatsnew_id, id) => {
    EmojiFeedback(
      { whatsnew_id: whatsnew_id, feedback_value: id },
      {
        onSuccess: (response) => {
          setMessage(response?.data?.message);
          setSuccessDialog(true);
          // refetch();
        },
        onError: (response) => {
          setMessage(response?.message);
          setErrorDialog(true);
        },
      }
    );
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
      }}
      role="presentation"
      onClick={handleDrawerClick}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ccc",
          background:
            "linear-gradient(to right, rgba(62, 90, 110, 1) 0%, rgba(15, 43, 63, 1) 100%)",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: "white", flexGrow: 1, marginRight: 2, whiteSpace: "nowrap" }}
        >
          What's New
        </Typography>
        {/* <div className='relative flex items-center rounded-lg h-[35px] w-full max-w-[250px] bg-[#EFF0F3] text-xs'>
          <Search className='absolute left-3 w-5 h-5' />
          <input
            className='w-full h-full pl-10 rounded-lg bg-transparent text-[#565656] font-medium'
            placeholder={t("_Search")}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div> */}
        <IconButton
          onClick={handleDrawerClose}
          aria-label="close"
          sx={{ color: "white", marginLeft: 2 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {whatsnewData?.map((item) => {
        const combinedContent = `${item.category_name} ${item.whatsnew_title} ${item.whatsnew_content}`;
        const totalWords = combinedContent.split(" ").length;
         console.log("item",item)
        return (
          <Card key={item.whatsnew_id} className="relative m-2 p-3">
            <div className="text-sm my-2">
              <span
                className={`text-xs ${
                  item?.category_name === "Announcement" ? `bg-[#e77817]` : `bg-[#0071b6]`
                } text-white border rounded-[30px] px-2 py-1`}
              >
                {item.category_name}
              </span>{" "}
              <span className="text-xs text-[#a8b9c6] font-medium mx-3">
                {dayjs(item?.updatedAt).format("DD/MM/YYYY")}
              </span>
            </div>
            <h4 className="text-sm font-semibold text-[#596fb5] my-2">
              {item?.whatsnew_title}
            </h4>
            <div className="text-xs mb-8">
              {item.whatsnew_content.split("\n").map((str, index) => {
                const isExpanded = expandedCards[item.whatsnew_id];
                return (
                  <p className="text-xs my-2 text-[#808080]" key={index}>
                    {isExpanded || totalWords <= MAX_WORDS ? str : truncateContent(str)}
                  </p>
                );
              })}
              {totalWords > MAX_WORDS && (
                <a
                  href="#"
                  className="text-blue-500 hover:text-blue-700 text-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggle(item.whatsnew_id);
                  }}
                >
                  {expandedCards[item.whatsnew_id] ? "Read less" : "Read more"}
                </a>
              )}
            </div>

            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2 mt-5">
              <button
                className="text-2xl"
                onClick={() => handlefeedback(item?.whatsnew_id, "1")}
              >
                😢
              </button>
              <button
                className="text-2xl"
                onClick={() => handlefeedback(item?.whatsnew_id, "2")}
              >
                😐
              </button>
              <button
                className="text-2xl"
                onClick={() => handlefeedback(item?.whatsnew_id, "3")}
              >
                😊
              </button>
            </div>
          </Card>
        );
      })}
    </Box>
  );

  return (
    <React.Fragment>
      <Badge onClick={toggleDrawer("right", !state.right)} style={{ width: "40px" }}>
        <img src={whatsNew} alt="whatsNew" width={"100%"} height={"auto"} />
      </Badge>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={handleDrawerClose}
        onOpen={() => setState({ ...state, right: true })}
        PaperProps={{
          sx: {
            flexShrink: 0,

            width: {
              xs: "100%", // Full width on extra small screens
              sm: 400, // 400px width on small screens and up
            },
          },
        }}
      >
        {list("right")}
      </SwipeableDrawer>
      {successDialog ? (
        <SuccessDialog
          message={message}
          open={successDialog}
          onOkayClick={() => setSuccessDialog(false)}
        ></SuccessDialog>
      ) : null}
      {errorDialog ? (
        <ErrorDialog
          message={message}
          open={errorDialog}
          onClick={() => setErrorDialog(false)}
        ></ErrorDialog>
      ) : null}
    </React.Fragment>
  );
}
