import React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

const index = (props) => {
  return (
    <Box
      sx={{
        width: props.width ? props.width : 200,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        precision={1}
        name="interviewRating"
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        {...props}
      />
      {/* <Box>{labels[values.interviewRating]}</Box> */}
    </Box>
  );
};

export default index;
