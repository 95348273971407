import React from "react";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import Select from "../FormsUI/Select";
import {
  companyNameValidation,
  fromValidation,
  workExperienceToValidation,
  projectRoleValidation,
  designationValidation,
  jobSummaryValidation,
} from "../../common/yupValidations";
import MonthPicker from "../FormsUI/MonthYearPicker";
import TextField from "../FormsUI/TextField";
import { BackArrowIcon } from "../../icons";
import Button from "../Button/Button";
import CloseButton from "../Button/OutlinedButton";
import { useLocation, useNavigate } from "react-router";
import { roleOptions } from "../../common/constants";
import _ from "lodash";
import TextAreaWrapper from "../FormsUI/TextArea";
const AddWorkExperienceForm = ({
  isEdit,
  setFieldValue,
  workExperienceArray,
  onSaveClick,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  let item = location.state;
  let newAdded = workExperienceArray || [];

  return (
    <div className="flex justify-center mt-8">
      <div className="">
        <div className="flex gap-4 mb-8">
          <BackArrowIcon
            onClick={() => {
              navigate(-1);
            }}
            className="w-5"
          />
          <label className="text-inputColor font-medium">{t("BACK")}</label>
        </div>
        <Box
          sx={{
            // width: "600px",
            backgroundColor: "#FAFAFAB3",
            border: "2px solid #E8E8E8",
            borderRadius: "10px",
            paddingY: "30px",
            paddingX: "40px",
          }}
        >
          <Formik
            initialValues={{
              designation: item?.title || "",
              companyName: item?.companyName || "",
              from: item?.from || null,
              to: item?.to || null,
              // role: item?.role || "",
              currentlyWorkHere: item?.currentlyWorkHere || false,
              description: item?.description || "",
            }}
            enableReinitialize={true}
            validateOnMount
            validationSchema={Yup.object().shape({
              designation: designationValidation,
              companyName: companyNameValidation,
              // role: projectRoleValidation,
              from: fromValidation,
              to: workExperienceToValidation,
              description: jobSummaryValidation,
            })}
          >
            {({ errors, status, isValid, values, handleChange, handleBlur }) => (
              <Form>
                <div>
                  <label className="text-inputColor font-semibold">
                    {isEdit ? t("_Edit_Work_Experience") : t("_Add_Work_Experience")}
                  </label>

                  <div>
                    <div className="mt-9">
                      <TextField
                        name="designation"
                        label="_Designation"
                        inputClassName={"bg-white rounded"}
                      ></TextField>
                    </div>
                    <div className=" mt-5">
                      <TextField name="companyName" label="_Company_name"></TextField>
                    </div>
                    <div className="flex justify-between mt-5 gap-5">
                      <MonthPicker
                        name="from"
                        label="_From"
                        isEdit={isEdit}
                      ></MonthPicker>
                      <MonthPicker name="to" label="_To" isEdit={isEdit}></MonthPicker>
                    </div>
                    {/* <div className="mt-5">
                      <Select name={`role`} label="_Role" options={roleOptions} />
                    </div> */}

                    <TextAreaWrapper
                      name="description"
                      label="_Job_Summary"
                      layoutClassName="mt-4"
                      labelClassName="mb-0"
                      labelColor={"text-formLabelColor"}
                      minRows={4}
                    ></TextAreaWrapper>
                    <div
                      className="relative "
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        checked={values.currentlyWorkHere}
                        name={`currentlyWorkHere`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ transform: "scale(0.8)" }}
                        className="p-0"
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={
                          values.to
                            ? new Date(values.to).getFullYear() !==
                                new Date().getFullYear() ||
                              new Date(values.to).getMonth() !== new Date().getMonth()
                            : false
                        }
                      />
                      <span className="text-xs text-primary font-semibold">
                        {t("_I_currently_work_here.")}
                      </span>
                    </div>
                  </div>

                  <div className="flex gap-5 mt-5">
                    <Button
                      onClick={() => {
                        if (isEdit) {
                          let newData = Object.assign(newAdded[item?.index], {
                            title: values.designation,
                            companyName: values.companyName,
                            from: values.from,
                            to: values.to,
                            currentlyWorkHere:
                              values.to &&
                              (new Date(values.to).getFullYear() !==
                                new Date().getFullYear() ||
                                new Date(values.to).getMonth() !== new Date().getMonth())
                                ? false
                                : values.currentlyWorkHere,
                            description: values.description,
                          });
                          newAdded[item?.index] = newData;
                          newAdded.sort((a, b) => new Date(b.from) - new Date(a.from));
                          setFieldValue(`workExperiences`, newAdded);
                        } else {
                          newAdded.push({
                            title: values.designation,
                            companyName: values.companyName,
                            from: values.from,
                            to: values.to ? values.to : null,
                            description: values.description,
                            currentlyWorkHere:
                              values.to &&
                              (new Date(values.to).getFullYear() !==
                                new Date().getFullYear() ||
                                new Date(values.to).getMonth() !== new Date().getMonth())
                                ? false
                                : values.currentlyWorkHere,
                          });
                          newAdded.sort((a, b) => new Date(b.from) - new Date(a.from));
                          setFieldValue(`workExperiences`, newAdded);
                        }
                        navigate(-1);
                      }}
                      buttonClassName="w-24 h-9 px-10 py-4 text-xs"
                      label="_SAVE"
                      disabled={!isValid}
                    />
                    <CloseButton
                      onClick={() => {
                        navigate(-1);
                      }}
                      label="_Cancel"
                      buttonClassName="w-24 h-9 px-10 py-4 text-xs"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
};

export default AddWorkExperienceForm;
