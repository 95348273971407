import React, { useState } from "react";
import { useField } from "formik";
import InputLabel from "../InputLabel";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { twMerge } from "tailwind-merge";

const TextAreaWrapper = ({
  name,
  label,
  labelColor,
  labelClassName,
  layoutClassName,
  textAreaClassName,
  padding_y,
  padding_x,
  error,
  ...rest
}) => {
  const [field, meta] = useField({ name, error });
  const [isFocused, setIsFocused] = useState(false);

  const styles = {
    textAreaInput: twMerge(
      `border w-full mt-0 py-${padding_y ? padding_y : 3} px-${
        padding_x ? padding_x : 3
      } text-xs rounded text-inputColorSecondary ${
        meta?.error && meta?.touched ? "border-[#D32F2F]" : "border-[#70707059]"
      }`,
      // {
      //   border: "2px solid",

      //   borderColor: meta.error ? "#D32F2F" : "#70707059",
      // },
      textAreaClassName
    ),
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const configTextField = {
    ...field,
    ...rest,
    //className: `${styles.textAreaInput}`,
    //Commented below 2 lines because on clear of textfield its not showing error
    //onFocus: handleFocus,
    //onBlur: handleBlur,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
  }

  return (
    <div className={layoutClassName}>
      {label && (
        <InputLabel
          label={label}
          color={labelColor}
          labelClassName={labelClassName}
          isMandatory={rest.isMandatory}
        />
      )}
      <TextareaAutosize {...configTextField} className={styles.textAreaInput} />
      {meta.error && meta.touched ? (
        <p className="text-[11px] text-[#D32F2F] -mt-1">{meta?.error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TextAreaWrapper;
