import * as React from "react";

import {
  AddIcon,
  CheckMarkOutlineIcon,
  CheckmarkDoneIcon,
  PenIcon,
  RedTickCircleIcon,
} from "../../icons";
import PageHeader from "../Title";
import UploadImage from "../Files/UploadImage";
import ResumeTimeline from "./ResumeTimeline";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CssTooltip } from "../StyledComponent";

const ResumeRightSideCard = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  isNextButtonClicked,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="flex justify-between mx-4">
        <div>
          <div className="flex flex-grow gap-2 items-center">
            <p className="text-2xl font-medium text-secondary">{values?.firstName}</p>
            <p className="text-2xl font-medium text-secondary">{values?.lastName}</p>
            <PenIcon
              onClick={() => {
                var newurl = `?type=${"CD"}&op=edit`;
                navigate(newurl);
              }}
              className="h-4 w-4 text-[#B0B0B0]"
            />
          </div>

          <p className="text-sm font-medium text-secondary">
            {values?.currentDesignation},
            {values?.currentWorkLocation?.city?.split(",")[0]}
          </p>
          <p className="text-sm font-medium text-secondary">
            {values?.mobile}, {values?.email}
          </p>
        </div>
        <div className="flex gap-11">
          {/* <div className="first-step w-6 h-6">
            {values.isContactNSummaryVerified ? (
              <CheckmarkDoneIcon />
            ) : !values?.isContactNSummaryVerified && isNextButtonClicked ? (
              <RedTickCircleIcon
                onClick={() => setFieldValue("isContactNSummaryVerified", true)}
              ></RedTickCircleIcon>
            ) : (
              <CssTooltip
                title={t("_Confirm_contact_Info_and_career_summary")}
                placement="top"
                arrow
              >
                <CheckMarkOutlineIcon
                  onClick={() => setFieldValue("isContactNSummaryVerified", true)}
                />
              </CssTooltip>
            )}
          </div> */}
          <div className="w-[200px] h-[200px]">
            <UploadImage
              setFieldValue={setFieldValue}
              name={"profilePicture"}
              value={values.profilePicture || ""}
              showDeleteDialog={false}
            ></UploadImage>
          </div>
        </div>
      </div>
      <div className="mx-4 mt-2">
        <div className="flex gap-5  items-center">
          <PageHeader title={"_Career_Summary"} titleClassName={"font-medium text-sm"} />
          <PenIcon
            onClick={() => {
              var newurl = `?type=${"CD"}&op=edit`;
              navigate(newurl);
            }}
            className="h-4 w-4 text-[#B0B0B0]"
          />
        </div>
        <p className="text-medium text-tertiary mt-[15px]">{values.careerSummary}</p>
      </div>

      <div className="flex mx-4 items-center  mt-16 mb-7">
        <PageHeader title={"_Work_Experience"} titleClassName={"font-medium text-xl"} />
        <div className="flex gap-11 ml-5">
          <AddIcon
            onClick={() => {
              var newurl = `?type=${"WE"}&op=add`;
              navigate(newurl);
            }}
          />
          {/* <div className="second-step ">
            {values.isWorkExpVerified ? (
              <CheckmarkDoneIcon />
            ) : !values?.isWorkExpVerified && isNextButtonClicked ? (
              <RedTickCircleIcon
                onClick={() => setFieldValue("isWorkExpVerified", true)}
              ></RedTickCircleIcon>
            ) : (
              <CssTooltip title={t("_Confirm_Work_Experience")} placement="top" arrow>
                <CheckMarkOutlineIcon
                  onClick={() => setFieldValue("isWorkExpVerified", true)}
                />
              </CssTooltip>
            )}
          </div> */}
        </div>
      </div>
      {values?.workExperiences?.map((workExperience, index) => {
        return (
          <ResumeTimeline
            key={index}
            item={workExperience}
            lastIndex={values?.workExperiences.length - 1 === index}
            name={"workExperiences"}
            type={"WE"}
            index={index}
            setFieldValue={setFieldValue}
            data={values.workExperiences}
          />
        );
      })}

      <div className="flex  mx-4 items-center mt-16  mb-7">
        <PageHeader title={"_Education"} titleClassName={"font-medium text-xl"} />
        <div className="flex gap-11 ml-5">
          <AddIcon
            onClick={() => {
              var newurl = `?type=${"E"}&op=add`;
              navigate(newurl);
            }}
          />
          {/* <div className="third-step">
            {values.isEducationVerified ? (
              <CheckmarkDoneIcon />
            ) : !values?.isEducationVerified && isNextButtonClicked ? (
              <RedTickCircleIcon
                onClick={() => setFieldValue("isEducationVerified", true)}
              ></RedTickCircleIcon>
            ) : (
              <CssTooltip title={t("_Confirm_Education")} placement="top" arrow>
                <CheckMarkOutlineIcon
                  onClick={() => setFieldValue("isEducationVerified", true)}
                />
              </CssTooltip>
            )}
          </div> */}
        </div>
      </div>

      {!values?.educations || values?.educations?.length === 0 ? (
        <div className="border border-errorBorder text-medium text-xs w-[408px] h-[70px] bg-white mx-4">
          <p className="test-xs text-title mx-5 my-4">
            {t(
              "_Atleast_one_education_detail_is_required_to_provide_accurate_job_recommendations."
            )}
          </p>
        </div>
      ) : (
        values?.educations?.map((education, index) => {
          return (
            <ResumeTimeline
              key={index}
              item={education}
              lastIndex={values?.educations.length - 1 === index}
              name={"educations"}
              type={"E"}
              index={index}
              setFieldValue={setFieldValue}
              data={values.educations}
            />
          );
        })
      )}
    </div>
  );
};

export default ResumeRightSideCard;
