import React from "react";
import { useTranslation } from "react-i18next";
import Rating from "../Rating";

const AssessedSkills = ({ skillName, skillLevel }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center mb-4">
      <div className="text-resumeColor text-medium" style={{ minWidth: "200px" }}>
        {skillName}
      </div>
      <div className="flex-grow ml-auto">
        <Rating value={skillLevel} readOnly />
      </div>
    </div>
  );
};

export default AssessedSkills;
