import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { TextareaAutosize } from "@mui/material";
import { templates } from "../../common/constants";
import CheckBox from "@mui/icons-material/CheckBox";
const CssTextField = styled(TextField)((props) => ({
  "& label.Mui-focused": {
    color: "#2D2D2D",
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: "white",

    "& fieldset": {
      border: "1px solid #70707059",
      borderRadius: "5px",
      //borderRadius: props.template === templates.recruiter ? "5px" : "10px",
    },

    "&:hover fieldset": {
      border: "2px solid #2D2D2D",
    },
    "& input:disabled+fieldset": "transparent",

    // "& input:disabled+fieldset": {
    //   backgroundColor: props.isDisabledBackground
    //     ? "rgba(187, 187, 186, 0.3)"
    //     : "transparent",
    // },

    "& input:disabled": {
      // opacity: props.isDisabledBackground ? "" : "40%",
      opacity: "40%",
    },

    "& .MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(0, 0, 0, 1)",
    },

    "&.Mui-focused fieldset": {
      border: "2px solid #2D2D2D",
    },
  },
}));

const CssTextFieldRounded = styled(TextField)((props) => ({
  "& label.Mui-focused": {
    color: "#2D2D2D",
  },
  "& .MuiOutlinedInput-root": {
    minHeight: "50px",
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: "white",

    "& fieldset": {
      border: "1px solid #70707059",
      borderRadius: "9999px",
      //borderRadius: props.template === templates.recruiter ? "5px" : "10px",
    },

    "&:hover fieldset": {
      border: "2px solid #2D2D2D",
    },
    "& input:disabled+fieldset": "transparent",

    // "& input:disabled+fieldset": {
    //   backgroundColor: props.isDisabledBackground
    //     ? "rgba(187, 187, 186, 0.3)"
    //     : "transparent",
    // },

    "& input:disabled": {
      // opacity: props.isDisabledBackground ? "" : "40%",
      opacity: "40%",
    },

    "& .MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "rgba(0, 0, 0, 1)",
    },

    "&.Mui-focused fieldset": {
      border: "2px solid #2D2D2D",
    },
  },
}));

const CssTextArea = styled(TextareaAutosize)({
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: 1.5,
  width: "100%",
  padding: 12,
  height: "120px",
  border: "1px dashed #A7A9AC",
  color: "#2D2D2D",
  "&:hover": {
    borderColor: "#2D2D2D",
  },

  "&:focus": {
    borderColor: "#2D2D2D",
  },

  // firefox
  " &:focus-visible": {
    outline: 0,
  },
});

const CssSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#0071B6",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CssSelect = styled(Select)((props) => ({
  "&.MuiOutlinedInput-root": {
    height: "40px",
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: "white",
    "& fieldset": {
      border: "1px solid #70707059",
      borderRadius: "5px",
      // borderRight: "0px",
      // borderRadius: props.template === templates.recruiter ? "5px" : "10px",
      // borderTopLeftRadius: "10px",
      // borderBottomLeftRadius: "10px",
    },
    "&:hover fieldset": {
      border: "2px solid #2D2D2D",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid #2D2D2D",
    },
  },
}));

const CssAutoComplete = styled(Autocomplete)(() => ({
  "&.MuiAutocomplete-root": {
    "& fieldset": {
      border: "1px solid #70707059",
      // borderRight: "0px",
      // borderRadius: "0px",
      //  borderTopLeftRadius: "10px",
      //  borderBottomLeftRadius: "10px",
    },
    "&:hover fieldset": {
      border: "2px solid #2D2D2D",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid #2D2D2D",
    },
  },
}));

const CssSelectTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#2D2D2D",
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "15px",
    fontWeight: "500",
    "& fieldset": {
      border: "1px solid #70707059",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: "2px solid #2D2D2D",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid #2D2D2D",
    },
  },
});

const CssPopper = styled(Popper)`
  && {
    z-index: 1;
    inset: auto auto 15px 0 !important;
    &[x-placement*="bottom"] .arrow {
      width: 0;
      height: 0;
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-bottom: 1em solid #2c3e50;
      margin-top: -0.9em;

      &:before {
        border-width: "0 1em 1em 1em";
        border-color: "transparent transparent white transparent";
      }
    }

    &[x-placement*="top"] .arrow {
      bottom: 5;
      width: 0;
      height: 0;
      border-left: 1em solid transparent;
      border-right: 1em solid transparent;
      border-top: 1em solid #2c3e50;
      margin-bottom: -0.9em;

      &:before {
        border-width: 1em 1em 0 1em;
        border-color: white transparent transparent transparent;
      }
    }

    &[x-placement*="right"] .arrow {
      left: 0;
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;
      border-right: 1em solid #2c3e50;
      margin-left: -0.9em;

      &:before {
        border-width: 1em 1em 1em 0;
        border-color: transparent white transparent transparent;
      }
    }

    &[x-placement*="left"] .arrow {
      right: 0;
      width: 0;
      height: 0;
      border-top: 1em solid transparent;
      border-bottom: 1em solid transparent;
      border-left: 1em solid #2c3e50;
      margin-right: -0.9em;

      &:before {
        border-width: 1em 0 1em 1em;
        border-color: transparent transparent transparent white;
      }
    }

    .arrow {
      position: absolute;
      font-size: 7px;
      width: 3em;
      height: 3em;

      &:after {
        content: " ";
        position: absolute;
        top: 29px;
        left: 55px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid white;
        filter: drop-shadow(-1px 3px -2px rgba(0, 0, 0, 0.05));
      }
    }

    .popper-content {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 4px;
      color: #2d2d2d;
      height: 30px;
      width: 130px;
    }
  }
`;

const CssTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#2D2D2D",
    fontSize: 12,
    // border: "2px solid #7070708C",
    padding: "10px 20px",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 5,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      //boxShadow: "3px 3px 3px 3px #00000029",
      //border: "0.5px solid #7070708C",
    },
  },
}));

const CssInputTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#d32f2f",
    fontSize: 12,
    // border: "2px solid #7070708C",
    padding: "2px 5px",
    boxShadow: "0px 3px 6px #00000029",
    marginTop: "-2px !important",
    borderRadius: 5,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      //boxShadow: "3px 3px 3px 3px #00000029",
      //border: "0.5px solid #7070708C",
    },
  },
}));

const CssNotificationTooltip = styled(({ className, ...props }) => (
  <div className="relative">
    {props.open ? (
      <div
        className="absolute right-2 lg:right-4 top-[42px] bg-white border-t border-l border-[#7070704D] w-10 h-10 rotate-45"
        style={{ zIndex: 9999 }}
      ></div>
    ) : null}
    <Tooltip {...props} classes={{ popper: className }} />
  </div>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#2D2D2D",
    boxShadow: "0px 3px 6px #00000029",
    maxWidth: "420px",
    maxHeight: "530px",
    padding: 0,
    top: "25px",
    right: "-50px",
    margineRight: "-50px",
    borderRadius: "10px",
    border: "1px solid #7070704D",
    paddingBottom: "10px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    height: "30px",
    width: "40px",

    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      right: "-30px",
      height: "30px",
      width: "40px",
      // paddingTop: "-20px"
    },
  },
}));

const CssCheckBox = styled(CheckBox)((props) => ({ color: "#0071B6" }));

export {
  CssSwitch,
  CssTextField,
  CssSelect,
  CssAutoComplete,
  CssSelectTextField,
  CssPopper,
  CssTooltip,
  CssTextArea,
  CssCheckBox,
  CssNotificationTooltip,
  CssInputTooltip,
};
