import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemedSuspense from "./components/ThemedSuspense";
import { AuthProvider } from "./context/AuthContext";
// import {WebSocketProvider} from './context/WebSocketContext'
import "../src/translations/i18n";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import StepContext from "./context/MultiStepFormContext";
import { SidebarProvider } from "./context/SidebarContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ResumeUploadModelProvider } from "./context/ProfileDetailsContext";
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const THEME = createTheme({
  typography: {
    fontFamily: `"Sora"`,
  },

  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDrawer: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <SidebarProvider>
          <AuthProvider>
            {/* <WebSocketProvider> */}
            <Suspense fallback={<ThemedSuspense />}>
              <StepContext>
                <ResumeUploadModelProvider>
                  <ThemeProvider theme={THEME}>
                    <StyledEngineProvider injectFirst>
                      <App />
                    </StyledEngineProvider>
                  </ThemeProvider>
                </ResumeUploadModelProvider>
              </StepContext>
            </Suspense>
            {/* </WebSocketProvider> */}
          </AuthProvider>
        </SidebarProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
