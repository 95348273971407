import Skeleton from "@mui/material/Skeleton";
import React from "react";

const SidemenuSkltn = ({ state }) => {
  const menuTransistionStyle = {
    entering: { display: "none" },
    entered: { display: "block" },
    exiting: { display: "block" },
    exited: { display: "none" },
  };

  return (
    <div className="grow overflow-x-hidden  flex flex-col h-full">
      <ul className="mt-10">
        {/* {menu.map((route, i) => */}
        {[...Array(7).keys()]?.map((key) => (
          <li className="relative px-5 py-3" key={key}>
            <div className="inline-flex items-center w-full t transition-colors duration-150  p-2 rounded-md">
              <div className={`flex items-center gap-3`}>
                <div className="">
                  <Skeleton variant="rounded" className="w-6 h-5" />
                </div>

                <div style={{ ...menuTransistionStyle[state] }} className="text-xs">
                  <Skeleton variant="text" sx={{ fontSize: "30rem" }} />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidemenuSkltn;
