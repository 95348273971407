import { CssTextField, CssSelect } from "../../StyledComponent";
import { useField, useFormikContext } from "formik";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import InputLabel from "../InputLabel";
import { DownIcon } from "../../../icons";
import { AuthContext } from "../../../context/AuthContext";
import FormHelperText from "@mui/material/FormHelperText";
import { useContext } from "react";
import { TextField } from "@mui/material";
const SelectWrapper = ({
  name,
  label,
  options,
  isMandatory,
  labelClassName,
  labelColor,
  placeholder,
  ...rest
}) => {
  const { template } = useContext(AuthContext);
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const handleChange = (e, v, i) => {
    if (rest.onChange) rest.onChange(e, v, i);
    else {
      const { value } = e.target;
      setFieldValue(name, value);
    }
  };
  const { t } = useTranslation();
  const configSelect = {
    ...field,
    ...rest,
    ...placeholder,
    fullWidth: true,
    IconComponent: DownIcon,
    onChange: rest.onChange ? rest.onChange : handleChange,
    //placeholder: placeholder || "Select an option",
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  return (
    <div className="relative">
      {/* <div className="text-formLabelColor text-medium mb-2">{t(label)}</div> */}
      {label ? (
        <InputLabel
          label={label}
          color={labelColor}
          labelClassName={labelClassName}
          isMandatory={isMandatory}
        />
      ) : null}
      <CssSelect
        {...configSelect}
        key={field.value}
        MenuProps={{
          sx: {
            height: "300px",
            maxHeight: "350px",
            maxWidth: "200px",
          },
        }}
        sx={{
          "& .MuiSelect-icon": {
            marginRight: "10px",
            height: "7px",
            width: "14px",
            marginTop: "2px",
          },
        }}
        template={template}
        displayEmpty={true}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     placeholder={placeholder}
        //     className="text-primary text-medium"
        //   />
        // )}
      >
        {options?.map((item, i) => {
          if (item?.value) {
            return (
              <MenuItem key={i} value={item.value}>
                <div className="text-xs">{item.label}</div>
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={i} value={item}>
                <div className="text-xs"> {item}</div>
              </MenuItem>
            );
          }
        })}
      </CssSelect>
      {/* {console.log(meta.error)} */}
      {meta.touched && meta.error ? (
        <p className="text-[11px] absolute text-[#d32f2f] mt-0.5">{meta.error}</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectWrapper;
