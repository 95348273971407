import React, { useState, useEffect } from "react";
import JobListingDetails from "./JobListingDetails";
import JobDescription from "../../pages/JobDescription";
import JobBlockDetails from "./JobBlockDetails";

function JobGridView({ data, jobId, refetch }) {
  const [isContainerShrunk, setIsContainerShrunk] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    if (jobId) {
      const job = data?.data?.data.find((company) => company.job_id === jobId);
      if (job) {
        setSelectedJob(job);
        setIsContainerShrunk(true);
      }
    }
  }, [data, jobId]);

  const handleClickDetail = (job) => {
    setSelectedJob(job);
    setIsContainerShrunk(true);
  };

  const handleCloseJobDescription = () => {
    setIsContainerShrunk(false);
    setSelectedJob(null);
  };

  //console.log("123", isContainerShrunk, selectedJob);

  return (
    <div
      //className="grid md:flex"
      className={`grid   ${isContainerShrunk ? "lg:grid-cols-2" : "lg:grid-cols-1"}`}
    >
      <div
        className={`grid grid-cols-1 ${
          isContainerShrunk === true
            ? "lg:grid-cols-2 gap-4  max-w-[740px]"
            : "lg:grid-cols-4 gap-6"
        }    grid-flow-row`}
      >
        {/* // <div className="border w-full border-borderCompany rounded-lg"> */}
        {data?.data?.data?.map((company, index) => (
          <div className="border border-borderCompany " key={index}>
            <JobBlockDetails
              onClickDetail={() => handleClickDetail(company)}
              isContainerShrunk={isContainerShrunk}
              setSelectedJob={setSelectedJob}
              company={company}
              refetch={refetch}
            />
            <div className="block lg:hidden">
              {selectedJob && selectedJob.job_id === company.job_id && (
                <JobDescription
                  selectedJob={selectedJob}
                  onClose={handleCloseJobDescription}
                  refetch={refetch}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {isContainerShrunk && (
        <div className=" border border-borderCompany rounded-lg ml-5 max-w-[598px] hidden lg:block">
          {selectedJob && (
            <JobDescription
              selectedJob={selectedJob}
              onClose={handleCloseJobDescription}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default JobGridView;
