import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { DeleteIcon, PenIcon } from "../../icons";
import { Navigate, useNavigate } from "react-router";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const ResumeTimeline = ({ item, lastIndex, setFieldValue, name, type, data, index }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  //console.log("item", item);
  if (Object.keys(item).length === 0) {
    return null;
  }

  const fromDate = item.from !== "" ? dayjs(item.from).format("MMM-YYYY") : "No date";
  let toDate = "";
  if (item.currentlyWorkHere) {
    toDate = "Currently";
  } else if (item.to) {
    toDate = dayjs(item.to).format("MMM-YYYY");
  } else {
    toDate = "No date";
  }

  //console.log("data", item.currentlyWorkHere);
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        paddingY: 0,
      }}
    >
      <TimelineItem sx={{ paddingY: 0 }}>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              borderRadius: 0,
              marginY: 0,
              height: 15,
              width: 15,
              bgcolor: "#C2A477",
            }}
          />
          <TimelineConnector
            sx={{ paddingY: lastIndex ? 0 : "65px", bgcolor: "#C2A477" }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ paddingY: 0, marginTop: -0.3 }}>
          <div>
            <div className="flex gap-10">
              <p className="text-sm text-resumeItemTitle">
                {fromDate} - {toDate}
              </p>
              <div className="flex gap-5">
                <PenIcon
                  onClick={() => {
                    navigate(`?type=${type}&op=edit`, {
                      state: { ...item, index: index },
                    });
                  }}
                  className="h-4 w-4 text-[#B0B0B0]"
                />
                <DeleteIcon
                  className="cursor-pointer"
                  onClick={() => {
                    data.splice(index, 1);
                    setFieldValue(name, data);
                  }}
                />
              </div>
            </div>
            <p className="text-xl text-title font-medium mt-[15px]">{item.title}</p>
            <p className="text-xs font-medium text-title mt-[5px]">
              {item.companyName || item.collegeUniversityName}
            </p>
            <p className="text-xs text-grayTextColor mt-[15px] mb-5">
              {/* <p className="text-medium text-tertiary mt-[15px]"> */}
              {item.cgpa ? `${t("_CGPA/_Score")}: ${item.cgpa}` : item.description}
            </p>
          </div>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default ResumeTimeline;
