import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CheckmarkIcon } from "../../icons";
import DialogActions from "@mui/material/DialogActions";
import OutlinedButton from "../Button/OutlinedButton";
const DeactivateConfirmation = (props) => {
  const { handleDeactivate, maxWidth, onClose, open, message, subContent, ...other } =
    props;
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "80%", maxWidth: maxWidth, maxHeight: 435 },
      }}
      maxWidth="xs"
      open={open}
      {...other}
      className="p-6"
    >
      <DialogTitle className="text-medium">
        <div className="flex flex-row">
          <span className="flex-grow text-sm">{t("_Deactivate")}</span>
          <CloseIcon
            className="w-3 h-3 text-right text-closeIcon mx-1 cursor-pointer"
            onClick={onClose}
          ></CloseIcon>
        </div>
      </DialogTitle>
      <DialogContent className={`text-xs pt-5 border-t border-dialogBorder`}>
        {message}
      </DialogContent>
      <DialogActions className="mb-5">
        <OutlinedButton
          autoFocus
          onClick={onClose}
          label="_CANCEL"
          buttonClassName={"mr-4 text-sm w-[90px]"}
        ></OutlinedButton>
        <Button
          onClick={handleDeactivate}
          label="_DEACTIVATE"
          buttonClassName={"text-sm w-[110px] mr-5"}
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeactivateConfirmation;
