import React, { useState, useContext } from "react";
//import { DashboardIcon, ListViewIcon } from "../icons";
import CompanyListView from "../components/JobList/CompanyJobList";
import { useQuery } from "react-query";
import { getAdminJobsSearch, getJobsList, getOpenJobsSearch } from "../lib/jobs-api";
import { category, pagination, templates } from "../common/constants";
import { AuthContext } from "../context/AuthContext";
//import FilterJobs from "./Jobs/FilterJobs";
import ViewButtons from "../components/ViewButtons";
import { useLocation } from "react-router-dom";
import JobBlockDetails from "../components/JobList/JobBlockDetails";
import JobListSktln from "../components/skeloton/JobListSktln";
import { useTranslation } from "react-i18next";
//import { getProfileDetails } from "../lib/userprofile-api";
import { useSearchParams } from "react-router-dom";
import Pagination from "../components/Pagination/Pagination";
import JobGridView from "../components/JobList/JobGridView";
import { filterTypes } from "../common/constants";
import FilterJobs from "./Jobs/FilterJobs";
import SearchJobFilter from "../components/Filters/SearchJobFilter";
import MascotIcon from "../assets/img/mascot.png";

function SearchJobs() {
  const { t } = useTranslation();
  const { userId, template } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get("q");
  const cat = category.search;
  // const pagesize = pagination.pageSize;
  const job = useLocation();
  const jobId = job.state?.jobId;
  const [currentPage, setCurrentPage] = useState(0);
  const [showListView, setShowListView] = useState(true);
  const [showBlockView, setShowBlockView] = useState(false);
  const [pagesize, setPagesize] = useState(pagination.pageSize);
  const [jobRoles, setJobRoles] = useState([]);
  const [filterJobRoles, setFilterJobRoles] = useState([]);
  const [seeAllRoles, setSeeAllRoles] = useState(5);
  const [userSkills, setUserSkills] = useState([]);
  const [userExperience, setUserExperience] = useState(0);
  const [skill, setSkill] = useState({
    selectedSkillFilterTypes: 0,
    selectedSkillOptions: [],
  });

  const [experience, setExperience] = useState({
    selectedExperienceFilterTypes: 0,
    selectedExperienceOptions: [],
  });
  const [employmentType, setEmploymentType] = useState({
    selectedEmploymentFilterTypes: 0,
    selectedEmploymentOptions: [],
  });
  const [recentlyPosted, setRecentlyPosted] = useState({
    selectedRecentlyPostedFilterTypes: 0,
    selectedRecentlyPostedOptions: [],
  });
  const [companyList, setCompanyList] = useState({
    selectedCompanyFilterTypes: 0,
    selectedCompanyOptions: [],
  });
  const [location, setLocation] = useState({
    selectedLocationFilterTypes: 0,
    selectedLocationOptions: [],
  });
  const [industryList, setIndustryList] = useState({
    selectedIndustryFilterTypes: 0,
    selectedIndustryOptions: [],
  });
  const [workMode, setWorkMode] = useState({
    selectedWorkModeFilterTypes: 0,
    selectedWorkModeOptions: [],
  });

  const {
    data,
    isLoading: isSavedJobLoading,
    refetch,
  } = useQuery(
    [
      "/jobsList",
      userId,
      cat,
      {
        selectedSkillOptions: [searchValue],
      },
      experience,
      employmentType,
      recentlyPosted,
      companyList,
      location,
      industryList,
      workMode,
      currentPage,
      pagesize,
    ],
    templates.candidate === template
      ? getJobsList
      : templates.platformAdmin === template
      ? getAdminJobsSearch
      : getOpenJobsSearch,
    {
      retry: false,
      enabled: searchValue ? true : false,
    }
  );

  const handleListViewClick = () => {
    setShowListView(true);
    setShowBlockView(false);
  };
  const handleBlockViewClick = () => {
    setShowListView(false);
    setShowBlockView(true);
  };

  const handleApplyFilters = (filterType, selectedOptions) => {
    setCurrentPage(1);
    switch (filterType) {
      case filterTypes.skill:
        setSkill({
          selectedSkillFilterTypes: filterType,
          selectedSkillOptions: selectedOptions,
        });
        break;
      case filterTypes.experience:
        setExperience({
          selectedExperienceFilterTypes: filterType,
          selectedExperienceOptions: selectedOptions,
        });
        break;
      case filterTypes.employmentType:
        setEmploymentType({
          selectedEmploymentFilterTypes: filterType,
          selectedEmploymentOptions: selectedOptions,
        });
        break;
      case filterTypes.recentlyPosted:
        setRecentlyPosted({
          selectedRecentlyPostedFilterTypes: filterType,
          selectedRecentlyPostedOptions: selectedOptions,
        });
        break;
      case filterTypes.companyList:
        setCompanyList({
          selectedCompanyFilterTypes: filterType,
          selectedCompanyOptions: selectedOptions,
        });
        break;
      case filterTypes.location:
        setLocation({
          selectedLocationFilterTypes: filterType,
          selectedLocationOptions: selectedOptions,
        });
        break;
      case filterTypes.industry:
        setIndustryList({
          selectedIndustryFilterTypes: filterType,
          selectedIndustryOptions: selectedOptions,
        });
        break;
      case filterTypes.workLocation:
        setWorkMode({
          selectedWorkModeFilterTypes: filterType,
          selectedWorkModeOptions: selectedOptions,
        });
        break;
      default:
        break;
    }
  };

  const handleResetFilters = () => {
    //console.log("userskills", userSkills);
    setFilterJobRoles([]);
    setSkill({
      selectedSkillFilterTypes: 0,
      selectedSkillOptions: [],
    });
    setExperience({
      selectedExperienceFilterTypes: filterTypes.experience,
      selectedExperienceOptions: [],
    });

    setEmploymentType({
      selectedEmploymentFilterTypes: 0,
      selectedEmploymentOptions: [],
    });
    setRecentlyPosted({
      selectedRecentlyPostedFilterTypes: 0,
      selectedRecentlyPostedOptions: [],
    });
    setCompanyList({
      selectedCompanyFilterTypes: 0,
      selectedCompanyOptions: [],
    });
    setLocation({
      selectedLocationFilterTypes: 0,
      selectedLocationOptions: [],
    });
    setIndustryList({
      selectedIndustryFilterTypes: 0,
      selectedIndustryOptions: [],
    });
    setWorkMode({
      selectedWorkModeFilterTypes: 0,
      selectedWorkModeOptions: [],
    });
  };

  const multiSelectReset = () => {
    setCompanyList({
      selectedCompanyFilterTypes: 0,
      selectedCompanyOptions: [],
    });
    setLocation({
      selectedLocationFilterTypes: 0,
      selectedLocationOptions: [],
    });
    setIndustryList({
      selectedIndustryFilterTypes: 0,
      selectedIndustryOptions: [],
    });
    setWorkMode({
      selectedWorkModeFilterTypes: 0,
      selectedWorkModeOptions: [],
    });
  };

  return (
    <div className="md:py-10 md:px-12 py-5 px-5">
      <p className="text-2xl text-title font-semibold">{t("_Jobs")}</p>

      <SearchJobFilter
        onResetFilterJobs={handleResetFilters}
        onResetJobFilter={multiSelectReset}
        // skill={skill}
        experience={experience}
        userExperience={userExperience}
        employmentType={employmentType}
        recentlyPosted={recentlyPosted}
        onApplyFilters={handleApplyFilters}
      />
      <ViewButtons
        showListView={showListView}
        showBlockView={showBlockView}
        handleListViewClick={handleListViewClick}
        handleBlockViewClick={handleBlockViewClick}
      ></ViewButtons>

      {showListView ? (
        <div>
          {isSavedJobLoading ? (
            // <JobListSktln />
            <div className="flex items-center justify-center w-full h-[70vh]">
              <img
                src={MascotIcon}
                className="h-56 w-40 text-buttonPrimary mx-auto animate-bounce  "
                alt="mascotLoading"
              />
            </div>
          ) : !data?.data?.data || data?.data?.data?.length === 0 ? (
            <div>{t("_No_jobs_available")}</div>
          ) : (
            <CompanyListView data={data} refetch={refetch} isOpenSearch={true} />
          )}
        </div>
      ) : (
        <div>
          {!data?.data?.data || data?.data?.data?.length === 0 ? (
            <div>{t("_No_jobs_available")}</div>
          ) : (
            // <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
            //   {data?.data?.data?.map((company, index) => (
            //     <div className="border border-borderCompany " key={index}>
            //       <JobBlockDetails company={company} refetch={refetch} />
            //     </div>
            //   ))}
            // </div>
            <>
              <JobGridView data={data} jobId={jobId} refetch={refetch} />
            </>
          )}
        </div>
      )}
      {data?.data?.totalItems > 0 ? (
        <div className="mt-5">
          <Pagination
            totalPosts={data?.data?.totalItems}
            paginate={setCurrentPage}
            page={data?.data?.currentPage}
            pagesize={pagesize}
            setPagesize={setPagesize}
          />
        </div>
      ) : null}
    </div>
  );
}

export default SearchJobs;
