import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import flagIcon from "../../assets/img/flag.png";
import { useTranslation } from "react-i18next";
import { QueryClient, useQuery } from "react-query";
import { getNotificationList } from "../../lib/notification-api";
import { AuthContext } from "../../context/AuthContext";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import { pagination } from "../../common/constants";
const Notification = ({
  // messages,
  // isLoading,
  // pageIndex,
  // setPageIndex,
  // pageSize,
  userId,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [mesageArray, setMessageArray] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = pagination.pageSize;

  const {
    data: messages,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(["/notification", userId, pageSize, pageIndex], getNotificationList, {
    enabled: false,
    onSuccess: (resp) => {
      setMessageArray((prev) => [...prev, ...resp?.data?.data]);
    },
  });

  const GetEndMessage = () => {
    return (
      <div className="flex flex-col justify-center items-center gap-2 py-[35px] text-center w-full">
        <img src={flagIcon} className="w-10 h-10" />
        <span className="text-primary text-center text-xs">
          {t("_That's_all_your_notifications_from_the")}
          <br /> {t("_last_30_days")}
        </span>
      </div>
    );
  };

  useEffect(() => {
    refetch();
  }, [pageIndex]);

  const { t } = useTranslation();
  dayjs.extend(customParseFormat);

  const fetchData = () => {
    const totalPages = Math.ceil(messages?.data?.totalCount / pageSize);
    if (pageIndex < totalPages) {
      setHasMore(true);
      setPageIndex(pageIndex + 1);
    } else {
      setHasMore(false);
    }
  };
  return (
    <div className="w-full h-full ">
      <div className="text-medium py-5 font-semibold w-full px-[29px] text-buttonsecondary">
        {t("_Notification")}
      </div>

      <div className="h-full overflow-y-auto">
        {mesageArray?.length > 0 || isFetching || isLoading ? (
          <InfiniteScroll
            dataLength={mesageArray?.length || 0} // This is important field to render the next data
            next={fetchData}
            height={460}
            hasMore={hasMore}
            loader={
              <div
                className={`${
                  pageIndex == 1
                    ? "flex h-full w-full justify-center items-center"
                    : "w-full text-center"
                }`}
              >
                {isFetching && <CircularProgress />}
              </div>
            }
            endMessage={<GetEndMessage />}
          >
            <div className=" w-full overflow-y-auto">
              {/* <div className="max-h-[460px] w-full overflow-y-auto"> */}
              {mesageArray?.map((item, i) => (
                <div
                  key={i}
                  className="flex gap-6 min-h-[50px] py-3.5 px-[29px] items-center hover:bg-bgHover"
                >
                  {/* <Avatar sx={{ width: 32, height: 32, bgcolor: "orangered" }} >{item.name}</Avatar> */}
                  <div className="flex flex-col gap-1">
                    <label
                      className={`text-xs text-buttonsecondary ${
                        item?.read ? "font-normal" : "font-semibold"
                      }`}
                    >
                      {item.description}
                    </label>
                    <label className="text-primary text-xs">
                      {dayjs(item.posted_on, "YYYY-MM-DD").format("DD MMM YYYY")}
                      {", "}
                      <span>{dayjs(item.createdAt).format("HH mm A")}</span>
                    </label>
                  </div>
                </div>
              ))}
              {/* {mesageArray?.length == messages?.data?.totalCount && <GetEndMessage />} */}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex flex-col justify-center items-center gap-2 min-h-[150px] text-center w-full">
            <span className="text-primary text-center text-xs">
              {t("_No_notifications")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
