import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";

/**
 * This method is used to edit contact details
 * @param {*} options
 * @returns
 */
export async function editContactDetails(options) {
  //console.log("Options", options);
  let url = getAPIMap("updateContactDetails");
  //let url =
  //"https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/editPersonalInfo/{userId}";
  url = url.replace("{userid}", options.userId);
  let response = axiosPrivate.put(url, options.userDetailsPayload);
  return response;
}

/**
 * This method is used for listing Recruiter Details
 * @param {*} options
 * @returns
 */
export async function getCandidateInfo(options) {
  //console.log("Options", options);
  let url = getAPIMap("getRecruiter");
  // let url =
  //   "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/getRecruiter/{userId}";
  url = url.replace("{userid}", options.queryKey[1]);
  //console.log("url", url);
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for listing Company Details
 * @param {*} options
 * @returns
 */
export async function getCompanyInfo(options) {
  //console.log("Options", options);
  let url = getAPIMap("getCompany");
  //let url =
  //  "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/getcompanybyid/{companyId}";
  url = url.replace("{companyid}", options.queryKey[1]);
  //console.log("url", url);
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to edit company details
 * @param {*} options
 * @returns
 */
export async function editCompanyDetails(options) {
  //console.log("Options", options);
  let url = getAPIMap("updateCompanyDetails");
  // let url =
  //   "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/editCompanyInfo/{companyId}";
  url = url.replace("{companyid}", options.groupId);
  let response = axiosPrivate.put(url, options.userDetailsPayload);
  return response;
}

/**
 * This method is used for change password in recruiter profile
 */
export async function changePassword(options) {
  let url = getAPIMap("changeRecruiterPassword");
  //let url =
  // "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/pswd/_change";
  let response = axiosPrivate.post(url, options.payload);
  return response;
}

/**
 * This method is used for upload Company Logo
 * @param {*} options
 * @returns
 */
export async function UploadCompanyLogo(options) {
  const header = { "Content-Type": "multipart/form-data" };

  let url = getAPIMap("uploadLogo");
  //let url =
  //"https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/job/logo/_upload";
  let response = axiosPrivate.post(url, options.imagePayload, header);
  return response;
}

/**
 * This method is used for update Company Logo
 * @param {*} options
 * @returns
 */
export async function UpdateCompanyLogo(options) {
  const header = { "Content-Type": "multipart/form-data" };

  let url = getAPIMap("updateLogo");
  //let url =
  //"https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/job/logo/_update";
  let response = axiosPrivate.put(url, options.imagePayload, header);
  return response;
}

/**
 * This method is used for delete Company Logo
 * @param {*} options
 * @returns
 */
export async function DeleteCompanyLogo(options) {
  let url = getAPIMap("deleteLogo");
  //let url =
  // "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/job/logo/_delete";
  let response = axiosPrivate.delete(url);
  return response;
}
