import axios from "axios";
import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";
import { getAppBarUtilityClass } from "@mui/material";

export async function getTemplateList() {
  let url = getAPIMap("getDistinctTemplate");
  let response = await axiosPrivate.get(url);
  return response;
}

export async function getMenuByTemplateName(options) {
  let url = getAPIMap("getMenuByTemplateName");
  const decodedString = decodeURIComponent(options.queryKey[1]);
  url = url.replace("{templateName}", decodedString);
  let response = await axiosPrivate.get(url);
  return response;
}

export async function addRole(options) {
  let url = getAPIMap("addRole");
  let response = await axiosPrivate.post(url, options.payload);
  return response;
}

export async function getRoleById({ queryKey }) {
  const [, roleId] = queryKey;
  let url = getAPIMap("getRoleById");
  url = url.replace("{role_id}", roleId);

  const response = await axiosPrivate.get(url);
  return response;
}

export async function getAllResourceTypes() {
  let url = getAPIMap("getAllResourceTypes");
  let response = await axiosPrivate.get(url);
  return response;
}

// export async function editRole(options) {
//   let url = getAPIMap("editRole");
//   let response = await axiosPrivate.put(url, payload);
// }

export async function getAllRoles({ queryKey }) {
  // Get the base URL
  let url = getAPIMap("getAllRoles");
  console.log("Initial URL:", url);
  let queryParams = [];

  if (queryKey[1]) {
    queryParams.push(`pgindex=${queryKey[1]}`);
  }

  if (queryKey[2]) {
    queryParams.push(`pgsize=${queryKey[2]}`);
  }

  if (queryKey[3]) {
    queryParams.push(`roleName=${queryKey[3]}`);
  }

  if (queryKey[4]) {
    queryParams.push(`template=${queryKey[4]}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  console.log("Final URL:", url);

  const response = await axios.get(url);
  return response;
}

export async function getAllRoleTemplates(options) {
  let url = getAPIMap("getAllRoleTemplates");

  let response = axiosPrivate.get(url);
  console.log(response);
  return response;
}

export async function editRoleById(options) {
  let url = getAPIMap("editRoleById");
  console.log(options);
  url = url.replace("{role_id}", options.roleid);
  let response = axiosPrivate.put(url, options.payload);
  return response;
}

export async function getAllUsers({ queryKey }) {
  let url = getAPIMap("getAllUsers");
  console.log(queryKey[5]);

  url = url.replace("{role_id}", queryKey[1]);
  let queryParams = [];
  if (queryKey[2]) {
    queryParams.push(`pagesize=${queryKey[2]}`);
  }

  if (queryKey[3]) {
    queryParams.push(`page=${queryKey[3]}`);
  }

  if (queryKey[4]) {
    queryParams.push(`search=${queryKey[4]}`);
  }
  if (typeof queryKey[5] === "string" && queryKey[5].trim() !== "") {
    queryParams.push(`status=${queryKey[5]}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  console.log(url);

  let response = await axiosPrivate.get(url);
  return response;
}

export async function updateUserByID(options) {
  console.log(options.id);
  let url = getAPIMap("updateUserByID");
  url = url.replace("{userid}", options.id);
  console.log(url);
  let response = await axiosPrivate.put(url, options.payload);
  return response;
}

export async function deleteRoleById(options) {
  let url = getAPIMap("deleteRole");
  console.log(options.value);
  url = url.replace("{role_id}", options.value);
  let response = await axiosPrivate.delete(url);
  return response;
}

export async function deleteUserById(options) {
  let url = getAPIMap("deleteUser");
  console.log(options.value);
  url = url.replace("", options.value);
  console.log(url);
  let response = await axiosPrivate.delete(url);
  return response;
}

export async function activateUser(options) {
  console.log(options);
  let url = getAPIMap("activateUser");
  url = url.replace("{userid}", options);
  let response = await axiosPrivate.put(url);
  return response;
}

export async function deactivateUser(options) {
  console.log(options);
  let url = getAPIMap("deactivateUser");
  url = url.replace("{userid}", options);
  console.log(url);
  let response = await axiosPrivate.put(url);
  return response;
}
