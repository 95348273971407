import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";
import { lazy } from "react";
import { HelmetProvider } from "react-helmet-async";
import PageHead from "./components/PageHead";
import ActiveEmail from "./pages/Admin/ActiveEmail"

const UnsubscribeEmail = lazy(() => import("./pages/UnsubscribeEmail"));
const SetPassword = lazy(() => import("./pages/CreatePassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const LoginForm = lazy(() => import("./pages/Recruiter/SignIn"));
const CandidateLoginForm = lazy(() => import("./pages/Login"));
const baseName = process.env.REACT_APP_BASE_NAME;
function App() {
  const { i18n } = useTranslation();
  const val = i18n.resolvedLanguage;

  useEffect(() => {
    if (val === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [val, i18n]);

  return (
    <>
      <HelmetProvider>
        <PageHead title={"CURATAL"} description={"Heads you win"} />
        <Router basename={baseName}>
          <Routes>
          <Route path="/reactivate/:issuedTo/:alinkToken" element={<ActiveEmail />} />
          <Route path="/unsubscribe/:userid/:notification_name" element={<UnsubscribeEmail />} />
            <Route path="/auth/*" element={<ForwardRoute />} />
            {/* Place new routes over this */}
            {/*Change below to setpassword for candidate */}
            <Route path="/:userid/:usertoken/can_link" element={<CandidateLoginForm />} />
            <Route path="/:userid/:usertoken/rec_link" element={<LoginForm />} />
            <Route path="/:userid/:usertoken/_vlink" element={<SetPassword />} />
            <Route path="/:userid/:usertoken/inv_link" element={<SetPassword />} />
            <Route path="/:userid/:usertoken/:code/inv_link" element={<SetPassword />} />
            <Route
              path="/:referrerid/:userid/:usertoken/ref_link"
              element={<SetPassword />}
            />
            <Route path="/:userid/:pcaid/:usertoken/pca_link" element={<SetPassword />} />
            <Route path="/:userid/:usertoken/_rlink" element={<ResetPassword />} />
            <Route path="/app/*" element={<ProtectedRoute />} />
            {/* <Route path="/app/dashboard" element={<PersonalInformation />} /> */}
            <Route path="*" element={<Navigate to="/auth" />} />
          </Routes>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
