import { CssTextField } from "../../StyledComponent";
import { useField } from "formik";
import InputLabel from "../InputLabel";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const TextFieldWrapper = ({
  name,
  label,
  labelColor,
  labelClassName,
  layoutClassName,
  isMandatory,
  displayError = true,
  ...rest
}) => {
  const { template } = useContext(AuthContext);

  const [field, meta] = useField(name);
  const configTextField = {
    ...field,
    ...rest,
    fullWidth: true,
    autoComplete: "off",
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = displayError ? meta.error : "";
  }
  return (
    <div className={layoutClassName}>
      {/* <div className="text-formLabelColor text-medium mb-2">{t(label)}</div> */}

      {label && (
        <InputLabel
          label={label}
          color={labelColor}
          labelClassName={labelClassName}
          isMandatory={isMandatory}
        />
      )}
      <CssTextField
        {...configTextField}
        template={template}
        // isDisabledBackground={isDisabledBackground}

        inputProps={{
          autoComplete: "off",
        }}
      ></CssTextField>
    </div>
  );
};

export default TextFieldWrapper;
