import React, { useEffect, useState, useMemo } from "react";
import { CssSelect, CssAutoComplete } from "../StyledComponent";
import { useField, useFormikContext, ErrorMessage } from "formik";
import { Country, State, City } from "country-state-city";
import { CloseIcon } from "../../icons";
import { Box, MenuItem, ListSubheader, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";

function CompanyLocationDropDown({ name, ...props }) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const [searchText, setSearchText] = useState("");
  const [country, setCountry] = useState(field.value.country || "");
  const [city, setCity] = useState(field.value.city || "");
  const [cityOptions, setCityOptions] = useState([]);

  let countryOptions = Country.getAllCountries();

  useEffect(() => {
    setFieldValue(`${name}.country`, country);
    setFieldValue(`${name}.city`, city);
    if (country) {
      let cityData = City.getCitiesOfCountry(country);
      let filterData = cityData.map((cities) => {
        let stateData = State.getStateByCodeAndCountry(
          cities.stateCode,
          cities.countryCode
        );
        return `${cities.name}, ${stateData.name}`;
        // return {
        //   //value: { city: cities.name, state: stateData.name },
        //   value: `${cities.name}, ${stateData.name}`,
        //   label: `${cities.name}, ${stateData.name}`,
        // };
      });
      setCityOptions(filterData);
    }
  }, [country, name, setFieldValue, city]);

  const handleChange = (e) => {
    setCountry(e.target.value);
    setCity("");
    setFieldValue(`${name}.country`, e.target.value);
  };

  const handleCityChange = (e, value) => {
    setCity(value);
    setFieldValue(`${name}.city`, value);
  };

  const containsText = (option, searchText) => {
    if (
      option.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      option.phonecode.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    ) {
      return option;
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const displayedOptions = useMemo(
    () => countryOptions.filter((option) => containsText(option, searchText)),
    [countryOptions, searchText]
  );

  return (
    <div className="relative">
      <div
        className={`flex mb-1 border ${
          meta.error && meta.touched ? "border-[#d32f2f]" : "border-formInputBorder"
        } rounded-${
          props.borderRadius ? props.borderRadius : "5px"
        } hover:border-black hover:border-2`}
      >
        <div className="w-1/5 border-r border-formInputBorder">
          <CssSelect
            MenuProps={{ autoFocus: false }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            type="text"
            value={field?.value?.country}
            onChange={handleChange}
            className="w-full bg-white"
            name={`${name}.country`}
            style={{
              borderRadius: "0px",
              borderTopLeftRadius: props.borderRadius ? props.borderRadius : "5px",
              borderBottomLeftRadius: props.borderRadius ? props.borderRadius : "5px",
              borderRight: "0px",
              height: "40px",
              fontSize: "12px",
            }}
            sx={{
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "0px !important",
                },
                "&:hover fieldset": {
                  border: "0px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "0px",
                },
              },
            }}
            displayEmpty
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {value ? (
                    <img
                      className="w-8 h-6"
                      src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
                      alt="flag"
                    ></img>
                  ) : null}
                </Box>
              );
            }}
            // placeholder={props.placeholder}
          >
            <ListSubheader>
              <TextField
                size="small"
                autoFocus
                placeholder="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {displayedOptions.map((data, index) => {
              return (
                <MenuItem key={index} value={data.isoCode} className="text-xs">
                  <div className="flex">
                    <img
                      className="w-8 h-6"
                      src={`https://flagcdn.com/w20/${data.isoCode.toLowerCase()}.png`}
                      alt="flag"
                    ></img>
                    <p className="text-xs font-xs text-labelColorSecondary mx-2 my-1">
                      {data.name}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
          </CssSelect>
        </div>
        <div className="w-4/5">
          <CssAutoComplete
            name={`${name}.city`}
            id="tags-outlined"
            options={cityOptions}
            style={{ fontSize: "12px" }}
            // placeholder={`City & State`}
            //getOptionLabel={(option) => (option && option.label ? option.label : "")}
            filterOptions={filterOptions}
            value={field?.value?.city}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                sx={{
                  "& label.Mui-focused": {
                    color: "#2D2D2D",
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "12px",
                    fontWeight: "500",
                    //minHeight: "40px",
                    paddingY: "4px",
                    // paddingBottom: "7px",
                    "& fieldset": {
                      border: "1px solid #70707059",
                      borderRadius: "10px",
                    },
                    "&:hover fieldset": {
                      border: "2px solid #2D2D2D",
                    },
                    "&.Mui-focused fieldset": {
                      border: "2px solid #2D2D2D",
                    },
                  },
                }}
                {...params}
                placeholder={props.placeholder}
                // className="text-primary text-medium h-full bg-white rounded-r-lg"
              />
            )}
            onChange={handleCityChange}
            //isOptionEqualToValue={(option, value) => option === ""}
            ChipProps={{
              deleteIcon: <CloseIcon className="text-white h-[9px] w-[9px]" />,
              className: "bg-bgPrimary text-white px-1 ml-2",
            }}
            PaperComponent={({ children }) => (
              <Paper style={{ fontSize: "12px" }}>{children}</Paper>
            )}
            // style={{ height: "40px" }}
            sx={{
              "&.MuiAutocomplete-root": {
                "& fieldset": {
                  border: "0px !important",
                  minHeight: "40px",
                },
                "&:hover fieldset": {
                  border: "0px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "2px solid #2D2D2D",
                },
                "& .MuiAutocomplete-input": {
                  // padding: "3.5px 4px 3.5px 5px",
                },
              },
            }}
          />
        </div>
      </div>
      {/* {console.log("field.name", field.name)} */}
      <ErrorMessage
        name={field.name}
        render={(msg) => {
          // console.log("Error message:", msg);

          return <div className="text-[#d32f2f]  text-[11px]">{msg.city}</div>;
        }}
      />
    </div>
  );
}

export default CompanyLocationDropDown;
