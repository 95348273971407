import React from "react";
import { BookmarkIcon, DeleteIcon, PenIcon } from "../../icons";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
const CertificateInfo = ({ data, item, index, setFieldValue, name, type }) => {
  const navigate = useNavigate();

  return (
    <div className="mt-5 grid grid-cols-8">
      <div className="col-span-1 ">
        <BookmarkIcon className="text-resumeTextColor w-5 h-7 text-left" />
      </div>
      <div className="col-span-7">
        <div className="flex gap-10">
          <div className="text-resumeColor text-medium mb-3">
            {item.date ? dayjs(item.date).format("YYYY") : "No date"}
          </div>
          <div className="flex gap-5">
            <PenIcon
              onClick={() => {
                navigate(`?type=${type}&op=edit`, {
                  state: { ...item, index: index },
                });
              }}
              className="h-4 w-4 text-white"
            />
            <DeleteIcon
              onClick={() => {
                data.splice(index, 1);
                setFieldValue(name, data);
              }}
              className="h-4 w-4 text-white"
            />
          </div>
        </div>
        <div className="text-resumeColor text-2xl mb-3">{item.title}</div>
        <div className="text-resumeColor text-medium ">{item.university}</div>
      </div>
    </div>
  );
};

export default CertificateInfo;
