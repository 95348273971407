import Button from "../Button/Button";
import { Trans, useTranslation } from "react-i18next";
import { Error, ErrorIcon } from "../../icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
const ErrorDialog = (props) => {
  const { onClick, open, message, header, title, i18nKey, ...other } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: 417, textAlign: "center" } }}
      maxWidth="xs"
      open={open}
      {...other}
      className="p-6 rounded-md "
    >
      <DialogTitle sx={{ textAlign: "center", marginTop: "24px" }}>
        <ErrorIcon className="h-[50px] w-[50px] text-error-icon mx-auto " />
        {header ? (
          <div className="mt-4 text-sm">{t(header)}</div>
        ) : !title ? (
          <div className="mt-4 text-sm">{t("_Failed")}</div>
        ) : (
          <div className="mt-4 text-sm">{t("_Error")}</div>
        )}
      </DialogTitle>
      <div className="text-xs text-title font-medium mx-1 whitespace-pre-line">
        {message !== "" ? t(message) : <Trans i18nKey={i18nKey} />}
      </div>
      <div className="text-center mt-7">
        {!title ? (
          <Button
            onClick={onClick}
            label="_TRY_AGAIN"
            buttonClassName="w-[145px] h-10 text-xs mb-9"
          ></Button>
        ) : (
          <Button
            onClick={onClick}
            label="_OKAY"
            buttonClassName="w-[145px] h-10 text-xs mb-9"
          ></Button>
        )}
      </div>
    </Dialog>
  );
};
export default ErrorDialog;
