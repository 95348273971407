import { LeftIcon, RightIcon } from "../../icons";
import { useEffect, useState } from "react";
import { itemsPerPageOptions } from "../../common/constants";
import SelectWrapper from "../FormsUI/Select";
import { Form, Formik } from "formik";
import InputLabel from "../FormsUI/InputLabel";
import PaginationComponent from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import TextFieldWrapper from "../FormsUI/TextField";
import { CssTextField } from "../StyledComponent";
const Pagination = ({
  totalPosts,
  paginate,
  page,
  pagesize,
  setPagesize,
  goTopageInputBox = false,
  options,
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [initialPageNum, setInitialPageNum] = useState(0);
  const [finalPageNum, setFinalPageNum] = useState(0);

  //console.log(initialPageNum);
  //console.log(currentPage);
  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    } else {
      setCurrentPage(1);
    }
  }, [page]);
  useEffect(() => {
    if (totalPosts > 0) {
      setInitialPageNum(currentPage * pagesize - pagesize + 1);
    } else {
      setInitialPageNum(0);
    }

    if (totalPosts >= currentPage * pagesize) {
      setFinalPageNum(currentPage * pagesize);
    } else if (totalPosts <= pagesize || totalPosts < currentPage * pagesize) {
      setFinalPageNum(totalPosts);
    } else {
      setInitialPageNum(1);
    }
  }, [currentPage, pagesize, totalPosts]);

  return (
    <>
      {totalPosts > 0 ? (
        <div className="flex flex-row flex-wrap justify-between items-center gap-2">
          {/* <button
            className="w-4 h-4 rounded-full bg-bgSelectedView"
            disabled={currentPage === 1}
            onClick={() => {
              if (currentPage > 1) {
                let newPage = currentPage - 1;
                setCurrentPage(newPage);
                paginate(newPage);
              }
            }}
          >
            <LeftIcon className="mx-auto h-2 w-2"></LeftIcon>
          </button> */}
          <span className="text-xs ">
            Showing : {initialPageNum + " - " + finalPageNum + " of " + totalPosts}
          </span>
          <div className="flex items-center flex-wrap gap-2">
            <PaginationComponent
              count={Math.ceil(totalPosts / pagesize)}
              page={page}
              className="-mt-1"
              siblingCount={1}
              boundaryCount={1}
              color="primary"
              onChange={(e, value) => {
                setCurrentPage(value);
                paginate(value);
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: () => {
                      return <LeftIcon className="h-3 w-3"></LeftIcon>;
                    },
                    next: () => {
                      return <RightIcon className="h-3 w-3"></RightIcon>;
                    },
                  }}
                  {...item}
                />
              )}
            />
            <Formik
              initialValues={{ itemsPerPage: pagesize, customPageNumber: currentPage }}
              enableReinitialize={true}

              // validationSchema={validate}
            >
              {({ resetForm, isValid, errors, values, setFieldValue }) => (
                <Form className="flex gap-3 flex-wrap items-center ">
                  <>
                    <InputLabel label={"_Go_to"} />
                    {goTopageInputBox ? (
                      <TextFieldWrapper
                        name={"pageNumber"}
                        className="w-[70px]   h-[40px]"
                        type="number"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (
                              e.target.value <= Math.ceil(totalPosts / pagesize) &&
                              e.target.value > 0
                            ) {
                              setCurrentPage(parseInt(e.target.value));
                              paginate(parseInt(e.target.value));
                            }
                            setFieldValue("pageNumber", "");
                          }
                        }}
                        placeholder={"#"}
                      />
                    ) : (
                      <SelectWrapper
                        name="customPageNumber"
                        onChange={(e) => {
                          setCurrentPage(parseInt(e.target.value));
                          paginate(parseInt(e.target.value));
                        }}
                        options={Array.from(
                          { length: Math.ceil(totalPosts / pagesize) },
                          (_, i) => i + 1
                        ).map((pageNumber) => {
                          return {
                            label: pageNumber.toString(),
                            value: pageNumber.toString(),
                          };
                        })}
                        className="w-[70px]   h-[40px]"
                      />
                    )}
                    <InputLabel label={"_Page"} />
                  </>
                </Form>
              )}
            </Formik>
          </div>
          {/* // <button
          //   className="w-4 h-4 rounded-full bg-bgSelectedView"
          //   onClick={() => {
          //     if (currentPage * dataPerPage < totalPosts) {
          //       let newPage = currentPage + 1;
          //       setCurrentPage(newPage);
          //       paginate(newPage);
          //     }
          //   }}
          // >
          //   <RightIcon className="mx-auto h-2 w-2"></RightIcon>
          // </button> */}
          <Formik
            initialValues={{ itemsPerPage: pagesize, customPageNumber: currentPage }}
            enableReinitialize={true}

            // validationSchema={validate}
          >
            {({ resetForm, isValid, errors, values, setFieldValue }) => (
              <Form className="flex gap-3 flex-wrap items-center ">
                <>
                  <InputLabel label={"_Items_Per_Page"} />

                  <SelectWrapper
                    name="itemsPerPage"
                    onChange={(e) => {
                      setPagesize(e.target.value);
                      // paginate(1);

                      const totalPages = Math.ceil(totalPosts / e.target.value);

                      if (currentPage > totalPages && totalPages !== 0) {
                        paginate(totalPages);
                        setCurrentPage(totalPages);
                      } else {
                        paginate(currentPage);
                        setCurrentPage(currentPage);
                      }
                    }}
                    options={options ? options : itemsPerPageOptions}
                    className="w-[70px] h-[40px]"
                  />
                </>
              </Form>
            )}
          </Formik>
        </div>
      ) : null}
    </>
  );
};
export default Pagination;
