import getAPIMap from "../routes/ApiUrls";
import { axiosPrivate } from "../common/axiosPrivate";

//const IP = process.env.REACT_APP_IP;

/**
 * This method is used for upload Profile Photo
 * @param {*} options
 * @returns
 */
export async function UploadProfilePhoto(options) {
  const header = { "Content-Type": "multipart/form-data" };

  let url = getAPIMap("uploadProfilePhoto");
  url = url.replace("{id}", options.userId);
  let response = axiosPrivate.post(url, options.imagePayload, header);
  return response;
}

/**
 * This method is used for update Profile Photo
 * @param {*} options
 * @returns
 */
export async function UpdateProfilePhoto(options) {
  const header = { "Content-Type": "multipart/form-data" };

  let url = getAPIMap("updateProfilePhoto");
  url = url.replace("{id}", options.userId);
  url = url.replace("{fileid}", options.fileId);
  let response = axiosPrivate.put(url, options.imagePayload, header);
  return response;
}

/**
 * This method is used for delete Profile Photo
 * @param {*} options
 * @returns
 */
export async function DeleteProfilePhoto(options) {
  let url = getAPIMap("deleteProfilePhoto");
  url = url.replace("{id}", options.userId);
  url = url.replace("{fileid}", options.fileId);
  let response = axiosPrivate.delete(url);
  return response;
}

/**
 * This method is used for listing Profile Details
 * @param {*} options
 * @returns
 */
export async function getProfileDetails(options) {
  let url = getAPIMap("listProfileDetails");
  url = url.replace("{id}", options.queryKey[1]);
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for updating Profile Details
 * @param {*} options
 * @returns
 */
export async function updateProfileDetails(options) {
  let url = getAPIMap("updateProfileDetails");
  url = url.replace("{id}", options.userId);
  let response = axiosPrivate.patch(url, options.payload);
  return response;
}

/**
 * This method is used for change password in accountsettings
 */
export async function changePassword(options) {
  let url = getAPIMap("changePassword");
  let response = axiosPrivate.post(url, options.payload);
  return response;
}

/**
 * This method is used for upload recorded video
 * @param {*} options
 * @returns
 */
export async function UploadRecordedVideo(options) {
  const header = { "Content-Type": "multipart/form-data" };
  let url = getAPIMap("uploadVideo");
  url = url.replace("{id}", options.userId);
  let response = axiosPrivate.post(url, options.videoPayload, header);
  return response;
}

/**
 * This method is used for update recorded Video
 * @param {*} options
 * @returns
 */
export async function UpdateRecordedVideo(options) {
  const header = { "Content-Type": "multipart/form-data" };

  let url = getAPIMap("updateVideo");
  url = url.replace("{id}", options.userId);
  url = url.replace("{fileid}", options.fileId);
  let response = axiosPrivate.put(url, options.videoPayload, header);
  return response;
}

/**
 * This method is used for delete Video
 * @param {*} options
 * @returns
 */
export async function DeleteRecordedVideo(options) {
  let url = getAPIMap("deleteVideo");
  url = url.replace("{id}", options.userId);
  url = url.replace("{fileid}", options.fileId);
  let response = axiosPrivate.delete(url);
  return response;
}

/**
 * This method is used for update user
 * @param {*} options
 * @returns
 */
export async function UpdateUser(options) {
  let url = getAPIMap("updateUser");
  url = url.replace("{userId}", options.userId);
  let response = axiosPrivate.put(url, options.userDetailsPayload);
  return response;
}

/**
 * This method is used to upload profile details
 * @param {} options
 * @returns
 */
export async function uploadProfileDetails(options) {
  //console.log(options);
  let url = getAPIMap("uploadProfileDetails");
  url = url.replace("{id}", options.userId);
  //let url =
  // "https://curatal-dev.openturf.dev/curatal_account/api/v1/accounts/74494546-2d2d-4704-8a2e-b7cb7b657e6a/profile";
  return axiosPrivate.post(url, options.reqData);
}

/**
 * This method is used to get currency codes
 */
export async function getCurrencyCodes() {
  let url = getAPIMap("getCurrencyCodes");
  return axiosPrivate.get(url);
}

/**
 * This method is used to download candidate resume
 */

export async function getCandidateResume({ queryKey }) {
  let url = getAPIMap("getCandidateResumeWithPII");
  url = url.replace("{candidateid}", queryKey[1]);
  //url = url.replace("{flag}", queryKey[2]);
  if (queryKey[3]) {
    url = url.concat("?quickResume=", 1);
  }
  if (queryKey[2] > 2 && !queryKey[3]) {
    let response = axiosPrivate.get(url);
    return response;
  } else {
    let response = axiosPrivate.get(url, {
      responseType: "blob",
    });
    return response;
  }
  // let response = axiosPrivate.get(url);
  // return response;
}

export async function getAiJobSummary(options) {
  let url = getAPIMap("getAiJobSummary");

  let response = axiosPrivate.post(url, options.payload);
  return response;
}
export async function getAiJobDescription(options) {
  let url = getAPIMap("getAiJobDescriptionSummary");

  let response = axiosPrivate.post(url, options.payload);
  return response;
}

/**
 * This method is used to get the candidate metrics
 */
export async function getCandidateMetrics({ queryKey }) {
  let url = getAPIMap("getCandidateMetrics");
  url = url.replace("{userid}", queryKey[1]);
  let response = axiosPrivate.get(url);
  return response;
}
/**
 * This method is used to get the recruiter metrics
 */
export async function getRecruiterMetrics({ queryKey }) {
  let url = getAPIMap("getRecruiterMetrics");
  url = url.replace("{userid}", queryKey[1]);
  let response = axiosPrivate.get(url);
  return response;
}

export async function getSalaryMeterOptionsData() {
  let url = getAPIMap("salaryMeterApi");
  return axiosPrivate.get(url);
}

export const getSalaryMeterCalculation = async (options) => {
  let reqData = options.finalValues;
  var url = getAPIMap("salaryMeterCalculation");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let response = await axiosPrivate.post(url, reqData, config);
  return response;
};