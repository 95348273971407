import React, { useContext } from "react";
import { Navigate } from "react-router";
import Layout from "../../containers/Layout";
import { AuthContext } from "../../context/AuthContext";
import { templates } from "../../common/constants";

const ProtectedRoute = () => {
  const { user, session, template } = useContext(AuthContext);

  //return user && session ? <Layout /> : <Navigate to="/auth/recruiter/login" />;
  return user && session ? (
    <Layout />
  ) : template === templates.candidate ? (
    <Navigate to="/auth/login" />
  ) : template === templates.pcaAdmin || template === templates.pcaAgent ? (
    <Navigate to="/auth/pca/login" />
  ) : (
    <Navigate to="/auth/recruiter/login" />
  );
  //commenting down for recruiter demo purpose
  // return user && session ? <Layout /> : <Navigate to="/auth/login" />;
};

export default ProtectedRoute;
