import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CssTextField } from "../../StyledComponent";
import { useFormikContext, useField } from "formik";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const YearPicker = ({ name, label, inputClassName, ...rest }) => {
  const [field, meta] = useField(name);
  let val = field.value;
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(val);
  useEffect(() => {
    setStartDate(val);
  }, [val]);
  const { setFieldValue, errors, touched } = useFormikContext();

  const handleDateChange = (date) => {
    setStartDate(date);
    setFieldValue(name, date);
  };

  const isError = touched[name] && errors[name];

  return (
    <div>
      <div className="text-grayTextColor text-xs font-medium mb-1">{t(label)}</div>
      <DatePicker
        wrapperClassName={"w-full"}
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="yyyy"
        showYearPicker
        customInput={
          <CssTextField
            variant="outlined"
            fullWidth
            className={` ${inputClassName}`}
            //InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            style={{ border: "none", borderColor: isError ? "#d32f2f" : "inherit" }}
          />
        }
      />
      {meta.error && (
        <div style={{ color: "#d32f2f", fontSize: "11px" }}>{meta.error}</div>
      )}
    </div>
  );
};

export default YearPicker;
