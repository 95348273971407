import * as React from "react";
import Drawer from "@mui/material/Drawer";
import * as Icons from "../../icons";
import { SidebarContext } from "../../context/SidebarContext";
import SidebarSubmenu from "./SidebarSubmenu";
// import routes from "../../routes/sidebar";
import { NavLink } from "react-router-dom";
import menu from "../../routes/sidebar";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";
import { useState } from "react";
import { useQuery } from "react-query";
import { sidebarMenu } from "../../lib/onboarding-api";
import SidemenuSkltn from "../skeloton/SidemenuSkltn";
import newIcon from "../../assets/img/new.png";

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  return <Icon {...props} />;
}
export default function TemporaryDrawer() {
  const { toggleSidebar, isSidebarOpen } = React.useContext(SidebarContext);
  const { t } = useTranslation();
  const [routes, setRoutes] = useState(menu);

  const { isLoading: isMenuLoading } = useQuery(["sidebarMenu"], sidebarMenu, {
    onSuccess: (resp) => {
      setRoutes(resp?.data?.data);
    },
  });
  return (
    <Box role="presentation" onClick={toggleSidebar} onKeyDown={toggleSidebar}>
      <Drawer anchor={"left"} open={isSidebarOpen} onClose={toggleSidebar}>
        {!isMenuLoading ? (
          <div className="grow overflow-x-hidden bg-bgPrimary flex flex-col  h-full w-[250px]">
            <ul className="mt-10">
              {routes?.map((route, i) =>
                route.routes ? (
                  <SidebarSubmenu route={route} key={route.name} />
                ) : true ? (
                  <li className={`relative mb-3 `} key={route.name}>
                    <NavLink
                      to={route.path}
                      className="inline-flex items-center w-full text-sm text-white font-semibold transition-colors duration-150 px-6 p-3"
                      style={({ isActive }) =>
                        isActive
                          ? { backgroundColor: "#E77817" }
                          : { backgroundColor: "transparent" }
                      }
                    >
                      <div className={`flex items-start gap-3`}>
                        <div className="">
                          <Icon
                            className="w-5 h-4"
                            aria-hidden="true"
                            icon={route.icon}
                          />
                        </div>
                        <div
                          className={`text-xs ${
                            t(route.name) === "Refer Your Friends" ? "highlight-text" : ""
                          }`}
                        >
                          {t(route.name)}
                        </div>
                        {t(route.name) === "Refer Your Friends" && isSidebarOpen ? (
                          <img
                            src={newIcon}
                            alt="new"
                            className="h-9 w-9 absolute right-11 top-0.5"
                          />
                        ) : null}
                      </div>
                    </NavLink>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        ) : (
          <SidemenuSkltn state={null} />
        )}
      </Drawer>
    </Box>
  );
}
