import dayjs from "dayjs";
import _ from "lodash";
import { t } from "i18next";
import { candidateSkillLevel } from "./constants";
import { Country } from "country-state-city";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

/**
 * This method is used to calculate the no of diff from current date to the posted date
 */
export const calculatePostedDays = (dateVal) => {
  const postedDate = dayjs(dateVal);
  const today = dayjs();
  const daysSincePosted = today.diff(postedDate, "day");
  return daysSincePosted;
};

/**
 * This method is used to calculate the no of diff from current date to the posted date
 */
export const calculateValidDays = (dateVal) => {
  const postedDate = dayjs(dateVal);
  const today = dayjs();
  const daysToGo = postedDate.diff(today, "day");
  return daysToGo;
};

/**
 * This method is used to calculate the no of years of exeprience
 * @param {*} start_date
 * @param {*} end_date
 * @returns
 */
export function calculateYearsOfExp(start_date, end_date) {
  let date1 = "";
  if (end_date !== "now") {
    date1 = dayjs(end_date, "DD-YYYY");
  } else {
    date1 = dayjs();
  }

  const date2 = dayjs(start_date, "DD-YYYY");

  let yearDifference = date1.diff(date2, "y", true);

  return yearDifference;
}

/**
 * This method is used to get the skill options in rateyourskill form
 * @param {T} allSelectedOptions
 * @param {*} allOptions
 * @param {*} index
 * @returns
 */
export const getOptionsToRender = (allSelectedOptions, allOptions, index) => {
  // convert [[{}, {}], [{}]] -> [{}, {}, {}]
  const filteredOptions = allSelectedOptions?.flatMap((options) => options);
  // Filter the selected values from main array.
  const optionsToRender =
    filteredOptions?.length > 0
      ? allOptions?.filter(
          (option) =>
            !filteredOptions?.some(
              (selectOption) =>
                option && selectOption && option.value === selectOption.skillName
            )
        )
      : allOptions;
  // Adds the selected option of that select dropdown as well in that index
  if (allSelectedOptions?.[index]?.skillName !== "") {
    optionsToRender?.unshift({
      label: allSelectedOptions?.[index]?.skillName,
      value: allSelectedOptions?.[index]?.skillName,
    });
  }
  return optionsToRender;
};
/**
 * This method is used to return the skill options based on the previous selection data
 * @param {*} values
 * @param {*} i
 * @param {*} skillOptions
 * @returns
 */
export const sSkillOptions = (values, i, skillOptions) => {
  let options = skillOptions?.filter((x) => {
    return x.value !== values?.primarySkills;
  });
  let sSkillArray = values?.secondarySkills?.map((a) => a.sskill);
  sSkillArray?.forEach((b) => {
    options = options.filter((c) => {
      return c.value !== b;
    });
  });
  if (values?.secondarySkills[i]?.sskill !== "") {
    options.unshift({
      label: values?.secondarySkills[i]?.sskill,
      value: values?.secondarySkills[i]?.sskill,
    });
  }
  return options;
};

/**
 * This method is used to get the secondary skill weightage options based on primary skill weightage selection
 * @param {} values
 * @returns
 */
export const sSkillLevelOptions = (values) => {
  if (values.weightage === 3) {
    return [
      { label: t("_Novice"), value: 1 },
      { label: t("_Competent"), value: 2 },
      { label: t("_Proficient"), value: 3 },
    ];
  } else if (values.weightage === 4) {
    return [
      { label: t("_Novice"), value: 1 },
      { label: t("_Competent"), value: 2 },
      { label: t("_Proficient"), value: 3 },
      { label: t("_Expert"), value: 4 },
    ];
  } else {
    return [
      { label: t("_Novice"), value: 1 },
      { label: t("_Competent"), value: 2 },
      { label: t("_Proficient"), value: 3 },
      { label: t("_Expert"), value: 4 },
      { label: t("_Master"), value: 5 },
    ];
  }
  //return sskillLevelOptions;
};
/**
 * This method is used to get the skill level name
 */

export const getSkillLevelName = (skillLevel) => {
  return candidateSkillLevel[skillLevel];
};

export const displayCurrentDateTime = () => {
  const now = new Date();

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const formattedDateTime = now.toLocaleString("en-US", options);
  return formattedDateTime;
};
/** This method is used to get the skill names for recommendation engine */
export const getMoreSkillData = (allSkills) => {
  let skillName = allSkills?.map((x) => {
    return x;
  });

  return skillName ? skillName.toString() : "";
};

export const getSkillsData = (allSkills) => {
  let skillName = allSkills?.filter((skill) => skill !== null).join(", ");
  return skillName || "";
}

/**
 * This method is used to get the country code
 */
export const getCountryCode = (countryName) => {
  let countries = Country.getAllCountries();
  let data = countries.filter((x) => {
    return x.name === countryName;
  });
  if (data?.length > 0) {
    return data?.[0]?.isoCode;
  } else return "IN";
};

/** This method is used to capitalise the first letter of each word */
export const capitaliseLetter = (wrd) => {
  let warray = wrd.split(" ");
  let narray = warray.map((x, i) => {
    const firstLetter = x.charAt(0);

    const firstLetterCap = firstLetter.toUpperCase();

    const remainingLetters = x.slice(1);

    return firstLetterCap + remainingLetters;
  });
  return narray.join(" ");
};

export function generateShades(startColor, endColor, steps, brightnessFactor = 1.2) {
  function lerpColor(a, b, amount) {
    let ah = parseInt(a.replace(/#/g, ""), 16),
      ar = ah >> 16,
      ag = (ah >> 8) & 0xff,
      ab = ah & 0xff,
      bh = parseInt(b.replace(/#/g, ""), 16),
      br = bh >> 16,
      bg = (bh >> 8) & 0xff,
      bb = bh & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

    // Increase brightness
    rr = Math.min(255, rr * brightnessFactor);
    rg = Math.min(255, rg * brightnessFactor);
    rb = Math.min(255, rb * brightnessFactor);

    return (
      "#" +
      (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1).toUpperCase()
    );
  }

  const shades = [];
  for (let i = 0; i <= steps; i++) {
    shades.push(lerpColor(startColor, endColor, i / steps));
  }
  return shades;
}
