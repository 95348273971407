import { axiosPrivate } from "../../common/axiosPrivate";
import getAPIMap from "../../routes/ApiUrls";

/**
 * This method is used for Updating Work Status
 * @param {*} options
 * @returns
 */
export async function updatePcaWorkStatus(options) {
  let url = getAPIMap("updateWorkStatus");
  url = url.replace("{candidateId}", options?.candidateId);

  let response = axiosPrivate.put(url, options.payload);
  return response;
}
/**
 * This method is used for inviting candidate
 * @param {*} options
 * @returns
 */
export async function pcaCandidateInvite(options) {
  let url = getAPIMap("pcaCandidateInvite");
  url = url.replace("{candidateId}", options?.candidateId);

  let response = axiosPrivate.post(url);
  return response;
}

/**
 * This method is used for update candidate personal info
 * @param {*} options
 * @returns
 */
export async function pcaCandidatePersonalInfoUpdate(options) {
  let url = getAPIMap("pcaCandidatePersonalInfoUpdate");
  url = url.replace("{userId}", options.userId);
  let response = axiosPrivate.put(url, options.userDetailsPayload);
  return response;
}

/**
 * This method is used for updating Profile Details
 * @param {*} options
 * @returns
 */
export async function pcaCandidateProfileUpdate(options) {
  let url = getAPIMap("pcaCandidateProfileUpdate");
  url = url.replace("{id}", options.userId);
  let response = axiosPrivate.patch(url, options.payload);
  return response;
}

/**
 * This method is used for updating candidate resume by pca admin
 * @param {*} options
 * @returns
 */
export async function pcaUpdateCandidateResume(options) {
  const id = options.reqData.id;
  let formData = new FormData();
  formData.append("resume", options.reqData.resume);

  let url = getAPIMap("pcaUpdateCandidateResume").replace("{id}", id);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  let response = axiosPrivate.put(url, formData, config);
  return response;
}

export async function deactiveCandidateEmailId (options){
  let url = getAPIMap("deactiveCandidateEmail");
  url = url.replace("{id}", options.userId);
  let response = axiosPrivate.post(url, options);
  return response;
}

export async function reinviteEmailId (options){
  let url = getAPIMap("reinviteEmail");
  url = url.replace("{alinkToken}", options.alinkToken);
  url = url.replace("{issuedTo}", options.userId);
  let response = axiosPrivate.post(url, options);
  return response;
}
