import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";
const IP = process.env.REACT_APP_IP;

/**
 * This method is used for listing Jobs
 * @param {*} options
 * @returns
 */
export async function getJobsList(options) {
  let url = getAPIMap("getJobList");
  url = url.replace("{userId}", options.queryKey[1]);
  let payload;

  try {
    payload = {
      filter: {
        cat: options.queryKey[2],
        page: options?.queryKey[11],
        pagesize: options?.queryKey[12],
        industry: options?.queryKey[3]?.selectedIndustryOptions,
        company:
          options?.queryKey[7]?.selectedCompanyOptions?.length > 0
            ? options.queryKey[7].selectedCompanyOptions
            : undefined,

        country:
          options?.queryKey[8]?.selectedLocationOptions?.length > 0
            ? [options.queryKey[8].selectedLocationOptions?.[0]?.country]
            : undefined,
        city:
          options?.queryKey[8]?.selectedLocationOptions?.length > 0 &&
          options?.queryKey[8]?.selectedLocationOptions?.[0]?.states?.length > 0
            ? options.queryKey[8].selectedLocationOptions?.[0]?.states
            : undefined,
        work_env:
          options?.queryKey[10]?.selectedWorkModeOptions?.length > 0
            ? options.queryKey[10].selectedWorkModeOptions
            : undefined,
        emp_type:
          options?.queryKey[5]?.selectedEmploymentOptions?.length > 0
            ? options.queryKey[5].selectedEmploymentOptions
            : undefined,
        experience:
          options?.queryKey[4]?.selectedExperienceOptions?.length > 0
            ? options.queryKey[4].selectedExperienceOptions
            : undefined,
        skills: options?.queryKey[3]?.selectedSkillOptions?.map((skill) => {
          return {
            skill_name: skill,
            skill_level: "1",
          };
        }),
        posted_since:
          options?.queryKey[6]?.selectedRecentlyPostedOptions?.length > 0
            ? options.queryKey[6]?.selectedRecentlyPostedOptions
            : undefined,
        recommended_roles:
          options?.queryKey[13]?.length > 0 ? options.queryKey[13] : undefined,
        //use_mlcache: 0,
        // use_mlcache:
        //   options?.queryKey[0] === "recommendedjobsList"
        //     ? options?.queryKey[14]
        //     : options?.queryKey[13],
      },
    };
  } catch (error) {
    console.log(error);
  }

  let response = axiosPrivate.post(url, payload);
  return response;
}

/**
 * This method is used for get job detail
 * @param {*} options
 * @returns
 */
export async function getJobDetail(options) {
  let url = getAPIMap("getJobDetail");
  url = url.replace("{userid}", options.queryKey[2]);
  url = url.replace("{jobid}", options.queryKey[1]);

  let response = axiosPrivate.get(url);

  return response;
}

/**
 * This method is used for apply Job
 * @param {*} options
 * @returns
 */
export async function applyJob(options) {
  let url = getAPIMap("applyJob");
  url = url.replace("{userid}", options.userId);
  url = url.replace("{jobid}", options.job_id);

  let response = axiosPrivate.put(url, { score: options?.score });

  return response;
}

/**
 * This method is used for save Job
 * @param {*} options
 * @returns
 */
export async function saveJob(options) {
  let url = getAPIMap("saveJob");
  url = url.replace("{userid}", options.userId);
  url = url.replace("{jobid}", options.job_id);
  url += `?mark=${options.mark}`;
  let response = axiosPrivate.put(url, { score: options.score });

  return response;
}

/**
 * This method is used for open Jobs search
 * @param {*} options
 * @returns
 */
export async function getOpenJobsSearch(options) {
  let url = getAPIMap("getOpenJobsSearch");
  url = url.replace("{userId}", options.queryKey[1]);
  let payload;

  try {
    payload = {
      filter: {
        page: options?.queryKey[11],
        pagesize: options?.queryKey[12],
        industry: options?.queryKey[3]?.selectedIndustryOptions,
        company:
          options?.queryKey[7]?.selectedCompanyOptions?.length > 0
            ? options.queryKey[7].selectedCompanyOptions
            : undefined,

        country:
          options?.queryKey[8]?.selectedLocationOptions?.length > 0
            ? options.queryKey[8].selectedLocationOptions
            : undefined,
        emp_type:
          options?.queryKey[10]?.selectedWorkModeOptions?.length > 0
            ? options.queryKey[10].selectedWorkModeOptions
            : undefined,
        work_env:
          options?.queryKey[5]?.selectedEmploymentOptions?.length > 0
            ? options.queryKey[5].selectedEmploymentOptions
            : undefined,
        experience:
          options?.queryKey[4]?.selectedExperienceOptions?.length > 0
            ? options.queryKey[4].selectedExperienceOptions
            : undefined,
        skills: options?.queryKey[3]?.selectedSkillOptions?.map((skill) => {
          return {
            skill_name: skill,
            skill_level: "1",
          };
        }),
        posted_since:
          options?.queryKey[6]?.selectedRecentlyPostedOptions?.length > 0
            ? options.queryKey[6]?.selectedRecentlyPostedOptions
            : undefined,
        recommended_roles:
          options?.queryKey[13]?.length > 0 ? options.queryKey[13] : undefined,
        // use_mlcache:
        //   options?.queryKey[0] === "recommendedjobsList"
        //     ? options?.queryKey[14]
        //     : options?.queryKey[13],
      },
    };
  } catch (error) {
    console.log(error);
  }

  let response = axiosPrivate.post(url, payload);
  return response;
}

/**
 * This method is used for open Jobs search
 * @param {*} options
 * @returns
 */
export async function getAdminJobsSearch(options) {
  let url = getAPIMap("getAdminJobsSearch");
  url = url.replace("{userId}", options.queryKey[1]);
  let payload;

  try {
    payload = {
      filter: {
        page: options?.queryKey[11],
        pagesize: options?.queryKey[12],
        industry: options?.queryKey[3]?.selectedIndustryOptions,
        company:
          options?.queryKey[7]?.selectedCompanyOptions?.length > 0
            ? options.queryKey[7].selectedCompanyOptions
            : undefined,

        country:
          options?.queryKey[8]?.selectedLocationOptions?.length > 0
            ? options.queryKey[8].selectedLocationOptions
            : undefined,
        emp_type:
          options?.queryKey[10]?.selectedWorkModeOptions?.length > 0
            ? options.queryKey[10].selectedWorkModeOptions
            : undefined,
        work_env:
          options?.queryKey[5]?.selectedEmploymentOptions?.length > 0
            ? options.queryKey[5].selectedEmploymentOptions
            : undefined,
        experience:
          options?.queryKey[4]?.selectedExperienceOptions?.length > 0
            ? options.queryKey[4].selectedExperienceOptions
            : undefined,
        skills: options?.queryKey[3]?.selectedSkillOptions?.map((skill) => {
          return {
            skill_name: skill,
            skill_level: "1",
          };
        }),
        posted_since:
          options?.queryKey[6]?.selectedRecentlyPostedOptions?.length > 0
            ? options.queryKey[6]?.selectedRecentlyPostedOptions
            : undefined,
        recommended_roles:
          options?.queryKey[13]?.length > 0 ? options.queryKey[13] : undefined,
        // use_mlcache:
        //   options?.queryKey[0] === "recommendedjobsList"
        //     ? options?.queryKey[14]
        //     : options?.queryKey[13],
      },
    };
  } catch (error) {
    console.log(error);
  }

  let response = axiosPrivate.post(url, payload);
  return response;
}

/**
 * This method is used to get the list of accepted/declined jobs
 */
export async function getAcceptedOrDeclinedJobs({ queryKey }) {
  let url = getAPIMap("acceptOrDeclinedJobs");
  url = url.replace("{userid}", queryKey[1]);
  url = url.concat(
    `?page=${queryKey[2]}&pagesize=${queryKey[3]}&invite_status=${queryKey[4]}`
  );
  let response = axiosPrivate.get(url);
  return response;
}
