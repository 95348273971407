import React from "react";
import { useTranslation } from "react-i18next";

const InputLabel = ({ label, color, labelClassName, isMandatory }) => {
  const { t } = useTranslation();

  return (
    <p
      className={`${
        color ? color : "text-grayTextColor"
      } text-xs font-medium mb-1 ${labelClassName}`}
    >
      {t(label)}
      {isMandatory ? <span className="text-asteriskColor text-xs"> *</span> : null}
    </p>
  );
};

export default InputLabel;
