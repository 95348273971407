import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import { FileIcon, PlusIcon } from "../../icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import ConfirmDelete from "../Dialog/ConfirmDelete";
import SuccessDialog from "../Dialog/SuccessDialog";
import Button from "@mui/material/Button";
import { UploadResumeIcon, DeleteIcon } from "../../icons";
import { AuthContext } from "../../context/AuthContext";
import { roles } from "../../common/constants";
import TextButton from "../Button/TextButton";
function UploadImage({
  setFieldValue,
  name,
  value,
  isLoading,
  isPostAJob,
  isCompanyDetails,
  isEditable = true,
  changeButton = false,
  showDeleteDialog = true,
}) {
  const { t } = useTranslation();
  const [deleteImageDialog, setDeleteImageDialog] = useState(false);
  const [successImageDialog, setSuccessImageDialog] = useState(false);
  const { role } = useContext(AuthContext);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".png"],
      "image/gif": [".gif"],
      "image/bmp": [".bmp"],
    },
    maxFiles: 1,
    maxSize: 3 * 1024 * 1024,
    noClick: true,
    multiple: false,

    onDropAccepted: (files) => {
      const file = files[0];
      setFieldValue(name, file);
    },
    onDropRejected: () => {},
  });
  // const handleRemoveClick = () => {
  //   setFieldValue(name, "");
  //   setDeleteImageDialog(false);
  //   setSuccessImageDialog(true);
  // };

  return (
    <>
      <Box width={"100%"} sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {isPostAJob ? (
          <>
            {value === "" ? (
              <Button
                className="px-8 h-[50px] border-borderInactive rounded-5px border mt-5 md:mt-0 normal-case hover:bg-transparent w-full"
                variant="outlined"
                onClick={open}
              >
                <UploadResumeIcon className="inline"></UploadResumeIcon>
                <span className="text-xs text-titleSecondary my-auto ml-10px">
                  {t("_Add_Logo")}
                </span>
              </Button>
            ) : (
              <img
                src={value?.file || URL.createObjectURL(value)}
                className="rounded-10px h-[150px] w-[150px]"
                alt="ProfilePicture"
              />
            )}
          </>
        ) : (
          <div
            {...getRootProps({ className: "dropzone" })}
            className="rounded-10px bg-bgTertiary relative flex items-center justify-center"
            style={{
              width: "150px",
              height: "150px",
            }}
          >
            {value === "" ? (
              <>
                <input {...getInputProps()} />
                <PlusIcon onClick={open} />
              </>
            ) : (
              <img
                src={value?.file || URL.createObjectURL(value)}
                className="rounded-10px h-[150px] w-[150px]"
                alt="ProfilePicture"
              />
            )}
          </div>
        )}
        {value !== "" ? (
          <div className="flex justify-between w-auto flex-1 px-4 h-[60px] bg-bgSecondary items-center">
            <div className="flex gap-5">
              <FileIcon />
              <div>
                <p className="font-semibold text-medium whitespace-pre-wrap">
                  {value?.name}
                </p>
              </div>
            </div>
            {isEditable ? (
              <button
                disabled={role !== roles.master_recruiter && isPostAJob}
                onClick={(e) => {
                  if (showDeleteDialog) {
                    e.preventDefault();
                    setDeleteImageDialog(true);
                  } else {
                    setFieldValue(name, "");
                  }
                }}
                className="text-[#FF686E] text-xs font-semibold m-0 p-0"
              >
                {/* {t("_Remove")} */}
                <DeleteIcon></DeleteIcon>
              </button>
            ) : null}
          </div>
        ) : null}

        <ConfirmDelete
          message={
            isCompanyDetails
              ? t("_Are_you_sure_you_want_to_delete_company_logo?")
              : t("_Are_you_sure_you_want_to_delete_profile_picture?")
          }
          open={deleteImageDialog}
          onCancelClick={() => setDeleteImageDialog(false)}
          onDeleteClick={() => {
            setFieldValue(name, "");
            setDeleteImageDialog(false);
            setSuccessImageDialog(true);
          }}
        />
        <SuccessDialog
          message={
            isCompanyDetails
              ? t("_Company_logo_deleted_successfully")
              : t("_Profile_picture_deleted_successfully")
          }
          open={successImageDialog}
          onOkayClick={() => setSuccessImageDialog(false)}
        />
      </Box>
      {value !== "" && changeButton ? (
        <TextButton onClick={open} label={"_Change_photo"}></TextButton>
      ) : null}
    </>
  );
}

export default UploadImage;
