import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BackArrowIcon, ErrorOutline } from "../icons";
import QuickResumeSubContent1 from "../components/Forms/QuickResumeSubContent1";
import { Form, Formik } from "formik";
import ResumeRightSideCard from "../components/Resume/ResumeRightSideCard";
import AddEducationForm from "../components/Forms/AddEducationForm";
import AddWorkExperienceForm from "../components/Forms/AddWorkExperienceForm";
import CareerDetailsForm from "../components/Forms/CareerDetailsForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddCirtificateForm from "../components/Forms/AddCirtificateForm";
import { convertToDateObject } from "../common/constants";
import ConfirmNNextButton from "../components/Button/Button";
import { getProfileDetails, updateProfileDetails } from "../lib/userprofile-api";
import { useMutation, useQuery } from "react-query";
import ErrorDialog from "../components/Dialog/ErrorDialog";
import { AuthContext } from "../context/AuthContext";
import dayjs from "dayjs";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import jwt_decode from "jwt-decode";
import { UploadProfilePhoto } from "../lib/userprofile-api";
import Joyride, { STATUS } from "react-joyride";
import { UpdateUser } from "../lib/userprofile-api";
function QuickResume() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const op = searchParams.get("op");
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const { mutate: updateUserDetails, isLoading: isUpdateUserDetailsLoading } =
    useMutation(UpdateUser);
  const [{ run, steps }, setState] = useState({
    run: true,
    steps: [
      {
        content: (
          <div style={{ fontSize: "14px !important" }}>
            {t("_Confirm_details_in_each_section")}
          </div>
        ),
        locale: { skip: <strong aria-label="skip">{t("_SKIP")}</strong> },
        placement: "top",
        target: ".first-step",
        disableBeacon: true,
      },
      // {
      //   content: <h2>{t("_Confirm_your_Work_Experience")}</h2>,
      //   locale: { skip: <strong aria-label="skip">{t("_SKIP")}</strong> },
      //   placement: "bottom",
      //   target: ".second-step",
      //   disableBeacon: true,
      // },
      // {
      //   content: <h2>{t("_Confirm_your_education")}</h2>,
      //   locale: { skip: <strong aria-label="skip">{t("_SKIP")}</strong> },
      //   placement: "bottom",
      //   target: ".third-step",
      //   disableBeacon: true,
      // },
      // {
      //   content: <h2>{t("_Enter_and_Confirm_your_Skills")}</h2>,
      //   locale: { skip: <strong aria-label="skip">{t("_SKIP")}</strong> },
      //   placement: "bottom",
      //   target: ".fourth-step",
      //   disableBeacon: true,
      // },
      // {
      //   content: <h2>{t("_Confirm_your_Certificates")}</h2>,
      //   locale: { skip: <strong aria-label="skip">{t("_SKIP")}</strong> },
      //   placement: "bottom",
      //   target: ".fifth-step",
      //   disableBeacon: true,
      // },
      // {
      //   content: <h2>{t("_Enter_details")}</h2>,
      //   locale: { skip: <strong aria-label="skip">{t("_SKIP")}</strong> },
      //   placement: "right",
      //   target: ".sixth-step",
      //   disableBeacon: true,
      // },
    ],
  });
  const { userId, setUser } = useContext(AuthContext);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);

  const { mutate: updateDetails, isLoading: isUpdateDetailsLoading } =
    useMutation(updateProfileDetails);
  const { mutate: uploadPhoto, isLoading: isUploadPhotoLoading } =
    useMutation(UploadProfilePhoto);

  const { data: profileData, isLoading: isProfileDataLoading } = useQuery(
    ["/quickresume", userId],
    getProfileDetails,
    {
      cacheTime: 0,
    }
  );

  const handleErrorDialog = () => {
    setErrorDialog(false);
  };

  const validate = Yup.object({
    skills: Yup.array().of(
      Yup.object().shape({
        skillName: Yup.string().required(t("_Skill_is_required")),
        skillLevel: Yup.number().required(t("_Level_is_required")),
      })
    ),
    noticePeriod: Yup.string().required(t("_Notice_Period_is_required")),
    experience: Yup.string().required(t("_Experience_is_required")),
    currentCtc: Yup.number()
      .typeError("Only numbers allowed")
      .required(t("_Current_ctc_is_required"))
      .max(9999999999, t("_Max_10_digits_allowed")),
    //Commenting below validation as per confirmation from Sumathi
    //.max(Yup.ref("expectedCTC"), t("_Enter_value_less_than_expected_ctc")),
    expectedCTC: Yup.number()
      .typeError("Only numbers allowed")
      .required(t("_Expected_ctc_is_required"))
      .max(9999999999, t("_Max_10_digits_allowed")),
    // .test(
    //   "currentCtc",
    //   t("_Please_enter_value_greater_then_current_ctc"),
    //   (value, ctx) => {
    //     return ctx?.parent?.currentCtc < value;
    //   }
    // ),
    workPlace: Yup.array().min(1, t("_Work_place_is_required")),
    employmentType: Yup.string().required(t("_Employment_type_is_required")),
    preferredPlaceToRelocate: Yup.array().of(
      Yup.object().shape({
        country: Yup.string(),
        states: Yup.array()
          .min(1, t("_Preferred_Location_is_required"))
          .max(5, t("_Max_allowed_is_5")),
      })
    ),
    educations: Yup.array().min(1).required(),
  });
  const sortedWorkExperiences = profileData
    ? profileData?.data?.data?.work_experience?.company_details?.company_details
        ?.map((companyDetail) => {
          //console.log("companyDetail", companyDetail);
          if (companyDetail?.company_name) {
            return {
              title: companyDetail?.job_title,
              companyName: companyDetail?.company_name || "",
              from:
                companyDetail?.start_date === null
                  ? ""
                  : convertToDateObject(companyDetail.start_date),
              to:
                companyDetail?.end_date === null
                  ? ""
                  : convertToDateObject(companyDetail.end_date),

              description: companyDetail?.summary || "",
              currentlyWorkHere:
                parseInt(companyDetail?.is_current_company) === 1 ? true : false,
            };
          } else {
            return {};
          }
        })
        .sort((a, b) => dayjs(b.from).diff(dayjs(a.from)))
    : [];

  const sortedEducations = profileData
    ? profileData?.data?.data?.education?.edu_details
        ?.map((eduDetails) => {
          return {
            title: eduDetails?.specialization,
            collegeUniversityName: eduDetails?.univ_name,
            qualification: eduDetails?.qualification,
            Specialization: eduDetails?.specialization,
            from:
              eduDetails?.start_date === null
                ? ""
                : convertToDateObject(eduDetails.start_date),
            to:
              eduDetails?.end_date === null
                ? ""
                : convertToDateObject(eduDetails.end_date),

            cgpa: eduDetails?.score,
          };
        })
        .sort((a, b) => dayjs(b.from).diff(dayjs(a.from)))
    : [];

  const sortedCertificates = profileData
    ? profileData?.data?.data?.education?.cert_details
        ?.map((certDetail) => {
          return {
            date: certDetail?.cert_year,
            title: certDetail?.cert_in,
            university: certDetail?.cert_by,
          };
        })
        .sort((a, b) => dayjs(b.date).diff(dayjs(a.date)))
    : [];

  const handleScreenScroll = () => {
    window.scrollTo(0, 0);
    setFormSubmitted(false);
    setShowErrorText(true);
  };
  console.log(profileData);
  return (
    <div className=" max-w-[1440px] m-auto">
      {!isProfileDataLoading ? (
        <Formik
          validationSchema={validate}
          initialValues={{
            preferredPlaceToRelocate: profileData?.data?.data?.location_interest
              ?.preferred_location?.[0]?.country
              ? profileData?.data?.data?.location_interest?.preferred_location
              : [{ country: "IN", states: [] }],
            // preferredPlaceToRelocate:
            //   profileData?.data?.data?.location_interest?.preferred_location,
            currentCtcCurrency: {
              label:
                profileData?.data.data?.more_aboutyou?.ctc_details?.current_currency ||
                "INR",
              value:
                profileData?.data.data?.more_aboutyou?.ctc_details?.current_currency ||
                "INR",
            },
            employmentType:
              profileData?.data?.data?.location_interest?.preferred_employment_type?.[0],
            workPlace: profileData?.data?.data?.location_interest?.preferred_work_mode,
            currentCtc:
              profileData?.data.data?.more_aboutyou?.ctc_details?.current_ctc || 0,
            expectedCTC:
              profileData?.data.data?.more_aboutyou?.ctc_details?.expected_ctc || 0,
            expectedCTCCurrency: {
              label:
                profileData?.data.data?.more_aboutyou?.ctc_details?.expected_currency ||
                "INR",
              value:
                profileData?.data.data?.more_aboutyou?.ctc_details?.expected_currency ||
                "INR",
            },
            // skills: [
            //   {
            //     skillName: "",
            //     skillLevel: "",
            //   },
            //   {
            //     skillName: "",
            //     skillLevel: "",
            //   },
            //   {
            //     skillName: "",
            //     skillLevel: "",
            //   },
            //   {
            //     skillName: "",
            //     skillLevel: "",
            //   },
            //   {
            //     skillName: "",
            //     skillLevel: "",
            //   },
            // ],
            skills:
              profileData?.data.data?.rateyour_skills?.skill_proficiency?.length === 5
                ? profileData?.data.data?.rateyour_skills?.skill_proficiency?.map((x) => {
                    return {
                      skillName: x?.skill_name,
                      skillLevel: x?.skill_level,
                    };
                  })
                : [
                    {
                      skillName: "",
                      skillLevel: "",
                    },
                    {
                      skillName: "",
                      skillLevel: "",
                    },
                    {
                      skillName: "",
                      skillLevel: "",
                    },
                    {
                      skillName: "",
                      skillLevel: "",
                    },
                    {
                      skillName: "",
                      skillLevel: "",
                    },
                  ],

            certificateData: sortedCertificates,

            isCertificateVerified: false,
            name: profileData?.data?.data?.personal_information.first_name
              ? profileData?.data?.data?.personal_information.first_name +
                " " +
                profileData?.data?.data?.personal_information.last_name
              : "",
            firstName: profileData?.data?.data?.personal_information.first_name,
            lastName: profileData?.data?.data?.personal_information.last_name,
            //currentDesignation: "UX Designer, Bengaluru",
            currentDesignation:
              profileData?.data?.data?.work_experience?.company_details
                ?.company_details?.[0]?.job_title || "",

            mobile: profileData?.data?.data?.personal_information?.primary_phone || "",
            email: profileData?.data?.data?.personal_information?.primary_email || "",
            currentWorkLocation: profileData?.data?.data?.location_interest
              ?.current_location
              ? profileData?.data?.data?.location_interest?.current_location
              : {
                  country: "IN",
                  city: "",
                },
            skillData: [
              // {
              //   skillName: "Adobe Photoshop",
              //   skillLevel: 5,
              // },
              // {
              //   skillName: "React",
              //   skillLevel: 4,
              // },
            ],

            isSkillVerified: false,
            careerSummary: profileData?.data?.data?.more_aboutyou?.about_me,
            profilePicture: "",
            workExperiences: sortedWorkExperiences,
            isWorkExpVerified: false,
            educations: sortedEducations,
            noticePeriod: profileData?.data?.data?.location_interest.notice_period || "",
            isEducationVerified: false,
            isContactNSummaryVerified: false,
            experience:
              profileData?.data?.data?.work_experience?.total_experience !== null
                ? profileData?.data?.data?.work_experience?.total_experience
                : profileData?.data?.data?.work_experience?.total_experience === 0
                ? 0
                : "",
          }}
          onSubmit={(values) => {
            setIsNextButtonClicked(true);
            // console.log("Value", values);
            const payload = {
              completed_screen_id: 1,

              education: {
                edu_details: values?.educations?.map((education) => {
                  return {
                    score: education.cgpa,
                    end_date: education.to ? dayjs(education.to).format("MM-YYYY") : "",
                    univ_name: education.collegeUniversityName,
                    start_date: education.from
                      ? dayjs(education.from).format("MM-YYYY")
                      : "",
                    qualification: education.qualification,
                    specialization: education.Specialization,
                  };
                }),
                cert_details: values?.certificateData?.map((certificate) => {
                  return {
                    cert_by: certificate.university,
                    cert_in: certificate.title,
                    cert_year: dayjs(certificate.date).format("YYYY"),
                  };
                }),
              },
              work_experience: {
                total_experience: values?.experience,
                company_details: values?.workExperiences?.map((workExperience) => {
                  return {
                    summary: workExperience.description,
                    end_date: workExperience.to
                      ? dayjs(workExperience.to).format("MM-YYYY")
                      : "",

                    job_title: workExperience.title,
                    start_date: workExperience.from
                      ? dayjs(workExperience.from).format("MM-YYYY")
                      : "",
                    company_name: workExperience.companyName,
                    is_current_company: workExperience.currentlyWorkHere ? 1 : 0,
                  };
                }),
              },
              rateyour_skills: {
                skill_proficiency: values?.skills?.map((skill) => {
                  return {
                    skill_name: skill.skillName,
                    skill_level: skill.skillLevel.toString(),
                  };
                }),
              },

              location_interest: {
                preferred_location: values?.preferredPlaceToRelocate,
                notice_period: values?.noticePeriod,
                current_location: JSON.stringify(values?.currentWorkLocation),
              },
              more_aboutyou: {
                about_me: values?.careerSummary,
                ctc_details: {
                  current_currency: values?.currentCtcCurrency.value,
                  current_ctc: values?.currentCtc,
                  expected_currency: values?.expectedCTCCurrency.value,
                  expected_ctc: values?.expectedCTC,
                },
              },
            };
            console.log(values);
            if (
              values?.firstName === null ||
              values.lastName === null ||
              !values?.mobile ||
              values?.mobile === "91"
            ) {
              var newurl = `?type=${"CD"}&op=edit`;
              navigate(newurl);
              // } else if (
              //   !(
              //     values?.isEducationVerified &&
              //     values?.isWorkExpVerified &&
              //     values?.isSkillVerified &&
              //     values?.isCertificateVerified
              //   )
              // ) {
              //   setErrorMessage(
              //     "_Please_Confirm_All_Details_by_clicking_on_tick_icon_next_to_each_section"
              //   );
              //   setErrorDialog(true);
            } else {
              const session = JSON.parse(localStorage.getItem("session"));
              const userToken = jwt_decode(session?.accessToken);
              updateDetails(
                { userId, payload },
                {
                  onSuccess: () => {
                    if (values.profilePicture !== "") {
                      let imagePayload = new FormData();
                      imagePayload.append("image", values.profilePicture);
                      imagePayload.append("is_primary_pic", 1);
                      uploadPhoto(
                        { imagePayload, userId },
                        {
                          onSuccess: () => {
                            setUser(userToken);
                            navigate("/app/dashboard");
                          },
                        }
                      );
                    } else {
                      setUser(userToken);
                      navigate("/app/dashboard");
                    }
                  },
                  onError: (error) => {
                    setErrorDialog(true);
                    setErrorMessage(error?.response?.data?.message);
                  },
                }
              );
            }
            if (
              !profileData?.data?.data?.personal_information.first_name &&
              values?.firstName
            ) {
              const userDetailsPayload = {
                first_name: values.firstName,
                last_name: values.lastName,
              };
              updateUserDetails(
                { userDetailsPayload, userId },
                { onSuccess: (resp) => {} }
              );
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isValid,
            errors,
            submitForm,
          }) => (
            <Form>
              {/* {console.log("initialValues", values)} */}
              {Object.keys(errors).length > 0 && formSubmitted
                ? handleScreenScroll()
                : ""}
              <Joyride
                callback={() => {}}
                continuous={false}
                run={run}
                scrollToFirstStep={true}
                showProgress={false}
                showSkipButton={false}
                steps={steps}
                locale={{ close: t("_OK") }}
                autoStart
                styles={{
                  options: {
                    arrowColor: "#FFFFFF",
                    backgroundColor: "#FFFFFF",
                    width: 230,
                    primaryColor: "#0071B6",
                    textColor: "#2d2d2d",
                    zIndex: 1000,
                    fontSize: "12px",
                  },
                  tooltip: {
                    fontSize: "14px",
                  },
                  buttonNext: {
                    fontSize: "12px",
                  },
                  buttonClose: {
                    fontSize: "12px",
                  },
                }}
              />
              {/* {console.log(values)} */}
              <div className={`${type === null ? "block" : "hidden"}`}>
                <div className=" items-center my-5 gap-3 text-inputColor text-medium hover:cursor-pointer">
                  {/* <BackArrowIcon
                    onClick={() => navigate("/auth/resume")}
                    className="w-5 h-[17px]"
                  /> */}
                  <div className="text-base mx-auto text-center font-semibold ">
                    <p className="text-base  text-title mt-5">
                      {t("_We_have_your_Resume")} -
                      <span className="underline text-secondary cursor-pointer ">
                        {profileData?.data?.data?.profile_information?.resume_file}
                      </span>
                      {" from "}
                      <span className="text-title">
                        {" "}
                        {dayjs(
                          profileData?.data?.data?.profile_information?.resume_upload_date
                        ).format("DD-MM-YYYY") +
                          ". " +
                          t("_To_update_resume,_click") +
                          " "}
                      </span>
                      <span
                        className="text-base font-semibold text-secondary"
                        onClick={() => navigate("/auth/resume")}
                      >
                        here
                      </span>
                    </p>
                  </div>
                </div>
                <p className="text-center text-base mb-2 font-semibold">
                  {t(
                    "_Complete_your_ATS-friendly_profile_and_pave_the_way_for_your_next_career_move!"
                  )}
                </p>
                <div className=" border-resumeBorder border-2 bg-bgSecondary ">
                  {showErrorText && Object.keys(errors).length > 0 ? (
                    <div className="text-sm text-error-icon text-center">
                      {t("_Please_fill_all_the_mandatory_fields")}
                    </div>
                  ) : null}
                  <div className="flex my-[50px] mx-[50px] gap-10">
                    <div className="border-resumeSubBorder border-2 bg-bgPrimary min-w-[450px]">
                      <QuickResumeSubContent1
                        key={1}
                        values={values}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isNextButtonClicked={isNextButtonClicked}
                      />
                    </div>
                    <ResumeRightSideCard
                      key={1}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      isNextButtonClicked={isNextButtonClicked}
                    />
                  </div>
                </div>
              </div>

              {type === "WE" ? (
                <AddWorkExperienceForm
                  isEdit={op === "edit" ? true : false}
                  setFieldValue={setFieldValue}
                  workExperienceArray={values.workExperiences}
                />
              ) : null}

              {type === "E" ? (
                <AddEducationForm
                  isEdit={op === "edit" ? true : false}
                  setFieldValue={setFieldValue}
                  educationArray={values.educations}
                />
              ) : null}

              {type === "CD" ? (
                <CareerDetailsForm
                  isEdit={op === "edit" ? true : false}
                  ovalues={values}
                  setFieldValue={setFieldValue}
                  data={values.workExperiences?.career_summary}
                />
              ) : null}
              {type === "C" ? (
                <AddCirtificateForm
                  isEdit={op === "edit" ? true : false}
                  cirtificateArray={values.certificateData}
                  setFieldValue={setFieldValue}
                />
              ) : null}
              {type !== null ? null : (
                <div className="flex my-10 justify-between">
                  <div className="flex gap-7 ">
                    <ErrorOutline />
                    <div className="text-sm text-title">
                      <p>
                        {t(
                          "_This_resume_template_is_designed_by_Curatal.com._Only_authorized_users_can_use_it."
                        )}
                      </p>
                      <p>
                        {t(
                          "_Duplication_or_copying_any_part_of_the_template_is_illegal."
                        )}
                      </p>
                    </div>
                  </div>
                  <ConfirmNNextButton
                    buttonClassName={"w-[145px] text-xs h-10"}
                    //type={"submit"}
                    label={"_NEXT"}
                    loading={isUpdateDetailsLoading}
                    onClick={() => {
                      setFormSubmitted(true);
                      submitForm();
                    }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <div className="flex justify-center items-center align-middle h-screen m-auto w-full">
          <CircularProgress />
        </div>
      )}
      <ErrorDialog
        message={errorMessage}
        open={errorDialog}
        onClick={handleErrorDialog}
      ></ErrorDialog>
    </div>
  );
}

export default QuickResume;
