import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
const AddButton = ({ buttonClassName,imgsrc, textClassName, ...rest }) => {
  const { t } = useTranslation();
  return (
    <LoadingButton
      {...rest}
      // Below line is commented so that the text can be transformedto uppercase as well.Please set as buttonclassname for the required styles
      //style={{ textTransform: "none" }}
      variant="outlined"
      className={twMerge(
        `h-12 border-2  border-buttonBorder rounded-10px font-medium text-center text-medium text-secondary hover:bg-transparent  ${buttonClassName}`
      )}
    >
     {imgsrc?.length>0?(<img src ={imgsrc} style={{width:"35px", height:'50px',marginTop:'6px'}} alt="cubot" className="inline"/>):null}       
     {t(`${rest.label}`)}
    </LoadingButton>
  );
};

export default AddButton;
