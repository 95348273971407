import { Trans } from "react-i18next";
import i18n from "../translations/i18n";
let t = i18n.t;
export const PrivacyPolicy = () => {
  return (
    <div className="text-xs text-title">
      <br></br>
      <p>
        {t(
          "_This_Privacy_Policy_outlines_the_policies_and_procedures_governing_the_collection_,_use,_and_disclosure_of_your_information_when_you_use_our_service._It_also_informs_you_about_your_privacy_rights_and_how_the_law_safeguards_your_data._By_using_the_service,_you_agree_to_the_collection_and_utilization_of_information_as_per_this_Privacy_Policy"
        )}
      </p>
      <br></br>
      <p>
        <strong>{"1." + t("_Interpretation_and_Definitions")}</strong>
      </p>
      <br></br>
      <p>
        <Trans i18nKey="_Interpretation_Terms_with_initial_capitalization_have_specific_meanings_defined_under_the_following_conditions._These_definitions_remain_consistent_whether_they_appear_in_singular_or_plural_form." />
      </p>
      <br></br>
      <p>{<Trans i18nKey={"_Definitions_For_the_purposes_of_this_Privacy_Policy"} />}</p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Account_refers_to_a_unique_account_created_for_you_to_access_our_service."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              '_The_Company,_also_referred_to_as_"CURATAL,","Company,","We,","Us,",_or_"Our"_in_this_Agreement,_pertains_to_Progressive_Video_LLP,_situated_at_Mandura,_#29/32-1,_19th_Cross,_Krishnaya_Layout,_Ittamadu,_Banashankari_3rd_Stage,_Bangalore,_Karnataka_560085,_India.'
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Cookies_are_small_files_placed_on_your_computer,_mobile_device,_or_other_devices_by_a_website,_containing_details_of_your_browsing_history_on_that_website_among_other_uses."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Country_refers_to_Karnataka,_India."} />}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Device_encompasses_any_device_that_can_access_the_service,_such_as_a_computer,_cell_phone,_or_digital_tablet."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Personal_Data_includes_any_information_related_to_an_identified_or_identifiable_individual."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Service_refers_to_our_website,_which_is_accessible_from_https://www.curatal.com/."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Service_Provider_signifies_any_natural_or_legal_entity_processing_data_on_behalf_of_the_Company._This_includes_third-party_companies_or_individuals_employed_by_the_Company_to_facilitate_the_service,_provide_services_on_its_behalf,_perform_services_related_to_the_service,_or_assist_in_analyzing_how_the_service_is_used."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Usage_Data_pertains_to_data_collected_automatically,_either_generated_by_using_the_service_or_from_the_service_infrastructure_itself._This_includes_but_is_not_limited_to,_your_device's_Internet_Protocol_address_(e.g.,_IP_address),_browser_type,_browser_version,_pages_visited,_date_and_time_of_visits,_time_spent_on_those_pages,_unique_device_identifiers,_and_other_diagnostic_data."
            }
          />
        }
      </p>
      <br></br>
      <p>
        <strong>{"2. " + t("_Collecting_and_Using_Your_Personal_Data")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("_Types_of_Data_Collected")}</strong>
      </p>
      <br></br>
      <p>
        {
          <Trans
            i18nKey={
              "_Personal_Data:_When_using_our_service,_we_may_request_you_to_provide_specific_personally_identifiable_information_that_can_be_used_to_contact_or_identify_you._This_may_include,_but_is_not_limited_to:"
            }
          />
        }
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; &nbsp; {"\u25CF"}&nbsp;{t("_Email_address")}
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; &nbsp; {"\u25CF"}&nbsp;{t("_First_name_and_last_name")}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;{t("_Phone_number")}
      </p>
      <br></br>
      <p>
        {
          <Trans
            i18nKey={
              "_Usage_Data_Usage_data_is_collected_automatically_during_your_use_of_the_service._It_may_encompass_information_like_your_devices_IP_address,_browser_type,_pages_visited_on_our_service,_date_and_time_of_your_visits,_duration_of_page_visits,_unique_device_identifiers,_and_other_diagnostic_data."
            }
          />
        }
      </p>
      <br></br>
      <p>
        {t(
          "_If_you_access_the_service_through_a_mobile_device,_we_may_also_collect_specific_information_automatically_,_including_,_but_not_limited_to,_the_type_of_mobile_device_you_use,_your_mobile_device's_unique_ID,_your_mobile_device's_IP_address,_your_mobile_operating_system,_the_type_of_mobile_Internet_browser_you_use,_unique_device_identifiers,_and_other_diagnostic_data._We_may_also_collect_information_that_your_browser_sends_when_you_visit_our_service_or_access_it_via_a_mobile_device."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Tracking_Technologies_and_Cookies")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_We_employ_cookies_and_similar_tracking_technologies_to_monitor_and_store_certain_information_regarding_your_activities_on_our_service,_with_the_aim_of_enhancing_and_analyzing_our_service._These_technologies_may_include:"
        )}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Cookies_or_Browser_Cookies_A_cookie_is_a_small_file_placed_on_your_device._You_can_configure_your_browser_to_refuse_all_cookies_or_notify_you_when_a_cookie_is_being_sent._However,_if_you_decline_cookies,_some_parts_of_our_service_may_not_be_accessible_unless_your_browser_settings_prevent_cookie_usage._Unless_you_have_adjusted_your_browser_settings_to_reject_cookies,_our_service_may_use_cookies."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Flash_Cookies_Certain_features_of_our_service_may_utilize_local_stored_objects_(Flash_Cookies)_to_collect_and_store_information_about_your_preferences_or_activities_on_our_service._Flash_Cookies_are_not_controlled_by_the_same_browser_settings_as_regular_Browser_Cookies._You_can_learn_more_about_managing_Flash_Cookies_at_Adobe's_guide."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Web_Beacons:_Certain_sections_of_our_service_and_our_emails_may_contain_small_electronic_files_known_as_web_beacons_(also_referred_to_as_clear_gifs,_pixel_tags,_and_single-pixel_gifs)._These_enable_us_to_count_users_who_have_visited_specific_pages,_opened_an_email,_and_provide_other_related_website_statistics,_such_as_the_popularity_of_a_certain_section,_as_well_as_verifying_system_and_server_integrity."
            }
          />
        }
      </p>
      <br></br>
      <p>
        {t(
          "_Cookies_may_be_categorized_as_Persistent_or_Session_Cookies._Persistent_Cookies_remain_on_your_personal_computer_or_mobile_device_even_after_you_go_offline,_while_Session_Cookies_are_deleted_as_soon_as_you_close_your_web_browser._For_more_information_about_cookies,_refer_to_&_What_Are_Cookies?"
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_We_utilize_both_Session_and_Persistent_Cookies_for_various_purposes,_including:"
        )}{" "}
      </p>
      <br></br>
      <p>
        &nbsp; {"\u25CF"}&nbsp;
        <strong>{t("_Necessary_/_Essential_Cookies")}</strong>
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Type_Session_Cookies"} />}
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Administered_by_Us"} />}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Purpose_These_Cookies_are_crucial_to_provide_you_with_services_available_through_the_website_and_enable_you_to_use_certain_features._They_help_authenticate_users_and_prevent_fraudulent_use_of_user_accounts._Without_these_Cookies,_requested_services_cannot_be_provided,_and_we_only_use_them_to_deliver_such_services."
            }
          />
        }
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; {"\u25CF"}&nbsp;
        <strong>{t("_Cookies_Policy_/_Notice_Acceptance_Cookies")}</strong>
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Type_Persistent_Cookies"} />}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Administered_by_Us"} />}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Purpose_These_Cookies_identify_whether_users_have_accepted_the_use_of_cookies_on_the_website."
            }
          />
        }
      </p>
      <br></br>
      <p>
        &nbsp; {"\u25CF"}&nbsp;
        {
          <strong>
            <Trans i18nKey={"_Functionality_Cookies"} />
          </strong>
        }
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Type_Persistent_Cookies"} />}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {<Trans i18nKey={"_Administered_by_Us"} />}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {
          <Trans
            i18nKey={
              "_Purpose_These_Cookies_are_crucial_to_provide_you_with_services_available_through_the_website_and_enable_you_to_use_certain_features._They_help_authenticate_users_and_prevent_fraudulent_use_of_user_accounts._Without_these_Cookies,_requested_services_cannot_be_provided,_and_we_only_use_them_to_deliver_such_services."
            }
          />
        }
        {t(
          "_For_more_information_about_the_cookies_we_use_and_your_choices_regarding_cookies,_please_visit_our_Cookies_Policy_or_the_Cookies_section_in_our_Privacy_Policy."
        )}
      </p>

      <br></br>
      <p>
        <strong>{t("_Use_of_Your_Personal_Data")}</strong>
      </p>
      <br></br>
      <p>
        <Trans
          i18nKey={"_The_Company_may_use_Personal_Data_for_the_following_purposes"}
        />
      </p>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_To_Provide_and_Maintain_Our_Service_This_includes_monitoring_the_usage_of_our_service."
          }
        />
      </p>

      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_To_Manage_Your_Account_Managing_your_registration_as_a_user_of_the_service._The_Personal_Data_you_provide_grants_you_access_to_different_functionalities_available_to_registered_users."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_For_the_Performance_of_a_Contract:_This_involves_the_development,_compliance,_and_execution_of_purchase_contracts_for_products,_items,_or_services_you_have_bought,_or_any_other_contract_with_us_through_the_service."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_To_Contact_You:_We_may_contact_you_via_email,_telephone_calls,_SMS,_or_other_electronic_communication_methods,_such_as_push_notifications_from_a_mobile_application._This_is_for_updates_or_informative_communications_related_to_functionalities,_products,_or_contracted_services,_including_security_updates_when_necessary_or_reasonable_for_their_implementation."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_To_Provide_You_with_News_and_Special_Offers:_This_includes_general_information_about_other_goods,_services,_and_events_we_offer,_which_are_similar_to_those_you_have_already_purchased_or_inquired_about,_unless_you_opt_out_of_receiving_such_information."
          }
        />
      </p>
      <br></br>
      <p>
        <Trans
          i18nKey={
            "_To_Manage_Your_Requests_We_attend_to_and_manage_your_requests_to_us."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_For_Business_Transfers:_We_may_use_your_information_to_evaluate_or_execute_mergers,_divestitures,_restructurings,_reorganizations,_dissolutions,_or_other_sales_or_transfers_of_some_or_all_of_our_assets,_whether_as_a_going_concern_or_as_part_of_bankruptcy,_liquidation,_or_a_similar_proceeding,_in_which_Personal_Data_held_by_us_about_our_service_users_is_among_the_assets_transferred."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_For_Other_Purposes:_We_may_use_your_information_for_various_other_purposes,_such_as_data_analysis,_identifying_usage_trends,_assessing_the_effectiveness_of_our_promotional_campaigns,_and_evaluating_and_improving_our_service,_products,_services,_marketing,_and_your_overall_experience."
          }
        />
      </p>
      <br></br>
      <p>
        {t("_We_may_share_your_personal_information_under_the_following_circumstances:")}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_With_Service_Providers:_Sharing_your_personal_information_with_service_providers_to_monitor_and_analyze_the_use_of_our_service,_as_well_as_to_contact_you."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_For_Business_Transfers:_Sharing_or_transferring_your_personal_information_in_connection_with,_or_during_negotiations_for,_any_merger,_sale_of_company_assets,_financing,_or_acquisition_of_all_or_a_portion_of_our_business_by_another_company."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_With_Affiliates:_Sharing_your_information_with_our_affiliates,_provided_they_adhere_to_this_Privacy_Policy._Affiliates_include_our_parent_company_and_any_other_subsidiaries,_joint_venture_partners,_or_companies_we_control_or_that_are_under_common_control_with_us."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_With_Business_Partners:_Sharing_your_information_with_our_business_partners_to_offer_you_specific_products,_services,_or_promotions."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_With_Other_Users:_When_you_share_personal_information_or_interact_in_public_areas_with_other_users,_please_be_aware_that_such_information_may_be_viewed_by_all_users_and_may_be_publicly_distributed_beyond_our_service."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_With_Your_Consent_We_may_disclose_your_personal_information_for_any_other_purpose_with_your_consent"
          }
        />
      </p>
      <br></br>
      <p>
        <strong>{t("_Retention_of_Your_Personal_Data")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_The_Company_will_retain_your_Personal_Data_only_for_as_long_as_necessary_to_fulfill_the_purposes_set_out_in_this_Privacy_Policy._We_will_retain_and_use_your_Personal_Data_to_the_extent_required_to_comply_with_our_legal_obligations_(e.g.,_if_we_are_required_to_retain_your_data_to_comply_with_applicable_laws),_resolve_disputes,_and_enforce_our_legal_agreements_and_policies."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_Usage_Data_is_generally_retained_for_a_shorter_period,_except_when_it_is_used_to_enhance_security_or_improve_the_functionality_of_our_service,_or_when_we_are_legally_obliged_to_keep_it_for_longer_periods."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Transfer_of_Your_Personal_Data")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Your_information,_including_Personal_Data,_is_processed_at_the_Company's_operational_offices_and_other_locations_where_the_involved_parties_are_situated._This_means_your_information_may_be_transferred_to,_and_maintained_on,_computers_located_outside_your_state,_province,_country,_or_other_governmental_jurisdiction,_where_data_protection_laws_may_differ_from_those_in_your_jurisdiction."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_By_consenting_to_this_Privacy_Policy_and_submitting_your_information,_you_agree_to_such_transfers._The_Company_will_take_all_reasonably_necessary_steps_to_ensure_your_data_is_treated_securely_and_in_compliance_with_this_Privacy_Policy._We_will_not_transfer_your_Personal_Data_to_an_organization_or_country_unless_adequate_controls,_including_data_and_personal_information_security,_are_in_place."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Disclosure_of_Your_Personal_Data")}</strong>
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Business_Transactions:_In_the_event_the_Company_is_engaged_in_a_merger,_acquisition,_or_asset_sale,_your_Personal_Data_may_be_transferred._We_will_provide_notice_before_your_Personal_Data_is_transferred_and_subject_to_a_different_Privacy_Policy."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Law_Enforcement:_Under_specific_circumstances,_the_Company_may_be_obligated_to_disclose_your_Personal_Data_if_required_by_law_or_in_response_to_valid_requests_from_public_authorities_(e.g.,_a_court_or_government_agency)."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Other_Legal_Requirements_The_Company_may_disclose_your_Personal_Data_in_good_faith_when_such_action_is_necessary_to"
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;{t("_Comply_with_a_legal_obligation")}
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {t("_Protect_and_defend_the_rights_or_property_of_the_Company")}
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {t("_Prevent_or_investigate_possible_wrongdoing_in_connection_with_the_service")}
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {t("_Protect_the_personal_safety_of_service_users_or_the_public")}
      </p>
      <br></br>
      <p>
        {" "}
        &nbsp; &nbsp; &nbsp; &nbsp; {"\u25CF"}&nbsp;
        {t("_Protect_against_legal_liability")}
      </p>
      <br></br>
      <p>
        <strong>{t("_Security_of_Your_Personal_Data")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_While_we_endeavor_to_use_commercially_acceptable_means_to_protect_your_Personal_Data,_please_be_aware_that_no_method_of_transmission_over_the_Internet_or_electronic_storage_is_100_percent_secure._We_cannot_guarantee_absolute_security_of_your_data."
        )}
      </p>
      <br></br>
      <p>
        {" "}
        <strong>{t("_Children's_Privacy")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Our_service_is_not_intended_for_individuals_under_the_age_of_13,_and_we_do_not_knowingly_collect_personally_identifiable_information_from_such_individuals._If_you_are_a_parent_or_guardian_and_are_aware_that_your_child_has_provided_us_with_Personal_Data,_please_contact_us._If_we_become_aware_that_we_have_collected_Personal_Data_from_anyone_under_the_age_of_13_without_verifying_parental_consent,_we_will_take_steps_to_remove_that_information_from_our_servers._If_our_data_processing_relies_on_consent_as_a_legal_basis_and_your_country_requires_consent_from_a_parent,_we_may_require_your_parent's_consent_before_collecting_and_using_that_information."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Links_to_Other_Websites")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Our_service_may_include_links_to_third-party_websites_not_operated_by_us._If_you_click_on_a_link_to_a_third-party_site,_you_will_be_redirected_to_that_site._We_strongly_advise_you_to_review_the_Privacy_Policy_of_every_site_you_visit."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_We_have_no_control_over_and_assume_no_responsibility_for_the_content,_privacy_policies,_or_practices_of_any_third-party_sites_or_services."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Changes_to_this_Privacy_Policy")}</strong>
      </p>
      <br></br>
      <p>
        <Trans
          i18nKey={
            "_Curatal_reserves_the_right_to_use_your_company_logo_for_display_and_public_promotion_at_our_discretion_on_our_website."
          }
        />
      </p>
      <br></br>
      <p>
        {t(
          "_We_may_update_our_Privacy_Policy_from_time_to_time._We_will_notify_you_of_any_changes_by_posting_the_new_Privacy_Policy_on_this_page._We_will_also_inform_you_via_email_and/or_a_prominent_notice_on_our_service_before_the_changes_become_effective_and_update_the_Last_updated_date_at_the_top_of_this_Privacy_Policy."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_You_are_advised_to_periodically_review_this_Privacy_Policy_for_any_changes._Changes_to_this_Privacy_Policy_are_effective_when_posted_on_this_page."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Contact_Us")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "If_you_have_any_questions_about_this_Privacy_Policy,_you_can_contact_us_at_the_following_address:"
        )}
      </p>
      <br></br>
      <p>{t("_Grievance_Officers")}</p>
      <br></br>
      <p>
        <strong>{t("_Email:reach@curatal.com")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("_Contact_Number:+91 98459 23944")}</strong>
      </p>
      <br></br>
    </div>
  );
};
