import React, { useState } from "react";

// create context
export const ResumeUploadModelContext = React.createContext();

export const ResumeUploadModelProvider = ({ children }) => {
  const [isResumeUploadModelOpen, setIsResumeUploadModelOpen] = useState(false);

  const handleResumeModalClose = () => {
    setIsResumeUploadModelOpen(false);
  };

  return (
    <ResumeUploadModelContext.Provider
      value={{
        isResumeUploadModelOpen,
        handleResumeModalClose,
        setIsResumeUploadModelOpen,
      }}
    >
      {children}
    </ResumeUploadModelContext.Provider>
  );
};
