import React, { useState } from "react";
import { MaterialReactTable } from "material-react-table";
import Pagination from "../Pagination/Pagination";
import { pagination } from "../../common/constants";

const Index = ({
  columns,
  data,
  headerPaddingX,
  bodyPaddingX,
  isLoading,
  onRowClick,
  rowState = [], // Default to an empty array if rowState is not provided
  enableRowSelection = false,
  ...rest
}) => {
  return (
    <div>
      <MaterialReactTable
        {...rest}
        key={rest.pagesize}
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enableRowSelection={enableRowSelection === true ? true : false}
        //enablePagination={true}
        enableSorting={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        manualSorting={false}
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
          },
        }}
        muiTableProps={{ sx: { border: "1px solid #F4F4F4" } }}
        // muiTableBodyRowProps={{
        //   sx: { border: "1px solid #F4F4F4" },

        // }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            if (rest.original) {
              onRowClick(row.original, event);
            } else if (typeof onRowClick === "function") {
              onRowClick(row.original.ticket_number, event);
            }
          },
          sx: {
            border: "1px solid #F4F4F4",
            cursor: "pointer",
            transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            "&:hover": {
              transform: "scaleY(1.02)", // Adjust scale if needed
              boxShadow:
                "0 -10px 20px rgba(0, 0, 0, 0.2), 0 10px 20px rgba(0, 0, 0, 0.2)",
              zIndex: 1,
              transition: "transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
              overflow: "hidden",
            },
          },
        })}
        muiTableBodyCellProps={{
          sx: {
            borderRadius: "0",
            fontSize: "12px",
            fontFamily: "Sora",
            color: "#9CA3AF",
            paddingX: bodyPaddingX ? bodyPaddingX : "46px",
            paddingY: "10px",
            flex: "0 0 auto",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            borderRadius: "0",
            border: "1px solid #F4F4F4",
            backgroundColor: "#F2F8FB",
            fontSize: "12px",
            fontFamily: "Sora",
            color: "#2D2D2D",
            paddingY: "14px",
            paddingX: headerPaddingX ? headerPaddingX : "46px",
            flex: "0 0 auto",
          },
        }}
        rowCount={rest.pagesize}
        state={{
          isLoading: isLoading,
          rowCount: rest.pagesize,
          showProgressBars: false,
          rowSelection: enableRowSelection ? rowState : [],
        }}
        initialState={{
          showColumnFilters: false,
          isLoading: true,
          showProgressBars: false,
          rowCount: rest.pagesize,
        }}
        manualPagination={true}
      />
      {rest.add}
      <div className={"mt-5"}>
        <Pagination
          totalPosts={rest.rowCount}
          paginate={rest.paginate}
          // postsPerPage={pagesize}
          page={rest.currentPage}
          pagesize={rest.pagesize}
          setPagesize={rest.setPagesize}
          goTopageInputBox={rest?.goTopageInputBox ? rest?.goTopageInputBox : false}
        />
      </div>
    </div>
  );
};

export default Index;
