import React, { useEffect, useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import { useQuery, useMutation } from "react-query";
import { pagination } from "../../common/constants";
import { EditIconRole, DeleteRoleIcon, BlueEyeIcon } from "../../icons";
import ListingRoleFilter from "./ListingRoleFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAllRoles,
  deleteRoleById,
  getTemplateList,
} from "../../lib/role-management-api";
import { CssTextField } from "../../components/StyledComponent";
import dayjs from "dayjs";
import SuccessDialog from "../../components/Dialog/SuccessDialog";
import ErrorDialog from "../../components/Dialog/ErrorDialog";
import { CssTooltip } from "../../components/StyledComponent";
import ConfirmDelete from "../../components/Dialog/ConfirmDelete";
import { FilterGrayIcon, SearchIcon, FilterIcon } from "../../icons";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import { TextField, InputAdornment } from "@mui/material";
import Button from "../../components/Button/Button";
import ConfirmDeleteRole from "../../components/Dialog/ConfirmDeleteRole";
import { AuthContext } from "../../context/AuthContext";

const ListingRole = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const loginUserRoleArray = user.realm_access?.roles[0];
  const loginUserRole = loginUserRoleArray.split("~")[1];

  const navigate = useNavigate();
  const accountFilter = searchParams.get("account_Filter");
  const subscriptionFilter = searchParams.get("sub_Filter");
  const [pageIndex, setPageIndex] = useState(1);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pagesize, setPagesize] = useState(pagination.pageSize);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [templateList, setTemplateList] = useState("");
  const [totalItems, setTotalItems] = useState();
  const [templateName, setTemplateName] = useState();
  const [resetKey, setResetKey] = useState(0);
  const [roleName, setRoleName] = useState();

  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [accountNameFilter, setAccountNameFilter] = useState(
    ["active", "inactive", "all"].includes(accountFilter) ? [accountFilter] : []
  );
  const [subscriptionStatusFilter, setSubscriptionStatusFilter] = useState(
    ["active", "inactive", "all", "cancel"].includes(subscriptionFilter)
      ? [subscriptionFilter]
      : []
  );
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: "", endDate: "" });
  const [tableData, setTableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { roledata, isLoading, refetch } = useQuery(
    ["getAllRoles", pageIndex, pagesize, searchText, templateName],
    getAllRoles,
    {
      onSuccess: (res) => {
        setTotalItems(res?.data.data.count);
        if (res?.data.data) {
          setTableData(res?.data.data.rows);
        }
      },
    }
  );

  const scope = window.localStorage.getItem("scope");

  const readAccess = scope.includes("role_mgmt_r");
  const editAccess = scope.includes("role_mgmt_e");
  const deleteAccess = scope.includes("role_mgmt_d");
  const createAccess = scope.includes("role_mgmt_c");

  const { templatedata, isTemplateListLoading } = useQuery(
    "getTemplateList",
    getTemplateList,
    {
      onSuccess: (res) => {
        if (Array.isArray(res?.data) && res.data.length > 0) {
          const templateOptions = res.data.map((item) => ({
            value: item.template,
            label: item.template,
          }));
          setTemplateList(templateOptions);
        } else {
          console.error("Response data is not in expected format:", res);
        }
      },
    }
  );

  console.log(searchText);

  const { mutate: deleteRole } = useMutation(deleteRoleById);

  const handleDelete = (role_id, role_name) => {
    setValue(role_id);
    setRoleName(role_name);
    setDeleteDialog(true);
  };

  const paginate = (currentPage) => setPageIndex(currentPage);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_date",
        header: t("_Creation_Date"),
        size: "100",
        Cell: ({ cell }) => {
          const dateValue = cell.row.original.createdAt;
          return <span>{dayjs(dateValue).format("DD-MM-YYYY")}</span>;
        },
      },
      {
        accessorKey: "role",
        header: t("_Role"),
        size: "200",
        Cell: ({ cell }) => (
          <span className="break-all">{cell.row.original.role_name}</span>
        ),
      },
      {
        accessorKey: "role_template",
        header: t("_Role_Template"),
        size: "250",
        Cell: ({ cell }) => (
          <span className="break-all">{cell.row.original.template}</span>
        ),
      },
      {
        accessorKey: "no_of_users",
        header: t("_#_of_Users"),
        size: "100",
        Cell: ({ cell }) => (
          <span className="break-all">{cell.row.original.users_count || 0}</span>
        ),
      },
      {
        header: t("_Action"),
        size: "10",
        Cell: ({ cell }) => (
          <div className="flex gap-3">
            {editAccess &&
              (loginUserRole !== cell.row.original.role_name ? (
                <CssTooltip title={t("_Edit_Role")} placement="top" arrow>
                  <EditIconRole
                    className="w-4 h-4"
                    onClick={() => {
                      navigate(`/app/rolemanagement/editrole`, {
                        state: {
                          roleid: cell?.row?.original?.role_id,
                          template: cell?.row?.original?.template,
                        },
                      });
                    }}
                  />
                </CssTooltip>
              ) : (
                ""
              ))}
            {deleteAccess &&
              (loginUserRole !== cell.row.original.role_name ? (
                <CssTooltip title={t("_Delete_Role")} placement="top" arrow>
                  <DeleteRoleIcon
                    className={`w-4 h-4 ${
                      cell.row.original.subscription_status === "cancel" ||
                      cell.row.original.users_count > 0
                        ? "opacity-40 pointer-events-none"
                        : ""
                    }`}
                    onClick={() =>
                      handleDelete(
                        cell?.row?.original?.role_id,
                        cell.row.original.role_name
                      )
                    }
                  />
                </CssTooltip>
              ) : (
                ""
              ))}
            <CssTooltip title={t("_View_Users")} placement="top" arrow>
              <BlueEyeIcon
                className={`w-4 h-4 ${
                  cell.row.original.subscription_status === "cancel" ? "opacity-40" : ""
                }`}
                onClick={() => {
                  navigate(`/app/rolemanagement/users`, {
                    state: {
                      role: {
                        roleId: cell?.row?.original?.role_id,
                        roleName: cell?.row?.original?.role_name,
                        template: cell?.row?.original?.template,
                      },
                    },
                  });
                }}
              />
            </CssTooltip>
          </div>
        ),
      },
    ],
    [t, navigate]
  );

  // const handleFiltersChange = (filterType, selectedOptions) => {
  //   setFilters({ ...filters, [filterType]: selectedOptions });
  // };
  const filters = [
    {
      placeholder: "All Role Templates",
      data: templateList,
      isLoading: isTemplateListLoading,
      filterType: "template_name",
    },
  ];

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1);
    setTemplateName();
    setSearchText("");
    setPageIndex(1);
  };

  const deleteMessage = t("_Are_you_sure_you_want_to_delete_the_role", { roleName });

  return (
    <div className="py-10 px-2 md:px-12 w-full">
      <div>
        <p className="text-2xl text-title font-semibold">{t("_Role_Management")}</p>
        <div className="my-10">
          <div className="flex justify-between items-center space-x-4">
            <div className="flex items-center space-x-4">
              <FilterIcon className="w-12 h-12" />
              {filters.map((filter, index) => (
                <div className="mr-2 mt-2 mb-2" key={index}>
                  <CustomDropdown
                    btnName={filter.placeholder}
                    data={filter.data}
                    isLoading={filter.isLoading}
                    filterType={filter.filterType}
                    reset={resetKey}
                    onApply={(selectedOptions) => {
                      setTemplateName(selectedOptions[0]);
                      setPageIndex(1);
                    }}
                    inputType={
                      filter.filterType !== "campany_name" ? "radio" : "checkbox"
                    }
                    isMulti={filter.filterType === "campany_name"}
                    className="text-gray-600 bg-white border border-gray-300 rounded-lg p-2"
                  />
                </div>
              ))}
              <CssTextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Search"
                fullWidth
                value={searchText}
                className="w-full h-[35px]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="w-4 h-4 text-gray-500 mx-2" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setPageIndex(1);
                }}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
              <div>
                <Button
                  buttonClassName=" w-24 h-[35px] text-xs rounded-[20px] mx-2 "
                  onClick={handleReset}
                  label="_RESET"
                />
              </div>
            </div>
            {createAccess && (
              <Button
                buttonClassName="w-24 h-[35px] text-xs rounded mx-2"
                onClick={() => navigate("/app/rolemanagement/role/add")}
                label="_ADD_ROLE"
              />
            )}
          </div>
        </div>
        <Table
          columns={columns}
          data={tableData}
          headerPaddingX="18px"
          bodyPaddingX="18px"
          isLoading={isLoading}
          rowCount={totalItems || 0}
          paginate={paginate}
          currentPage={pageIndex}
          pagesize={pagesize}
          setPagesize={setPagesize}
        />
      </div>
      <ConfirmDeleteRole
        message={deleteMessage}
        open={deleteDialog}
        roleName={roleName}
        onCancelClick={() => setDeleteDialog(false)}
        onDeleteClick={() => {
          deleteRole(
            { value },
            {
              onSuccess: () => {
                setDeleteDialog(false);
                setMessage("_Role_deleted_successfully");
                setSuccessDialog(true);
                refetch();
              },
              onError: (error) => {
                setDeleteDialog(false);
                setErrorDialog(true);
                setErrorMessage(error?.response?.data?.message);
              },
            }
          );
        }}
        maxWidth={500}
      />
      <SuccessDialog
        message={message}
        open={successDialog}
        onOkayClick={() => {
          setSuccessDialog(false);
        }}
      />
      <ErrorDialog
        message={errorMessage}
        open={errorDialog}
        onClick={() => {
          setErrorDialog(false);
          refetch();
        }}
      />
    </div>
  );
};

export default ListingRole;
