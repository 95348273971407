import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
//import Select from "../FormsUI/Select";
import {
  educationCollegeValidation,
  cgpaValidation,
  toValidation,
  fromValidation,
  educationSpecializationValidation,
  educationQualificationValidation,
} from "../../common/yupValidations";
import MonthPicker from "../FormsUI/MonthYearPicker";
import TextField from "../FormsUI/TextField";
import { BackArrowIcon } from "../../icons";
import Button from "../Button/Button";
import CloseButton from "../Button/OutlinedButton";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import Select from "../Select/MultiSelect";
import { getQualificationList } from "../../lib/dropDownList-api";
import _ from "lodash";
import InputLabel from "../FormsUI/InputLabel";
const AddEducationForm = ({ isEdit, setFieldValue, educationArray }) => {
  const [qualificationOptions, setQualificationOptions] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let item = location.state;
  let newAdded = educationArray || [];
  const { isLoading: isQualificationLoading } = useQuery(
    "geQualificationList",
    getQualificationList,
    {
      onSuccess: (resp) => {
        let y = resp?.data?.data?.qualification_list?.map((x, i) => {
          return { label: x, value: x };
        });

        setQualificationOptions(y);
      },
    }
  );

  return (
    <div className="flex justify-center mt-8">
      <div className="">
        <div className="flex gap-4 mb-8">
          <BackArrowIcon
            onClick={() => {
              window.history.back();
            }}
            className="w-5"
          />
          <label className="text-inputColor font-medium">{t("BACK")}</label>
        </div>
        <Box
          sx={{
            // width: "600px",
            backgroundColor: "#FAFAFAB3",
            border: "2px solid #E8E8E8",
            borderRadius: "10px",
            paddingY: "30px",
            paddingX: "40px",
          }}
        >
          <Formik
            initialValues={{
              qualification: item?.qualification || "",
              Specialization: item?.Specialization || "",
              college: item?.collegeUniversityName || "",
              from: item?.from || null,
              to: item?.to || null,
              cgpa: item?.cgpa || "",
            }}
            validationSchema={Yup.object().shape({
              qualification: educationQualificationValidation,
              Specialization: educationSpecializationValidation,
              college: educationCollegeValidation,
              from: fromValidation,
              to: toValidation,
              cgpa: cgpaValidation,
            })}
            validateOnMount
            // onSubmit={(values) => {
            //   if (isEdit) {
            //     let newData = Object.assign(newAdded[item?.index], {
            //       title: values.Specialization,
            //       collegeUniversityName: values.college,
            //       from: values.from,
            //       to: values.to,
            //       cgpa: values.cgpa,
            //       qualification: values.qualification,
            //       Specialization: values.Specialization,
            //     });
            //     newAdded[item?.index] = newData;
            //     newAdded.sort((a, b) => new Date(a.from) - new Date(b.from));
            //     setFieldValue(`educations`, newAdded);
            //   } else {
            //     // educationArray.push({
            //     //   title: values.title,
            //     //   collegeUniversityName: values.college,
            //     //   from: values.from,
            //     //   to: values.to,
            //     //   cgpa: values.cgpa,
            //     // });

            //     newAdded.push({
            //       title: values.Specialization,
            //       collegeUniversityName: values.college,
            //       from: values.from,
            //       to: values.to,
            //       cgpa: values.cgpa,
            //       qualification: values.qualification,
            //       Specialization: values.Specialization,
            //     });
            //     newAdded.sort((a, b) => new Date(a.from) - new Date(b.from));
            //     setFieldValue(`educations`, newAdded);
            //   }

            //   navigate(-1);
            // }}
          >
            {({ errors, status, isValid, values }) => (
              <Form>
                {console.log(errors)}
                <div>
                  <label className="text-inputColor font-semibold">
                    {isEdit ? t("_Edit_Education") : t("_Add_Education")}
                  </label>

                  <div>
                    <div className="mt-5">
                      <InputLabel label={"_Qualification"} />

                      <Select
                        name="qualification"
                        options={qualificationOptions}
                        isSearchable={true}
                        placeholder={""}
                      />
                    </div>
                    <div className="mt-5">
                      <TextField
                        name="Specialization"
                        label="_Specialization"
                      ></TextField>
                    </div>
                    <div className=" mt-5">
                      <TextField
                        name="college"
                        label="_College_/_University_name"
                      ></TextField>
                    </div>
                    <div className="flex justify-between mt-5 gap-5">
                      <MonthPicker
                        name="from"
                        label="_From"
                        isEdit={isEdit}
                      ></MonthPicker>
                      <MonthPicker name="to" label="_To" isEdit={isEdit}></MonthPicker>
                    </div>
                    <div className="mt-5">
                      <TextField name="cgpa" label="_CGPA"></TextField>
                    </div>
                  </div>

                  <div className="flex gap-5 mt-9">
                    <Button
                      onClick={() => {
                        if (isEdit) {
                          let newData = Object.assign(newAdded[item?.index], {
                            title: values.Specialization,
                            collegeUniversityName: values.college,
                            from: values.from,
                            to: values.to,
                            cgpa: values.cgpa,
                            qualification: values.qualification,
                            Specialization: values.Specialization,
                          });
                          newAdded[item?.index] = newData;
                          newAdded.sort((a, b) => new Date(b.from) - new Date(a.from));
                          setFieldValue(`educations`, newAdded);
                        } else {
                          // educationArray.push({
                          //   title: values.title,
                          //   collegeUniversityName: values.college,
                          //   from: values.from,
                          //   to: values.to,
                          //   cgpa: values.cgpa,
                          // });

                          newAdded.push({
                            title: values.Specialization,
                            collegeUniversityName: values.college,
                            from: values.from,
                            to: values.to ? values.to : null,
                            cgpa: values.cgpa,
                            qualification: values.qualification,
                            Specialization: values.Specialization,
                          });
                          newAdded.sort((a, b) => new Date(b.from) - new Date(a.from));
                          setFieldValue(`educations`, newAdded);
                        }

                        navigate("/auth/quick-resume");
                      }}
                      buttonClassName="w-24 h-9 px-10 py-4 text-xs"
                      label="_SAVE"
                      disabled={!isValid}
                      type="submit"
                    />
                    <CloseButton
                      // onClick={() => {
                      //   handleClose();
                      //   resetForm();
                      // }}
                      buttonClassName="w-24 h-9 px-10 py-4 text-xs"
                      onClick={() => {
                        navigate(-1);
                      }}
                      label="_Cancel"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
};

export default AddEducationForm;
