import React, { useState, useRef, useEffect, memo } from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { VariableSizeList as List, areEqual } from "react-window";
import { twMerge } from "tailwind-merge";

const Dropdown = ({
  btnName,
  btnWidth,
  btnHeight,
  width,
  data,
  isLoading,
  reset,
  onSelectionChange,
  isMulti = true,
  disabled = false,
  defaultSelectedData,
  commonValues,
  isSearch = true,
  searchParameter = "value",
  isReports,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const buttonRef = useRef(null);

  const Styles = {
    btnPrimary: `bg-transparent text-gray-400 px-2 w-full rounded border flex items-center ${
      btnWidth ? btnWidth : "min-w-[150px]"
    } ${btnHeight ? btnHeight : "h-[35px]"}`,
  };
  const itemHeight = 30;
  const maxListHeight = 150;

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (commonValues) {
      const valuesArray = Array.isArray(commonValues)
        ? commonValues
        : commonValues.split(",");
      setSelectedOptions(valuesArray);
    } else {
      setSelectedOptions([]);
    }
  }, [commonValues]);

  const handleCheckboxChange = (option, filterType) => {
    if (!isMulti) {
      if (selectedOptions.includes(option.value)) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions([option.value]);
      }
    } else {
      // setSelectedOptions((prevSelectedOptions) => {
      //   if (prevSelectedOptions.includes(option.value)) {
      //     return prevSelectedOptions.filter(
      //       (selectedOption) => selectedOption !== option.value
      //     );
      //   } else {
      //     return [...prevSelectedOptions, option.value];
      //   }
      // });
      setSelectedOptions((prevSelectedOptions) => {
        const newSelectedOptions = prevSelectedOptions.includes(option.value)
          ? prevSelectedOptions.filter(
              (selectedOption) => selectedOption !== option.value
            )
          : [...prevSelectedOptions, option.value];

        // Notify parent of the selection change
        onSelectionChange(newSelectedOptions);

        return newSelectedOptions;
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  useEffect(() => {
    setSelectedOptions([]);
    setSearchText("");
  }, [reset]);

  // useEffect(() => {
  //   onSelectionChange(selectedOptions);
  // }, [defaultSelectedData]);

  const filteredOptions =
    data?.length > 0
      ? data.filter((option) =>
          String(option[searchParameter]).toLowerCase().includes(searchText.toLowerCase())
        )
      : [];

  useEffect(() => {
    if (defaultSelectedData?.length) {
      setSelectedOptions(defaultSelectedData);
    }
  }, []);
  const combinedOptions = [
    ...selectedOptions
      .map((selectedOption) => data.find((option) => option.value === selectedOption))
      .filter(Boolean),
    ...filteredOptions.filter((option) => {
      return (
        option &&
        option?.value &&
        option?.label &&
        !selectedOptions.includes(option?.value)
      );
    }),
  ];

  const Row = memo(({ data, index, style }) => {
    const currentOption = combinedOptions?.[index];

    return (
      <div key={index} style={style} className="mb-4">
        <label className="flex gap-2 items-center mb-9 text-xs">
          <input
            type="checkbox"
            checked={selectedOptions.includes(currentOption?.value)}
            onChange={() => handleCheckboxChange(currentOption)}
            className="min-w-[20px] w-4 h-4"
            disabled={isReports && defaultSelectedData?.includes(currentOption?.value)}
          />
          <div className=" text-xs">{currentOption?.label}</div>
        </label>
        {/* {isLastSelectedOption && index !== combinedOptions.length - 1 && (
          <hr className="w-full border my-2" />
        )} */}
      </div>
    );
  }, areEqual);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={` ${btnWidth ? "" : "min-w-[150px]"}  relative text-medium`}>
        <button
          ref={buttonRef}
          onClick={handleOpen}
          className={open ? `${Styles.btnPrimary} border-gray-800` : Styles.btnPrimary}
          disabled={disabled}
        >
          <div className="flex justify-between w-full whitespace-nowrap">
            <div className="text-xs mt-1">
              {t(btnName)}{" "}
              {selectedOptions.length ? <span>({selectedOptions.length})</span> : null}
            </div>
            <div className="flex items-center ml-5">
              {open ? <ExpandLess className="ml-2" /> : <ExpandMore className="ml-2" />}
            </div>
          </div>
        </button>
        {open ? (
          <div className="relative bottom-0 left-0 z-50 ">
            <div
              className={`bg-white shadow-md p-4 rounded absolute  ${
                btnWidth ? "min-w-full w-[160%]" : "min-w-[150px]"
              }`}
            >
              {isSearch && (
                <div className="mb-4 ">
                  <div
                    className={`flex items-center border border-gray-300 px-2 py-1 rounded w-full `}
                  >
                    <SearchIcon className="text-gray-400 mr-4 ml-1" />
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchText}
                      onChange={handleSearchChange}
                      className="border-none outline-none w-full text-xs"
                    />
                  </div>
                </div>
              )}
              {isLoading ? (
                <div className="flex justify-center">
                  <CircularProgress />
                </div>
              ) : (
                <div className="w-full max-h-64 ">
                  <List
                    width={width}
                    height={Math.min(combinedOptions.length * itemHeight, maxListHeight)}
                    itemCount={combinedOptions?.length}
                    itemData={combinedOptions}
                    itemSize={(index) =>
                      combinedOptions[index]?.label?.length > 65
                        ? 75
                        : combinedOptions[index]?.label?.length > 50
                        ? 65
                        : combinedOptions[index].label?.length > 45
                        ? 55
                        : combinedOptions[index]?.label?.length > 25
                        ? 45
                        : 30
                    }
                  >
                    {Row}
                  </List>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default Dropdown;
