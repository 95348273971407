import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CheckmarkIcon } from "../../icons";
import DialogActions from "@mui/material/DialogActions";
import Closebutton from "../Button/OutlinedButton";
const ApplyConfirmation = (props) => {
  const { handleProfile, onClose, open, message, subContent, ...other } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: 475, textAlign: "center" } }}
      maxWidth="xs"
      open={open}
      {...other}
      className="p-6 rounded-md"
    >
      <DialogTitle sx={{ textAlign: "center", marginTop: "24px" }}>
        <CheckmarkIcon className="h-[50px] w-[50px] text-success mx-auto " />
        <div className="mt-4 text-sm">{t("_Success")}</div>
      </DialogTitle>
      <DialogContent className="text-medium mt-5 px-5">{t(message)}</DialogContent>
      <DialogContent className="text-sm px-5">{t(subContent)}</DialogContent>
      <DialogActions className="flex my-5 mx-auto gap-3 ">
        <Closebutton onClick={onClose} label="_SKIP" buttonClassName={""}></Closebutton>
        <Button
          onClick={handleProfile}
          label="_GO_TO_PROFILE"
          buttonClassName={"mt-0"}
        ></Button>
      </DialogActions>
    </Dialog>
  );
};
export default ApplyConfirmation;
