import * as React from "react";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: "10px",
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
    height: "250px",
  },

  "& .MuiPaper-root.MuiDialog-paper": {
    maxWidth: "750px",
    width: "750px",
  },
}));

export default function TermsDialog({ handleClose, open, title, content, dialogTitle }) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            height: "70px",
            background: "#0071B6",
          }}
          id="customized-dialog-title"
        >
          <span className="text-medium text-white">{dialogTitle || ""}</span>
          <div className="flex flex-row ">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon className="text-white" />
            </IconButton>{" "}
          </div>
        </DialogTitle>

        <div className="flex justify-center text-titleSecondary text-medium my-2 font-semibold">
          {title}
        </div>
        <DialogContent
        //style={{ width: "522px", margin: "auto" }}
        >
          <div className=" px-4 overflow-x-hidden text-xs text-title"> {content}</div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
