import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { reinviteEmailId } from "../../lib/pca/candidate-profile-api"
import { useParams, useNavigate } from 'react-router-dom';
import { CheckmarkIcon, ErrorIcon } from "../../icons";
import Button from "../../components/Button/Button"

const ActiveEmail = () => {
    const [successDialog, setSuccessDialog] = useState(false);
    const [status, setStatus] = useState(null);
    const [ requestSent, setRequestSent ] = useState(false)
    const [message, setMessage] = useState("");
    const { issuedTo, alinkToken } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { mutate: reinvite } = useMutation(reinviteEmailId, {
        onSuccess: (response) => {
            setMessage(response?.data?.message);
            setStatus('success'); // Set status to success
        },
        onError: (error) => {
            setMessage(error?.response?.data?.message);
            setStatus('error'); // Set status to error
        },
    });

    useEffect(() => {
        if (issuedTo && alinkToken && !requestSent) {
            setRequestSent(true); // Set flag to prevent duplicate requests
            reinvite({ issuedTo, alinkToken });
        }
    }, [issuedTo, alinkToken, requestSent, reinvite]);


    const styles = {
        container:
            "flex items-center justify-center absolute top-0 bottom-0 right-0 left-0 top bg-bgPrimary  sm:m-4 rounded-10px",
    };
    return (
        <div className={styles.container}>
            <div className=" rounded-xl shadow-[0px 0px 6px #00000029] w-full  max-w-[583px] p-2 ">
                {status==="success" ? (
                    <div className="flex flex-col items-center  pt-10 pb-10 space-y-2 bg-white rounded-10px shadow-[0px 0px 6px #00000029]">
                        <CheckmarkIcon className="h-[50px] w-[50px] text-success mx-auto " />
                        <h1 className="text-xl text-heading mt-4">{t("_Verify_account")}</h1>
                        <p className="text-xs  mx-10 pb-8 text-center max-w-[500px] mt-6">
                            {t(
                                "_Your_account_is_sucessful_actived."
                            )}
                        </p>
                        <Button
                            buttonClassName=" w-[88%] rounded-lg h-dropdownHeight text-xs"
                            label="_OKAY"
                            onClick={() => navigate("/auth/login")}
                        />
                    </div>
                ) : null}
                {status==="error" ? (
                    <div className="flex flex-col items-center  pt-10 pb-10 space-y-2 bg-white rounded-10px shadow-[0px 0px 6px #00000029]">
                        <ErrorIcon className="h-[50px] w-[50px] text-error-icon mx-auto " />
                        <h1 className="text-xl text-heading mt-4">{t("_Verify_account")}</h1>
                        <p className="text-xs  mx-10 pb-8 text-center max-w-[500px] mt-6">
                            {t(
                                "_No_user_found"
                            )}
                        </p>
                        <Button
                            buttonClassName=" w-[88%] rounded-lg h-dropdownHeight text-xs"
                            label="_TRY_AGAIN"
                            onClick={() => navigate("/auth/login")}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}
export default ActiveEmail;