import Button from "../Button/Button";
import { Trans, useTranslation } from "react-i18next";
import { CheckmarkIcon } from "../../icons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
const SuccessDialog = (props) => {
  const {
    onOkayClick,
    open,
    message,
    btnLabel,
    subContent,
    width = 417,
    i18nKey,
    ...other
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width, textAlign: "center" } }}
      maxWidth="xs"
      open={open}
      {...other}
      className="p-6 rounded-md"
    >
      <DialogTitle sx={{ textAlign: "center", marginTop: "24px" }}>
        <CheckmarkIcon className="h-[50px] w-[50px] text-success mx-auto " />
        <div className="mt-4 text-sm">{t("_Success")}</div>
      </DialogTitle>
      <div className="text-xs text-title font-medium">
        {message !== "" ? t(message) : <Trans i18nKey={i18nKey} />}
      </div>
      {subContent && (
        <p className="text-primary text-sm font-medium md:mr-12 md:ml-12 mb-4">
          {t(subContent)}
        </p>
      )}

      <div className="text-center mt-7">
        <Button
          onClick={onOkayClick}
          label={btnLabel ? btnLabel : "_OKAY"}
          buttonClassName="w-[145px] h-10 text-xs mb-9"
        ></Button>
      </div>
    </Dialog>
  );
};
export default SuccessDialog;
