import { t } from "i18next";
import React from "react";
import { Helmet } from "react-helmet-async";

const index = ({ title, description }) => {
  return (
    <>
      <Helmet>
        <title>{t(title)}</title>
        <meta name="description" content={t(description)} />
        <link rel="icon" href="%PUBLIC_URL%/logo.png" />
      </Helmet>
    </>
  );
};

export default index;
