import React from "react";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  certifiedInValidation,
  certifiedByValidation,
  certifiedYearValidation,
} from "../../common/yupValidations";
import YearPicker from "../FormsUI/YearPicker";
import TextField from "../FormsUI/TextField";
import _ from "lodash";
import { BackArrowIcon } from "../../icons";
import Button from "../Button/Button";
import CloseButton from "../Button/OutlinedButton";
import { useLocation, useNavigate } from "react-router";

const AddCirtificateForm = ({ isEdit, setFieldValue, cirtificateArray }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let item = location.state;
  console.log(item);
  let newAdded = cirtificateArray;
  return (
    <div className="flex justify-center mt-8">
      <div className="">
        <div className="flex gap-4 mb-8">
          <BackArrowIcon
            onClick={() => {
              navigate(-1);
            }}
            className="w-5"
          />
          <label className="text-inputColor font-medium">{t("BACK")}</label>
        </div>
        <Box
          sx={{
            width: "600px",
            backgroundColor: "#FAFAFAB3",
            border: "2px solid #E8E8E8",
            borderRadius: "10px",
            paddingY: "30px",
            paddingX: "40px",
          }}
        >
          <Formik
            initialValues={{
              certifiedIn: item?.title || "",

              certifiedBy: item?.university || "",
              date: item?.date ? new Date(item?.date) : "",
            }}
            validationSchema={Yup.object().shape({
              certifiedIn: certifiedInValidation,
              certifiedBy: certifiedByValidation,
              date: certifiedYearValidation,
            })}
          >
            {({
              errors,
              status,
              isValid,
              isSubmitting,
              values,

              handleChange,
              handleBlur,
              resetForm,
            }) => (
              <Form>
                {console.log(values)}
                <div>
                  <label className="text-inputColor font-semibold">
                    {isEdit ? t("_Edit_Certificate") : t("_Add_Certificate")}
                  </label>
                  <div className="mt-9">
                    <div>
                      <TextField
                        className=" w-full mb-5"
                        label={"_Certified_In"}
                        name="certifiedIn"
                      />
                      {/* </div>
                  <div className=""> */}
                      <TextField
                        className=" w-full"
                        label={"_Certified_by"}
                        name="certifiedBy"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-5 mt-5">
                  <YearPicker
                    label={"_Year"}
                    name="date"
                    inputClassName="min-w-[516px]"
                  />
                </div>

                <div className=" text-right mt-10 ">
                  <CloseButton
                    // onClick={() => {
                    //   handleClose();
                    //   resetForm();
                    // }}
                    onClick={() => {
                      navigate(-1);
                    }}
                    label="_Cancel"
                  />

                  <Button
                    onClick={() => {
                      if (isEdit) {
                        let newData = Object.assign(newAdded[item?.index], {
                          date: values.date,
                          title: values.certifiedIn,
                          university: values.certifiedBy,
                        });
                        newAdded[item?.index] = newData;
                        newAdded.sort((a, b) => new Date(a.date) - new Date(b.date));
                        setFieldValue(`certificateData`, newAdded);
                      } else {
                        newAdded.push({
                          date: values.date,
                          title: values.certifiedIn,
                          university: values.certifiedBy,
                        });
                        // cirtificateArray.push({
                        //   date: dayjs(values.date).format("MMM YYYY"),
                        //   title: values.certifiedIn,
                        //   university: values.certifiedBy,
                        // });
                        newAdded.sort((a, b) => new Date(a.date) - new Date(b.date));
                        setFieldValue(`certificateData`, newAdded);
                      }
                      navigate(-1);
                    }}
                    buttonClassName="w-[120px] ml-5"
                    label="_SAVE"
                    disabled={!isValid}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
};

export default AddCirtificateForm;
