import Select from "react-select";
import { useField } from "formik";
import { CssTextField } from "../StyledComponent";
import { useQuery } from "react-query";
import { getCurrencyCodes } from "../../lib/userprofile-api";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { templates } from "../../common/constants";
function CurrencyCode(props) {
  const [field] = useField(props.fieldName || "");
  const { template } = useContext(AuthContext);
  const [currencyData, setCurrencyData] = useState([]);

  const { isLoading } = useQuery("getCurrencyCodes", getCurrencyCodes, {
    onSuccess: (data) => {
      let optionsData = data?.data?.data?.map((x) => ({ label: x.code, value: x.code }));
      setCurrencyData(optionsData);
    },
  });

  // const inputStyles = {
  //   defaultStyle: "border-l border-formInputBorder mt-1 px-4 bg-white text-xs",
  // };
  const style = {
    control: (base, state) => ({
      ...base,
      border: 0,
      borderRadius: "5px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",

      width: "70px",
      height: "40px",
      // This line disable the blue border
      boxShadow: "none",
      whiteSpace: "noWrap",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#0071B6" : "white",
    }),
    menu: (styles) => ({
      ...styles,
      width: "100%",
      backgroundColor: "white",
      fontSize: "12px",
      textAlign: "center",

      // position: "absolute",
      // left: "-20%",

      border: "0 !important",
      // This line disable the blue border
      boxShadow: "none !important",
      "&:hover": {
        border: "none !important",
      },
      //whiteSpace: "nowrap",
      wordBreak: "break-all",
    }),
    singleValue: () => ({
      fontSize: props.borderColor ? "12px" : "12px",
      marginTop: "-23px",
      marginLeft: "3px",
      fontWeight: props.bordrColor ? 500 : 500,
      //whiteSpace: "nowrap",
      color: "#2D2D2D",
      fontSize: "12px",
    }),
    dropdownIndicator: (data) => ({
      ...data,
      "@media (max-width: 768px)": {
        display: "none !important",
      },
      paddingLeft: 0,
    }),

    input: (data) => ({
      ...data,
      "input:focus": {
        boxShadow: "none",
      },
      border: "0 !important",
      // This line disable the blue border
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      padding: 0,
    }),
  };

  return (
    <div
      className={
        "flex  flex-row  border  border-formInputBorder hover:border-borderPrimary focus-visible:border-borderPrimary hover:border-2 hover:-mt-[1px] rounded-5px "
      }
    >
      <Select
        isLoading={isLoading}
        aria-label="currencycode"
        inputProps={{
          autoComplete: "turnoff",
          autoCorrect: "off",
          spellCheck: "off",
        }}
        styles={style}
        isSearchable={false}
        options={props.options || currencyData}
        maxMenuHeight={200}
        components={{
          IndicatorSeparator: () => null,
        }}
        className="border-r-2"
        value={props.values[props.currencyFieldName]}
        onChange={(value) => {
          props.setFieldValue(props.currencyFieldName, value);
        }}
        name={props.currencyFieldName}
        isDisabled={false || props.disabledValue}
      />

      <CssTextField
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        variant="standard"
        fullWidth={true}
        type={props.type ? props.type : "number"}
        onWheel={(event) => event.target.blur()}
        placeholder={props.placeholder || "0"}
        onKeyDown={(e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+" ||
            e.key === "."
          ) {
            e.preventDefault();
          }
        }}
        InputProps={{
          style: {
            padding: "6px 10px",
            fontSize: "12px",
            fontFamily: "Sora",
            fontWeight: 500,
          },
          disableUnderline: true, // <== added this
        }}
        // {...field}
        // placeholder={props.placeHolder ? props.placeHolder : t("_Enter_phone_number")}
        className="border-r-2"
        style={{
          borderRadius: "5px",
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
          minHeight: "40px",
          backgroundColor: "white",
          fontSize: "12px",
        }}
        disabled={false || props.disabledValue}
      />
    </div>
  );
}
export default CurrencyCode;
