import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";

/**
 * This method is used for listing Notifications
 * @param {*} options
 * @returns
 */
export async function getNotificationList(options) {
  let url = getAPIMap("getNotificationList");
  url += `?userId=${options.queryKey[1]}`;
  if (options.queryKey[2] && options.queryKey[2]) {
    url += "&pagesize=" + options.queryKey[2];
  }
  if (options.queryKey[3] && options.queryKey[3]) {
    url += "&page=" + options.queryKey[3];
  }
  let response = axiosPrivate.get(url);
  return response;
}

export async function getUnreadNotificationCount(options) {
  let url = getAPIMap("getUnreadNotificationCount");
  url = url + `?userId=${options.queryKey[1]}`;

  let response = axiosPrivate.get(url);
  return response;
}

export async function updateNotificationReadStatus(options) {
  let url = getAPIMap("updateNotificationReadStatus");

  let response = axiosPrivate.put(url, options.payload);
  return response;
}
