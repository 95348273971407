import React from 'react'

import SidebarContent from './SidebarContent'

function DesktopSidebar(props) {
  return (
      <SidebarContent />
  )
}

export default DesktopSidebar
