import { useContext, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../routes";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Main from "../containers/Main";
import ThemedSuspense from "../components/ThemedSuspense";
//import { SidebarContext } from "../context/SidebarContext";
import MyProfileSidebar from "../components/Sidebar/MyProfileSidebar";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "react-router-dom";

const Page404 = lazy(() => import("../pages/404"));

function Layout() {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  // const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div className={`h-screen overflow-x-hidden overflow-y-auto`}>
      <div className="h-full flex flex-col overflow-auto">
        <div className="z-9999">
          <Header />
        </div>
        <div className="h-full flex overflow-x-hidden overflow-y-auto">
          <div className="shrink-0">
            <div className="flex h-full ">
              <Sidebar />
              {/* {user?.realm_access?.roles[0]?.toLowerCase().includes("candidate") &&
                pathname?.includes("profile") && <MyProfileSidebar />} */}
            </div>
          </div>
          {/** Overflow-x-hidden is added so that the table doesnt expand to the div width when left nav openend */}
          <div className="flex flex-col flex-1 w-full overflow-x-hidden">
            <Main>
              <Suspense fallback={<ThemedSuspense />}>
                <Routes>
                  {routes.map((route, i) => {
                    return route.component ? (
                      <Route
                        key={i}
                        path={`${route.path}`}
                        element={<route.component />}
                      />
                    ) : null;
                  })}
                  {/* <Route
                    path="/app"
                    element={<Navigate to="/app/dashboard" replace={true} />}
                  /> */}
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </Suspense>
            </Main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
