import React, { useState, useEffect } from "react";
import JobListingDetails from "./JobListingDetails";
import JobDescription from "../../pages/JobDescription";

function CompanyListView({ data, jobId, refetch, isOpenSearch }) {
  const [isContainerShrunk, setIsContainerShrunk] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  console.log("ListView", isOpenSearch);
  useEffect(() => {
    if (jobId) {
      const job = data?.data?.data.find((company) => company.job_id === jobId);
      if (job) {
        setSelectedJob(job);
        setIsContainerShrunk(true);
      }
    }
  }, [data, jobId]);

  const handleClickDetail = (job) => {
    setSelectedJob(job);
    setIsContainerShrunk(true);
  };

  const handleCloseJobDescription = () => {
    setIsContainerShrunk(false);
    setSelectedJob(null);
  };

  return (
    <div className="grid md:flex ">
      <div className="border w-full border-borderCompany rounded-lg">
        {data?.data?.data?.map((company, index) => (
          <div key={index}>
            <JobListingDetails
              company={company}
              onClickDetail={() => handleClickDetail(company)}
              isContainerShrunk={isContainerShrunk}
              setSelectedJob={setSelectedJob}
              isOpenSearch={isOpenSearch}
              refetch={refetch}
            />
            <div className="block lg:hidden">
              {selectedJob && selectedJob.job_id === company.job_id && (
                <JobDescription
                  selectedJob={selectedJob}
                  onClose={handleCloseJobDescription}
                  refetch={refetch}
                  isOpenSearch={isOpenSearch}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {isContainerShrunk && (
        <div className="border border-borderCompany rounded-lg ml-5 max-w-[598px] hidden lg:block">
          {selectedJob && (
            <JobDescription
              selectedJob={selectedJob}
              onClose={handleCloseJobDescription}
              refetch={refetch}
              isOpenSearch={isOpenSearch}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CompanyListView;
