import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { twMerge } from "tailwind-merge";
const BackButton = ({ buttonClassName, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="text"
      className={` border-0 text-medium rounded-lg font-medium h-12 text-textButtonColor ${buttonClassName} hover:bg-bgSecondary`}
      {...rest}
    >
      {t(`${rest.label}`)}
    </Button>
  );
};

export default BackButton;
