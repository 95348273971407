import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { templates } from "../../common/constants";
import Auth from "../../containers/Auth";
import { AuthContext } from "../../context/AuthContext";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function ForwardRoute() {
  const { user, session, template } = useContext(AuthContext);
  //const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <>
      {user && session && template === templates.recruiter ? (
        <Navigate to={"/app/recruiter/dashboard"} />
      ) : user && session && template === templates.platformAdmin ? (
        <Navigate to={"/app/admin/dashboard"} />
      ) : user && session && template === templates.candidate ? (
        <Navigate to={"/app/dashboard"} />
      ) : user && session && template === templates.pcaAdmin ? (
        <Navigate to={"/app/pca_admin/dashboard"} />
      ) : user && session && template === templates.pcaAgent ? (
        <Navigate to={"/app/pca_agent/dashboard"} />
      ) : (
        // <GoogleReCaptchaProvider
        //   reCaptchaKey={recaptchaKey}
        //   scriptProps={{
        //     async: false,
        //     defer: false,
        //     appendTo: "head",
        //     nonce: undefined,
        //   }}
        // >
        <Auth />
        // </GoogleReCaptchaProvider>
      )}
    </>
  );
}
