import { t } from "i18next";
import { Trans } from "react-i18next";

export const TermsNConditions = () => {
  return (
    <div className="text-xs text-title">
      <br></br>
      <p>
        <strong>{t("_Interpretation_and_Definitions")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("_Interpretation")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_The_words_of_which_the_initial_letter_is_capitalized_have_meanings_defined_under_the_following_conditions._The_following_definitions_shall_have_the_same_meaning_regardless_of_whether_they_appear_in_singular_or_in_plural."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Definitions")}</strong>
      </p>
      <br></br>
      <p>{t("_For_the_purposes_of_these_Terms_and_Conditions:")}</p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            '_Affiliate_means_an_entity_that_controls,_is_controlled_by_or_is_under_common_control_with_a_party,_where_"control"_means_ownership_of_50%_or_more_of_the_shares,_equity_interest_or_other_securities_entitled_to_vote_for_election_of_directors_or_other_managing_authority.'
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans i18nKey={"_Country_refers_to:_Karnataka,_India"} />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            '_Company_(referred_to_as_either_"CURATAL",_"the_Company",_"We",_"Us"_or_"Our"_in_this_Agreement)_refers_to_Progressive_Video_LLP,_Mandura",_#29/32-1,19th_Cross,_Krishnaya_Layout,_Ittamadu,_Banashankari_3rd_Stage,_Bangalore_Karnataka_560085'
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Device_means_any_device_that_can_access_the_Service_such_as_a_computer,_a_cell_phone_or_a_digital_tablet."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans i18nKey={"_Service_refers_to_the_Website."} />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            '_Terms_and_Conditions_(also_referred_as_"Terms")_mean_these_Terms_and_Conditions_that_form_the_entire_agreement_between_You_and_the_Company_regarding_the_use_of_the_Service._This_Terms_and_Conditions_agreement_has_been_created_with_the_help_of_the_Terms_and_Conditions_Generator.'
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Third-party_Social_Media_Service_means_any_services_or_content_(including_data,_information,_products_or_services)_provided_by_a_third-party_that_may_be_displayed,_included_or_made_available_by_the_Service."
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_Website_refers_to_Curatal.com,_accessible_from_https//www.curatal.com"
          }
        />
      </p>
      <br></br>
      <p>
        &nbsp; &nbsp; {"\u25CF"}&nbsp;
        <Trans
          i18nKey={
            "_You_means_the_individual_accessing_or_using_the_Service,_or_the_company,_or_other_legal_entity_on_behalf_of_which_such_individual_is_accessing_or_using_the_Service,_as_applicable."
          }
        />
      </p>
      <br></br>
      <p>
        <strong>{t("_Acknowledgment")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_These_are_the_Terms_and_Conditions_governing_the_use_of_this_Service_and_the_agreement_that_operates_between_You_and_the_Company._These_Terms_and_Conditions_set_out_the_rights_and_obligations_of_all_users_regarding_the_use_of_the_Service."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_Your_access_to_and_use_of_the_Service_is_conditioned_on_Your_acceptance_of_and_compliance_with_these_Terms_and_Conditions._These_Terms_and_Conditions_apply_to_all_visitors,_users_and_others_who_access_or_use_the_Service."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_By_accessing_or_using_the_Service_You_agree_to_be_bound_by_these_Terms_and_Conditions._If_You_disagree_with_any_part_of_these_Terms_and_Conditions_then_You_may_not_access_the_Service."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_You_represent_that_you_are_over_the_age_of_18._The_Company_does_not_permit_those_under_18_to_use_the_Service."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_Your_access_to_and_use_of_the_Service_is_also_conditioned_on_Your_acceptance_of_and_compliance_with_the_Privacy_Policy_of_the_Company._Our_Privacy_Policy_describes_Our_policies_and_procedures_on_the_collection,_use_and_disclosure_of_Your_personal_information_when_You_use_the_Application_or_the_Website_and_tells_You_about_Your_privacy_rights_and_how_the_law_protects_You._Please_read_Our_Privacy_Policy_carefully_before_using_Our_Service."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Links_to_Other_Websites")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Our_Service_may_contain_links_to_third-party_web_sites_or_services_that_are_not_owned_or_controlled_by_the_Company."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_The_Company_has_no_control_over,_and_assumes_no_responsibility_for,_the_content,_privacy_policies,_or_practices_of_any_third_party_web_sites_or_services._You_further_acknowledge_and_agree_that_the_Company_shall_not_be_responsible_or_liable,_directly_or_indirectly,_for_any_damage_or_loss_caused_or_alleged_to_be_caused_by_or_in_connection_with_the_use_of_or_reliance_on_any_such_content,_goods_or_services_available_on_or_through_any_such_web_sites_or_services."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_We_strongly_advise_You_to_read_the_terms_and_conditions_and_privacy_policies_of_any_third-party_web_sites_or_services_that_You_visit."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Termination")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_We_may_terminate_or_suspend_Your_access_immediately,_without_prior_notice_or_liability,_for_any_reason_whatsoever,_including_without_limitation_if_You_breach_these_Terms_and_Conditions."
        )}
      </p>
      <br></br>
      <p>
        {t("_Upon_termination,_Your_right_to_use_the_Service_will_cease_immediately.")}
      </p>
      <br></br>
      <p>
        <strong>{t("_Limitation_of_Liability")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Notwithstanding_any_damages_that_You_might_incur,_the_entire_liability_of_the_Company_and_any_of_its_suppliers_under_any_provision_of_this_Terms_and_Your_exclusive_remedy_for_all_of_the_foregoing_shall_be_limited_to_the_amount_actually_paid_by_You_through_the_Service_or_100_USD_if_You_haven't_purchased_anything_through_the_Service."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_To_the_maximum_extent_permitted_by_applicable_law,_in_no_event_shall_the_Company_or_its_suppliers_be_liable_for_any_special,_incidental,_indirect,_or_consequential_damages_whatsoever_(including,_but_not_limited_to,_damages_for_loss_of_profits,_loss_of_data_or_other_information,_for_business_interruption,_for_personal_injury,_loss_of_privacy_arising_out_of_or_in_any_way_related_to_the_use_of_or_inability_to_use_the_Service,_third-party_software_and/or_third-party_hardware_used_with_the_Service,_or_otherwise_in_connection_with_any_provision_of_this_Terms),_even_if_the_Company_or_any_supplier_has_been_advised_of_the_possibility_of_such_damages_and_even_if_the_remedy_fails_of_its_essential_purpose."
        )}
      </p>
      <br></br>
      <p>
        <span>
          {t(
            "_Some_states_do_not_allow_the_exclusion_of_implied_warranties_or_limitation_of_liability_for_incidental_or_consequential_damages,_which_means_that_some_of_the_above_limitations_may_not_apply._In_these_states,_each_party's_liability_will_be_limited_to_the_greatest_extent_permitted_by_law."
          )}
        </span>
      </p>
      <br></br>
      <p>
        <strong>{t('"AS_IS"_and_"AS_AVAILABLE"_Disclaimer')}</strong>
      </p>
      <br></br>
      <p>
        {t(
          '_The_Service_is_provided_to_You_"AS_IS"_and_"AS_AVAILABLE"_and_with_all_faults_and_defects_without_warranty_of_any_kind._To_the_maximum_extent_permitted_under_applicable_law,_the_Company,_on_its_own_behalf_and_on_behalf_of_its_Affiliates_and_its_and_their_respective_licensors_and_service_providers,_expressly_disclaims_all_warranties,_whether_express,_implied,_statutory_or_otherwise,_with_respect_to_the_Service,_including_all_implied_warranties_of_merchantability,_fitness_for_a_particular_purpose,_title_and_non-infringement,_and_warranties_that_may_arise_out_of_course_of_dealing,_course_of_performance,_usage_or_trade_practice._Without_limitation_to_the_foregoing,_the_Company_provides_no_warranty_or_undertaking,_and_makes_no_representation_of_any_kind_that_the_Service_will_meet_Your_requirements,_achieve_any_intended_results,_be_compatible_or_work_with_any_other_software,_applications,_systems_or_services,_operate_without_interruption,_meet_any_performance_or_reliability_standards_or_be_error_free_or_that_any_errors_or_defects_can_or_will_be_corrected.'
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_Without_limiting_the_foregoing,_neither_the_Company_nor_any_of_the_company's_provider_makes_any_representation_or_warranty_of_any_kind,_express_or_implied:_(i)_as_to_the_operation_or_availability_of_the_Service,_or_the_information,_content,_and_materials_or_products_included_thereon;_(ii)_that_the_Service_will_be_uninterrupted_or_error-free;_(iii)_as_to_the_accuracy,_reliability,_or_currency_of_any_information_or_content_provided_through_the_Service;_or_(iv)_that_the_Service,_its_servers,_the_content,_or_e-mails_sent_from_or_on_behalf_of_the_Company_are_free_of_viruses,_scripts,_trojan_horses,_worms,_malware,_timebombs_or_other_harmful_components."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_Some_jurisdictions_do_not_allow_the_exclusion_of_certain_types_of_warranties_or_limitations_on_applicable_statutory_rights_of_a_consumer,_so_some_or_all_of_the_above_exclusions_and_limitations_may_not_apply_to_You._But_in_such_a_case_the_exclusions_and_limitations_set_forth_in_this_section_shall_be_applied_to_the_greatest_extent_enforceable_under_applicable_law."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Refunds_&_Cancellations")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Refunds_All_Fees_paid_by_the_User_are_non-refundable._User_acknowledges_and_agrees_that_no_refunds_shall_be_paid_on_account_of_opting_out,_cancellation,_non-use_or_partial_use_of_the_Services."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Governing_Law")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_The_laws_of_the_Country,_excluding_its_conflicts_of_law_rules,_shall_govern_this_Terms_and_Your_use_of_the_Service._Your_use_of_the_Application_may_also_be_subject_to_other_local,_state,_national,_or_international_laws."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Disputes_Resolution")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_If_You_have_any_concern_or_dispute_about_the_Service,_You_agree_to_first_try_to_resolve_the_dispute_informally_by_contacting_the_Company."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_For_European_Union_(EU)_Users")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_If_You_are_a_European_Union_consumer,_you_will_benefit_from_any_mandatory_provisions_of_the_law_of_the_country_in_which_you_are_resident_in."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_United_States_Legal_Compliance")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          '_You_represent_and_warrant_that_(i)_You_are_not_located_in_a_country_that_is_subject_to_the_United_States_government_embargo,_or_that_has_been_designated_by_the_United_States_government_as_a_"terrorist supporting" _country,_and_(ii)_You_are_not_listed_on_any_United_States_government_list_of_prohibited_or_restricted_parties.'
        )}
      </p>
      <br></br>

      <p>
        {t(
          "_Your_access_to_and_use_of_the_Service_is_also_conditioned_on_Your_acceptance_of_and_compliance_with_the_Privacy_Policy_of_the_Company. Our_Privacy_Policy_describes_Our_policies_and_procedures_on_the_collection,_use_and_disclosure_of_Your_personal_information_when_You_use_the_Application_or_the_Website_and_tells_You_about_Your_privacy_rights_and_how_the_law_protects_You. Please_read_Our_Privacy_Policy_carefully_before"
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Severability_and_Waiver")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("_Severability")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_If_any_provision_of_these_Terms_is_held_to_be_unenforceable_or_invalid,_such_provision_will_be_changed_and_interpreted_to_accomplish_the_objectives_of_such_provision_to_the_greatest_extent_possible_under_applicable_law_and_the_remaining_provisions_will_continue_in_full_force_and_effect."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Waiver")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_Except_as_provided_herein,_the_failure_to_exercise_a_right_or_to_require_performance_of_an_obligation_under_this_Terms_shall_not_affect_a_party's_ability_to_exercise_such_right_or_require_such_performance_at_any_time_thereafter_nor_shall_the_waiver_of_a_breach_constitute_a_waiver_of_any_subsequent_breach."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Translation_and_Interpretation")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "_These_Terms_and_Conditions_may_have_been_translated_if_We_have_made_them_available_to_You_on_our_Service._You_agree_that_the_original_English_text_shall_prevail_in_the_case_of_a_dispute."
        )}
      </p>
      <br></br>
      <p>{t("_Changes_to_These_Terms_and_Conditions")}</p>
      <br></br>
      <p>
        <Trans
          i18nKey={
            "_Curatal_reserves_the_right_to_use_your_company_logo_for_display_and_public_promotion_at_our_discretion_on_our_website."
          }
        />
      </p>
      <br></br>
      <p>
        {t(
          "_We_reserve_the_right,_at_Our_sole_discretion,_to_modify_or_replace_these_Terms_at_any_time._If_a_revision_is_material_We_will_make_reasonable_efforts_to_provide_at_least_30_days'_notice_prior_to_any_new_terms_taking_effect._What_constitutes_a_material_change_will_be_determined_at_Our_sole_discretion."
        )}
      </p>
      <br></br>
      <p>
        {t(
          "_By_continuing_to_access_or_use_Our_Service_after_those_revisions_become_effective,_You_agree_to_be_bound_by_the_revised_terms._If_You_do_not_agree_to_the_new_terms,_in_whole_or_in_part,_please_stop_using_the_website_and_the_Service."
        )}
      </p>
      <br></br>
      <p>
        <strong>{t("_Contact_Us")}</strong>
      </p>
      <br></br>
      <p>
        {t(
          "If_you_have_any_questions_about_this_Privacy_Policy,_you_can_contact_us_at_the_following_address:"
        )}
      </p>
      <br></br>
      <p>{t("_Grievance_Officers")}</p>
      <br></br>
      <p>
        <strong>{t("_Email:reach@curatal.com")}</strong>
      </p>
      <br></br>
      <p>
        <strong>{t("_Contact_Number:+91 98459 23944")}</strong>
      </p>
    </div>
  );
};
