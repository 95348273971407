import React, { useContext, useEffect, useState } from "react";
import routes from "../../routes/ProfileSidebar";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getProfileDetails } from "../../lib/userprofile-api";
import { AuthContext } from "../../context/AuthContext";
import { Avatar, CircularProgress, Skeleton } from "@mui/material";

const MyProfileSidebar = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const userName = user.name;
  const userId = user.sub;

  const { data, isLoading, refetch } = useQuery(
    ["/profileDetails6", userId],
    getProfileDetails
  );
  return (
    <div className=" h-full w-full max-w-[242px] shadow-md pt-1 overflow-y-auto">
      <div className={`min-h-full px-5 pt-4 pb-2`}>
        <div className="flex gap-5 px-4  justify-center items-center bg-[#EFF0F3] rounded-md py-6 w-full">
          {isLoading ? (
            <div className="grid items-center gap-3 grid-cols-3">
              <Skeleton
                className="rounded-full w-10 h-10 col-span-1"
                variant="round"
              ></Skeleton>
              <Skeleton className="w-20 col-span-2"></Skeleton>
            </div>
          ) : (
            <>
              <div className="relative h-full">
                <CircularProgress
                  variant="determinate"
                  value={parseInt(data?.data?.data?.profile_information?.profile_score)}
                  sx={{ scale: "-1 -1" }}
                ></CircularProgress>
                <div className="absolute top-[2px] left-[2px]">
                  <Avatar
                    alt="profilepic"
                    src={
                      data?.data?.data?.profile_information?.profile_pic_file
                        ? data?.data?.data?.profile_information?.profile_pic_file[0]
                            ?.pro_pic_file
                        : ""
                    }
                    sx={{ width: 36, height: 36 }}
                  />
                </div>
                <div className="text-xs absolute bottom-0 left-8 top-8">
                  {parseInt(data?.data?.data?.profile_information?.profile_score) + "%"}
                </div>
              </div>
              <div className="w-full text-medium text-title font-semibold">
                {userName}
              </div>
            </>
          )}
        </div>
        <div className="w-full mt-2 ">
          {routes.map((route, i) => (
            <div className={`flex flex-col w-full`} key={i}>
              {/* <div className={`flex flex-row cursor-pointer w-full text-title  `}> */}
              <NavLink
                to={route.path}
                className={"grid grid-cols-6 gap-8 w-full items-center p-2.5 rounded-md"}
                // className={"flex items-center w-full gap-3 p-2.5 rounded-md"}
                style={({ isActive }) =>
                  isActive
                    ? { backgroundColor: "#0071B6", color: "#fff" }
                    : { backgroundColor: "transparent", color: "#2D2D2D" }
                }
              >
                <div className="col-span-1 w-6 h-6 border-2 bg-white border-buttonBorder rounded-full">
                  <span className="flex justify-center items-center font-semibold h-full text-secondary text-xs "></span>
                </div>
                <h1 className={`col-span-5 text-xs font-semibold mt-0.5 cursor-pointer`}>
                  {t(`${route.name}`)}
                </h1>
              </NavLink>
            </div>
            // </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyProfileSidebar;
