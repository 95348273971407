import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Button from "../../components/Button/Button";
import JobsFilter from "../../components/Dialog/JobsFilter";
import {
  employmentTypeOptions,
  onSiteRemoteOptions,
  recentlyPostedData,
  experienceData,
  roleOptions,
  filterTypes,
} from "../../common/constants";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import {
  getCompanyList,
  getIndustryList,
  getSkillList,
} from "../../lib/dropDownList-api";
import { AuthContext } from "../../context/AuthContext";

function SearchJobFilter({
  skill,
  experience,
  employmentType,
  recentlyPosted,
  onApplyFilters,
  onResetFilters,
  onResetFilterJobs,
  onResetJobFilter,
}) {
  const styles = {
    container: "border border-borderCompany border-2",
  };

  const { t } = useTranslation();
  const { userId } = useContext(AuthContext);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  //   const [skillOptions, setSkillOptions] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [resetKeyMoreFIlters, setResetKeyMoreFilters] = useState(0);
  //   const { isLoading: isSkillListLoading } = useQuery(["get rate skills"], getSkillList, {
  //     onSuccess: (data) => {
  //       let skillOptions = data?.data?.data?.map((skill) => {
  //         return {
  //           value: skill.skill_name,
  //           label: skill.skill_name,
  //         };
  //       });
  //       setSkillOptions(skillOptions);
  //     },
  //   });

  const handleFilterDialogOpen = () => {
    setOpenFilterDialog(true);
  };

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };
  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1);
    onResetFilterJobs();
  };
  const handleJobReset = () => {
    //setResetKey((prevKey) => prevKey + 1);
    onResetJobFilter();
  };

  return (
    <div>
      <div className={`${styles.container} `}>
        <div className="flex  flex-wrap">
          <div className="m-2">
            <CustomDropdown
              btnName="_Experience"
              data={experienceData}
              isLoading={false}
              filterType={filterTypes.experience}
              reset={resetKey}
              defaultSelectedData={experience.selectedExperienceOptions}
              onApply={(selectedOptions) =>
                onApplyFilters(filterTypes.experience, selectedOptions)
              }
            />
          </div>

          <div className="m-2">
            <CustomDropdown
              btnName="_Employment_type"
              data={employmentTypeOptions}
              isLoading={false}
              reset={resetKey}
              filterType={filterTypes.employmentType}
              onApply={(selectedOptions) =>
                onApplyFilters(filterTypes.employmentType, selectedOptions)
              }
            />
          </div>

          <div className="m-2">
            <CustomDropdown
              btnName="_Recently_posted"
              data={recentlyPostedData}
              isLoading={false}
              reset={resetKey}
              filterType={filterTypes.recentlyPosted}
              onApply={(selectedOptions) =>
                onApplyFilters(filterTypes.recentlyPosted, selectedOptions)
              }
              isMulti={false}
              defaultSelectedData={undefined}
              inputType={"radio"}
            />
          </div>
          <div className=" lg:mt-2.5 lg:ml-6">
            <button
              onClick={handleFilterDialogOpen}
              className="underline whitespace-nowrap text-textButtonColor text-xs font-medium"
            >
              {t("_More_filters")}
            </button>
            <Button
              buttonClassName=" w-24 h-[35px] text-xs rounded-[20px] mx-2 lg:ml-20"
              onClick={handleReset}
              label="_RESET"
            />
          </div>
        </div>
      </div>
      <JobsFilter
        key={resetKey}
        handleClose={handleFilterDialogClose}
        open={openFilterDialog}
        onReset={handleJobReset}
        onApply={onApplyFilters}
        filterType={filterTypes}
      />
    </div>
  );
}

export default SearchJobFilter;
