import React from "react";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  altMobileNumberValidationRequired,
  designationValidation,
  careerSummaryValidation,
  firstNameValidation,
  lastNameValidation,
} from "../../common/yupValidations";
import { InfoIcon } from "../../icons";
import TextField from "../FormsUI/TextField";
import TextAreaWrapper from "../FormsUI/TextArea";
import { BackArrowIcon } from "../../icons";
import Button from "../Button/Button";
import CloseButton from "../Button/OutlinedButton";
import CompanyLocationDropDown from "../Select/CompanyLocationDropDown";
import InputLabel from "../FormsUI/InputLabel";
import PhoneNumberInput from "../FormsUI/PhoneNumber";
import { CssTooltip } from "../StyledComponent";
import { knowledgeLevelDataforContactNumber } from "../../common/constants";
import { useNavigate } from "react-router";
const CareerDetailsForm = ({ isEdit, ovalues, setFieldValue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(ovalues);
  const checkIfValidJson = (x) => {
    try {
      JSON.parse(x);
      return true;
    } catch {
      return false;
    }
  };
  return (
    <div className="flex justify-center mt-8">
      <div className="">
        {console.log(checkIfValidJson(ovalues.currentWorkLocation))}
        <div className="flex gap-4 mb-8 hover:cursor-pointer max-w-min">
          <BackArrowIcon
            onClick={() => {
              navigate(-1);
              console.log("back");
            }}
            type="button"
            className="w-5"
          />
          <span className="text-inputColor font-medium">{t("_BACK")}</span>
        </div>
        <Box
          sx={{
            width: "550px",
            backgroundColor: "#FAFAFAB3",
            // backgroundColor: "#FAFAFAB3",
            border: "2px solid #E8E8E8",
            borderRadius: "10px",
            paddingY: "30px",
            paddingX: "40px",
          }}
        >
          <Formik
            initialValues={{
              firstName: ovalues?.firstName,
              lastName: ovalues?.lastName,
              designation: ovalues?.currentDesignation || "",
              location: ovalues?.currentWorkLocation?.country
                ? ovalues?.currentWorkLocation
                : checkIfValidJson(ovalues.currentWorkLocation)
                ? JSON.parse(ovalues.currentWorkLocation)
                : {
                    country: "IN",
                    city: "",
                  },
              altMobileNumber: ovalues?.mobile || "",
              careerSummary: ovalues?.careerSummary || "",
            }}
            validationSchema={Yup.object().shape({
              firstName: firstNameValidation,
              lastName: lastNameValidation,
              designation: designationValidation,
              altMobileNumber: altMobileNumberValidationRequired,
              careerSummary: careerSummaryValidation,
            })}
            onSubmit={(values, { setStatus, setSubmitting, validateForm, resetForm }) => {
              // console.log(values);
              //   validateForm().then((errors) => {
              // const careerDetailsPayload = {
              //   designation: values.designation,
              //   city_state_country:
              //     values?.location?.country + "_" + values?.location?.city,
              //   mobile: values.contactNumber,
              //   careerSummary: values.careerSummary,
              // };
              console.log("onsubmit");
              // window.history.back();
              //   });
            }}
          >
            {({
              errors,
              status,
              isValid,
              isSubmitting,
              values,
              handleChange,
              handleBlur,
              resetForm,
            }) => (
              <Form>
                <div>
                  <label className="text-inputColor font-semibold">
                    {isEdit ? t("_Edit_Career_Details") : t("_Add_Career_Details")}
                  </label>
                  <div>
                    <div className="mt-5">
                      <TextField
                        name="firstName"
                        label="_First_name"
                        disabled={ovalues?.firstName}
                      ></TextField>
                    </div>
                    <div className="mt-5">
                      <TextField
                        name="lastName"
                        label="_Last_name"
                        disabled={ovalues?.lastName}
                      ></TextField>
                    </div>
                    <div className="mt-5">
                      <TextField
                        name="designation"
                        label="_Current_Designation"
                      ></TextField>
                    </div>
                    <div className="mt-5">
                      <InputLabel label={t("_Current_Work_Location")} />
                      <CompanyLocationDropDown
                        name="location"
                        value={values.location}
                        multiple={false}
                      ></CompanyLocationDropDown>
                    </div>
                    <div className="relative">
                      <div className="absolute left-28">
                        <CssTooltip
                          title={knowledgeLevelDataforContactNumber}
                          placement="right"
                          arrow
                        >
                          <InfoIcon></InfoIcon>
                        </CssTooltip>
                      </div>
                      <div className="mt-5">
                        <PhoneNumberInput
                          name="altMobileNumber"
                          label={t("_Contact_Number")}
                          value={values.altMobileNumber}
                        ></PhoneNumberInput>
                      </div>
                    </div>
                    <div className="mt-5">
                      <TextAreaWrapper
                        name="careerSummary"
                        label="_Career_Summary"
                        // layoutClassName={"mt-30px"}
                        minRows={3}
                      ></TextAreaWrapper>
                    </div>
                  </div>
                  <div className="flex gap-5 mt-7">
                    <Button
                      type={"button"}
                      onClick={() => {
                        setFieldValue("firstName", values.firstName);
                        setFieldValue("lastName", values.lastName);
                        setFieldValue("name", values.firstName + " " + values.lastName);
                        setFieldValue("currentDesignation", values.designation);
                        setFieldValue("mobile", values.altMobileNumber);
                        setFieldValue("careerSummary", values.careerSummary);
                        setFieldValue("currentWorkLocation", {
                          country: values?.location?.country,
                          city: values?.location?.city,
                        });
                        navigate(-1);
                      }}
                      buttonClassName="w-24 h-9 px-10 py-4 text-xs"
                      label="_SAVE"
                      disabled={!isValid}
                    />
                    <CloseButton
                      buttonClassName="w-24 h-9 px-10 py-4 text-xs"
                      onClick={() => {
                        window.history.back();
                        console.log("back");
                      }}
                      label="_Cancel"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
};
export default CareerDetailsForm;
