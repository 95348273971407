import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";
const IP = process.env.REACT_APP_IP;

/**
 * This method is used for listing industries
 * @param {*} options
 * @returns
 */
export async function getIndustryList(options) {
  let url = getAPIMap("industryList");

  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for listing company
 * @param {*} options
 * @returns
 */
export async function getCompanyList(options) {
  let url = getAPIMap("companyList");
  let response = axiosPrivate.get(url);
  return response;
}
/*
 * This method is used to get the list of skills data
 */
export async function getSkillList(options) {
  let url = getAPIMap("skillsData");

  let response = axiosPrivate.get(url);
  return response;
}
/**
 * This method is used to get the list of qualifications
 */

export async function getQualificationList() {
  let url = getAPIMap("getQualifications");
  // let url =
  //   "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/job/qualification_list";
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get the list of qualifications
 */

export async function getJDTitleList() {
  let url = getAPIMap("listJDTitles");

  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get the BU List
 */

export async function getBuList() {
  let url = getAPIMap("getBuData");
  //let url = "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/BU/list";
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get the JD Title List
 */

export async function getJDtitle() {
  let url = getAPIMap("getJdTitleData");
  // let url =
  //   "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/JDtitle/list";
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for list the company names in company dropdown filter (Admin)
 */
export async function getAllCompanyList() {
  let url = getAPIMap("getAllCompany");
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get the company list with company name and ids (Admin)
 */
export async function listCompanies() {
  let url = getAPIMap("listCompanies");
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get the list of domains
 */
export async function getRecruiterDomainList() {
  let url = getAPIMap("getDomainForRecruiter");
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get the list of skills (Admin)
 */
export async function getRecruiterSkillList(options) {
  let url = getAPIMap("getSkillForRecruiter");
  url += "?type=" + options.queryKey[1];
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for list the Recruiter names for dropdown filter (Admin)
 */
export async function getRecruitersList(options) {
  let url = getAPIMap("getRecruiterListforAdmin");
  url += "?account_id=" + options.queryKey[1];
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to list the report columns for admin
 */
export async function getReportColumnsListForAdmin() {
  let url = getAPIMap("getReportColumnsForAdmin");
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to list the report columns for recruiter
 */
export async function getReportColumnsListForRecruiter() {
  let url = getAPIMap("getReportColumnsForRecruiter");
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to list the current role for candidate list admin
 */
export async function getCurrentRole() {
  let url = getAPIMap("currentRole");
  // let url =
  //   "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/admin/candidate_jobtitle";
  let response = axiosPrivate.get(url);
  return response;
}
